import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../Context/InshData";
import { uri } from "../../../../help/Api";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../services/LoadingPage";
import DailyJenral from "./DailyJenral";

const defaultAutos = {
    Users: [],
  };
  
export default function DailyJenralCon() {
  const tb = {
    wait: true,
    AllBranches: true,
    Users: true,
    stoe:sto,
    parms: {
    user:-1,
    },
  };

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "GetD",
    tb,
  );

  if (
    fetchAll ||
    !AutoOptions?.Users
  )
    return <LoadingPage />;

  return (
    <DailyJenral
      {...{
        AutoOptions: AutoOptions?.Users ? AutoOptions : defaultAutos,
      }}
    />
  );
}
