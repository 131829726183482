import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../help/Api";

import Grid from "@material-ui/core/Grid";
import { sto } from "../../../Context/InshData";
import { useInvAF } from "../../../St/Reviews/UseStyle";
import { Container, makeStyles } from "@material-ui/core";
//import {useStylesHed} from '../../../St/comps/UseStyle';
import { txtA, txtE } from "../../../Label/Lbl";
import InvBarcode from "./Bardode";
import { useBody } from "./style";

function Footer(props) {
  const { data, InvForFatora, found } = props;
  const clas = useInvAF();
  const calssBody = useBody();

  useEffect(() => {
    setTimeout(async () => {
      console.log("child footer");
      // onUnmountComplete();
    }, 0);
  }, []);

  const sty = {
    cntr: { textAlign: "center", fontFamily: "Amiri-Regular" },
    lft: { textAlign: "left", padingLeft: 1 },
    rit: { textAlign: "right", padingRight: 1 },
    dirR: { direction: "rtl", color: "black" },
    dirL: { direction: "ltr", padding: 2 },
  };
  const premiApp = JSON.parse(localStorage.getItem("premiApp")) || {};

  return (
    <Container style={{ direction: "rtl" }}>
      <Grid
        item
        container
        className={clas.BdytotSub2}
        style={{ paddingTop:"0.5rem" }}
      >
        <Grid item xs={7} container className={calssBody.totalItem} spacing={1}>
          <Grid item xs={4} className={calssBody.BdytotTitle2}>
            {(data.tot.tot_net - data.tot.amountOfVat).toFixed(2)}
          </Grid>
          <Grid item xs={7}>
            {" الإجمالي  " + " " + txtE.totalAmount}
          </Grid>
          {premiApp.billTemplate !== 7 && (
            <Grid item xs={4} className={calssBody.BdytotTitle2}>
              {data.tot.discount.toFixed(2)}
            </Grid>
          )}
          {premiApp.billTemplate !== 7 && (
            <Grid item xs={7}>
              {txtA.discount + " " + txtE.discount}
            </Grid>
          )}
          <Grid item xs={4} className={calssBody.BdytotTitle2}>
            {data.tot.amountOfVat.toFixed(2)}
          </Grid>
          <Grid item xs={7}>
            {txtA.amountOfVat + " " + txtE.amountOfVat}
          </Grid>
          <Grid item xs={4} className={calssBody.BdytotTitle2}>
            {data.tot.tot_net.toFixed(2)}
          </Grid>
          <Grid item xs={7}>
            {" الإجمالي مع الضريبة  " + " " + "Total Amount With Vat"}
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>
        </Grid>
        <Grid item xs={5} container>
          <InvBarcode InvForFatora={InvForFatora} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={1}
          style={{  fontWeight: "bold", textAlign: "center" }}
        >
          {premiApp.billTemplate !== 7 && (
            <Grid item xs={6}>
              {" "}
              {" توقيع البائع" + "     " + " Salesmans Sign "}
            </Grid>
          )}
          {premiApp.billTemplate !== 7 && (
            <Grid item xs={6}>
              {"توقيع المستلم" + "     " + " Receivrs Sign "}
            </Grid>
          )}

          {premiApp.billTemplate !== 7 && (
            <Grid item xs={6}>
              ____________________
            </Grid>
          )}

          {premiApp.billTemplate !== 7 && (

            <Grid item xs={6}>
              ____________________
            </Grid>
          )}


        </Grid>
      </Grid>
      <Grid item container style={sty.cntr}>
        <Grid container>
          <Grid item xs={12}>
            {InvForFatora.notes}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ border: "solid 0.1px",maxHeight:"1cm",direction: "rtl",justifyContent:"center" }}
          >
            {found.BillNotes}
          </Grid>



          {premiApp.billTemplate !== 7 && (
                 <Grid item xs={1}></Grid>
          )}
          {premiApp.billTemplate !== 7 && (
             <Grid item xs={12}>
             {"_____"}
           </Grid>
          )}
        {premiApp.billTemplate !== 7 && (
            <Grid item xs={12}>
            {sto.usernames}
          </Grid>
          )}


        </Grid>
      </Grid>
    </Container>
  );
}
export default Footer;
