const insh = {
  colors: {
    primary: "#4e9e84",
    secndry: "#2688ba",
    sal: "#319835",
    pur: "#008080",
    saf: "#1a1a8a",
    acc: "#898989",
    cancl: "#ff3e3b",
    customer: "#b906b6",
    cust: "#81007f",
    user: "#4a82b9",
    bluDark: { primary: "#4e9e84", secndry: "#2688ba" },
    blue: { primary: "#4e9e84", secndry: "#2688ba" },
    blue: { primary: "#4e9e84", secndry: "#2688ba" },
  },

  invs: {},
  sal: {
    bgImg: { backgroundImage: "linear-gradient(160deg, #5fb562 , #319835)" },
    color: { color: "#319835" },
    bgDis: { background: "#94d197" },
    stGrid: {
      // backgroundImage: 'linear-gradient(160deg, #ffffff1a, #ffffff33)',
      background: "#ffffff1f",
      margin: 15,
      borderRadius: 16,
    },
    fabButtonBA: {
      backgroundColor: "#4fa152",
      "&:hover": { backgroundColor: "green" },
    },
    iconBtn: { backgroundColor: "#4fa152" },
    iconBtnAcc: { backgroundColor: "#d3d3d3" },
    iconBtnRpt: { backgroundColor: "#3f51b5" },
    bakClr: {
      background: "#4fa152",
      ":hover": {
        background: "red",
        boxShadow:
          "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
      },
    },
  },
  purch: {
    bgImg: { backgroundImage: "linear-gradient(160deg, #0bb1b1 , #008080)" },
    color: { color: "#008080" },
    bgDis: { background: "#7dc5c5" },
    stGrid: {
      // backgroundImage: 'linear-gradient(160deg, #ffffff1a, #ffffff33)',
      background: "#ffffff1f",
      margin: 15,
      borderRadius: 16,
    },
    fabButtonBA: {
      backgroundColor: "#388d8d",
      "&:hover": { backgroundColor: "#067474" },
    },
    iconBtn: { backgroundColor: "#388d8d" },
    bakClr: {
      background: "#388d8d",
      "&:hover": {
        background: "#067474",
        boxShadow:
          "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
      },
    },
  },
  safe: {
    bgImg: { backgroundImage: "linear-gradient(160deg, #6464d7 , #1a1a8a)" },
    color: { color: "#1a1a8a" },
    bgDis: { background: "#9292d1" },
    stGrid: {
      // backgroundImage: 'linear-gradient(160deg, #ffffff1a, #ffffff33)',
      background: "#ffffff1f",
      margin: 15,
      borderRadius: 16,
    },
    fabButtonBA: {
      backgroundColor: "#3f51b5",
      "&:hover": { backgroundColor: "#161686" },
    },
    iconBtn: { backgroundColor: "#3f51b5" },
    bakClr: {
      background: "#3f51b5",
      "&:hover": {
        background: "#161686",
        boxShadow:
          "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
      },
    },
  },
  ret: {
    bgImg: { backgroundImage: "linear-gradient(160deg, #ff7e7c , #ff3e3b)" },
    color: { color: "orange" },
    bgDis: { background: "black" },
    fabButtonBA: {
      backgroundColor: "#ef5350",
      "&:hover": { backgroundColor: "#ff3a36" },
    },
    iconBtn: { backgroundColor: "#ef5350" },
    bakClr: {
      background: "#ef5350",
      "&:hover": {
        background: "#ff3a36",
        boxShadow:
          "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
      },
    },
  },
  viw: {
    // bgImg: { backgroundImage: 'linear-gradient(160deg, #ff7e7c , #ff3e3b)'},
    // color:{color:'orange'},
    // bgDis:{background:'black'},
    // fabButtonBA: { backgroundColor:'#ef5350', "&:hover": { backgroundColor: "#ff3a36" }},
    // iconBtn:{ backgroundColor: '#ef5350', },
    // bakClr:{ background:'#ef5350', '&:hover':{ background:'#ff3a36',
    //  boxShadow:'0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',}},
  },
  custm: {
    bgImg: { backgroundImage: "linear-gradient(160deg, #b906b6 , #1a1a8a)" },
    color: { color: "#b906b6" },
    bgDis: { background: "black" },
    stGrid: {
      // backgroundImage: 'linear-gradient(160deg, #ffffff1a, #ffffff33)',
      background: "#ffffff1f",
      margin: 15,
      borderRadius: 16,
    },
    fabButtonBA: {
      backgroundColor: "#ff3e3b",
      "&:hover": { backgroundColor: "green" },
    },
    iconBtn: { backgroundColor: "#81007f" },
    iconBtnB: { backgroundColor: "#f18584" },

    backgroundImage: "linear-gradient(160deg, #b906b6 , #orange)",
  },
  account: {
    bgImg: { backgroundImage: "linear-gradient(160deg, #898989 , #686868)" },
    color: { color: "#686868" },
    bgDis: { background: "black" },
    stGrid: {
      // backgroundImage: 'linear-gradient(160deg, #ffffff1a, #ffffff33)',
      background: "#ffffff1f",
      margin: 15,
      borderRadius: 16,
    },
    backgroundImage: "linear-gradient(160deg, #b906b6 , #orange)",
  },
  bascData: {
    all: {
      bgImg: { backgroundImage: "linear-gradient(160deg, #ff3e3b, #cd5c5c)" },
      color: { color: "#cd5c5c" },
      bgDis: { background: "#f18584" },
      stGrid: {
        // backgroundImage: 'linear-gradient(160deg, #ffffff1a, #ffffff33)',
        background: "#ffffff1f",
        margin: 15,
        borderRadius: 16,
      },
      fabButtonBA: {
        backgroundColor: "#ff3e3b",
        "&:hover": { backgroundColor: "green" },
      },
      iconBtn: { backgroundColor: "#bb2b2b" },
      bakClr: {
        background: "#cd5c5c",
        ":hover": {
          background: "red",
          boxShadow:
            "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
        },
      },
    },
  },
  user: {
    bgImg: { backgroundImage: "linear-gradient(160deg, #4a82b9, #8fbeed)" },
    color: { color: "#4a82b9" },
    bgDis: { background: "#f18584" },
    stGrid: {
      // backgroundImage: 'linear-gradient(160deg, #ffffff1a, #ffffff33)',
      background: "#ffffff1f",
      margin: 15,
      borderRadius: 16,
    },
    fabButtonBA: {
      backgroundColor: "#ff3e3b",
      "&:hover": { backgroundColor: "green" },
    },
    iconBtn: { backgroundColor: "#4a82b9" },
    bakClr: {
      background: "#cd5c5c",
      ":hover": {
        background: "red",
        boxShadow:
          "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
      },
    },
  },
  setting: {
    bgImg: { backgroundImage: "linear-gradient(160deg, #5dbf9e, #2a916e)" },
    color: { color: "#5dbf9e" },
    bgDis: { background: "#f18584" },
    stGrid: {
      // backgroundImage: 'linear-gradient(160deg, #ffffff1a, #ffffff33)',
      background: "#ffffff1f",
      margin: 15,
      borderRadius: 16,
    },
    fabButtonBA: {
      backgroundColor: "#ff3e3b",
      "&:hover": { backgroundColor: "green" },
    },
    iconBtn: { backgroundColor: "#bb2b2b" },
    bakClr: {
      background: "#cd5c5c",
      ":hover": {
        background: "red",
        boxShadow:
          "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
      },
    },
  },
};

export { insh };
