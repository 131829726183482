import React, { useEffect, useState } from 'react';
//b
import axios from 'axios';
import {uri}  from '../../../../../help/Api';
import {insh,sto} from '../../../../../Context/InshData';
import moment from 'moment';
// rt
import CssBaseline from '@material-ui/core/CssBaseline'
// mt
// f e
import Button from '@material-ui/core/Button';
import ScannerIcon from '@material-ui/icons/Scanner';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import KeyboardReturnSharpIcon from '@material-ui/icons/KeyboardReturnSharp';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';

import MaterialTable from "material-table";
// import tableIcons from "../../../../Elemnts/TableIcons";
import {TableIcons as tableIcons} from "../../../../../Elemnts/TableIcons";


import Container from '@material-ui/core/Container';
// cuG
import {useStylesUp,useStylesHed,useStylesAuto,useStylesTF,useStylesTD,useStylesBA,useStylesLbl
  ,useStylesUiElemment,
  useStylesSpDial} from '../../../../../St/Reports/UseStyle';
// cuL
// import {} from './UseStyle';
// csG
import {cellS,headerS,stElemnt} from '../../../../../St/comps/cStyle'
  
import "../../../../../St/comps/csStyle.css";
import { RestaurantMenu } from '@material-ui/icons';


//=====================================================================

// let prm = {Ne:nl,Sa:nl,De:nl,Se:nl,Pr:nl,Up:nl}

export default function Body(){

  const clasup = useStylesUp();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const claslbl = useStylesLbl();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  
  const insOrders = [{id:1,name:'ترتيب حسب التاريخ'},{id:2,name:'ترتيب حسب رقم المستند'},];
  const [optionsOrder, setOptionsOrder] = useState(insOrders);
  const [valueOrder, setValueOrder] = useState(optionsOrder[0]);

  const insReviws = [{id:1,name:'إجمالي'},{id:2,name:'تفصيلي'},];
  const [optionsReviw, setOptionsReviw] = useState(insReviws);
  const [valueReviw, setValueReviw] = useState(optionsReviw[1]);

  var columns = [
    {title: "", field: "name", headerStyle: headerS,cellStyle: cellS,},
    {title: "", field: "TotalFloat",headerStyle: headerS,cellStyle: cellS,},
    // {title: "دائن", field: "TypeOp",headerStyle: headerS,cellStyle: cellS,},
  ];
  


   const inshrpt = {
    transFrom:-1,transTo:-1,
    empFrom:-1,empTo:-1,
    dateFrom:moment(new Date()).format('YYYY-MM-DD'),
    timeFrom:moment(new Date()).format('HH:mm'),
    dateTo:moment(new Date()).format('YYYY-MM-DD'),
    timeTo:moment(new Date()).format('HH:mm'),
    empName:'',
    safe:-1, stock:-1,
    type:1,
    IdCom:null,
  }
  
const [rpt, setRpt] = useState(()=>{
    let today = new Date();
    let yasterday= moment(today.setDate(today.getDate() -1)).format('YYYY-MM-DD');
    return {...inshrpt, dateFrom: yasterday};
});
  const [gets, setGets] = useState(insh.tbname);


const inshValueAuto = {
  safe:{},stock:{}
}
const [valueAutos, setValueAutos] = useState(inshValueAuto);


const reLoadRpt = async () => {
  const tb = {
    Rptshiftstrans:true,
    wait:true,
    Safes:true,
    Stocks:true,
    stor:sto,
    parms:{
      trans_noFrom:rpt.transFrom, trans_noTo:rpt.transTo,
      emp_noFrom:rpt.empFrom, 
      emp_noTo:rpt.empTo,
      emp_name:rpt.empName,
      trans_dateFrom:rpt.dateFrom+ ' ' +rpt.timeFrom,
       trans_dateTo:rpt.dateTo+' '+rpt.timeTo,
      branch:sto.branchi,
      safe:rpt.safe, 
      stock:rpt.stock,
      type:rpt.type,
      IdCom:rpt.IdCom,
    }
 }

await axios.post(uri+'Rept',tb)
  .then(res => {
    console.log("the result is ", res)
    setGets({...gets,trans:res.data.trans,tot:res.data.tot});
}).catch(error => { /*console.log('no rpt');*/ })

}

      const loadRpt = async () => {
        const tb = {
          Rptshiftstrans:true, wait:true,
          Safes:true,Stocks:true,

          stor:sto,

          parms:{
            trans_noFrom:rpt.transFrom, trans_noTo:rpt.transTo,
            emp_noFrom:rpt.empFrom, emp_noTo:rpt.empTo,emp_name:rpt.empName,
            trans_dateFrom:rpt.dateFrom, trans_dateTo:rpt.dateTo,
            branch:sto.branchi,
            safe:rpt.safe,
            stock: rpt.stock,
            type:rpt.type,
            IdCom:rpt.IdCom,
          }
       }

      await axios.post(uri+'Rept',tb)
        .then(res => {
          setGets(res.data);
          setValueAutos({
            safe:res.data.Safes[0],
            stock:res.data.Stocks[0],
          })
      }).catch(error => { /*console.log('no rpt');*/ })
      }

     
      
useEffect(async () => {
  await loadRpt();
}, []);

const changAutos = (prop, newValue) =>
{
  setValueAutos({...valueAutos,[prop]:newValue})
   prop=='safe'? rpt.safe=newValue.id :rpt.stock=newValue.id;
   reLoadRpt();
}

const changTField = (prop) => (e) => {
  // console.log(prop+' - '+e.target.value)
  setRpt({ ...rpt, [prop]: e.target.value });

  // rpt.safe=valueAutos.safe.id;
  // rpt.stock=valueAutos.stock.id;
  prop=='dateFrom' ? rpt.dateFrom=e.target.value : rpt.dateTo=e.target.value
};

// ==== b sd
const [open, setOpen] = React.useState(false);
// const [hidden, setHidden] = React.useState(false);

const handleOpen = () => { setOpen(true); };
const handleClose = () => { setOpen(false); };

const toSave = () => { };

const toBack = () => { his.push({ pathname: '/', }); };


let his = useHistory();
const toPosh = () => {
  const info = {
  columns:columns,
  // rout:rpt.parms,
  data:gets.trans,
  tot:gets.tot,
  from:'الإقفال اليومي',
  title:'تقرير اليومية',
  auto:valueAutos,
  fDate:rpt.dateFrom,tDate:rpt.dateTo,ElmntShow:true,
  // totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
  }

  localStorage.setItem('iInfos',  JSON.stringify(info)); 
  his.push({ pathname: '/ReviewEqfal', });
  window.location.reload(false);
}

const actions = [
  // { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <CloseIcon className={[clasSp.ico,clasSp.clcan]} onClick={toBack} />, name: 'خـروج' },
  // { icon: <SaveIcon className={[clasSp.ico]} onClick={toSave} />, name: 'حـفـظ' },
  { icon: <VisibilityIcon className={clasSp.ico} onClick={reLoadRpt} />, name: ' عرض' },
  { icon: <PrintIcon className={clasSp.ico} onClick={toPosh} />, name: 'مـعـايـنـة كـاشـيـر' },
  // { icon: <ShareIcon />, name: 'Share' },
  // { icon: <FavoriteIcon />, name: 'Like' },
];

// ==== b sd 

const frontDownR = () => {
  return(
        <Grid container lg={12} md={12} className={[clasup.rootdown,]}>
        <Grid container xs={12} className={[clasup.downsub,clasup.downsubR,]}>
        {/* S front F */}
        <Grid container lg={7} md={7} sm={7} className={clasui.rootchip}>
            <Grid item lg={12} md={12} sm={12} xs={6} >
            <Chip className={[clasui.chipT,clasui.chipTR]} label={'إجمالي المبيعات - المرتجع'} clickable color="primary" /> 
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={6}>
            <Chip className={[clasui.chipV,clasui.chipVR]} label={gets.tot.totSal} clickable color="default"/>
            </Grid>
        </Grid>
        <Grid container lg={5} md={5} sm={5} className={clasui.rootchip}>
            <Grid item lg={12} md={12} sm={12} xs={6} >
            <Chip className={[clasui.chipT,clasui.chipTR]} label={'إجمالي الآجل'} clickable color="primary" /> 
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={6}>
            <Chip className={[clasui.chipV,clasui.chipVR]} label={gets.tot.totAjel} clickable color="default"/>
            </Grid>
        </Grid>
        <Grid container lg={7} md={7} sm={7}  className={clasui.rootchip}>
            <Grid item lg={12} md={12} sm={12} xs={6} >
            <Chip className={[clasui.chipT,clasui.chipTR]} label={'صافي شبكة + بنك'} clickable color="primary" /> 
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={6}>
            <Chip className={[clasui.chipV,clasui.chipVR]} label={gets.tot.totNetAndBank} clickable color="default"/>
            </Grid>
        </Grid>
        <Grid container lg={5} md={5} sm={5}  className={clasui.rootchip}>
            <Grid item lg={12} md={12} sm={12} xs={6} >
            <Chip className={[clasui.chipT,clasui.chipTR]} label={'صافي نقدي'} clickable color="primary" /> 
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={6}>
            <Chip className={[clasui.chipV,clasui.chipVR]} label={gets.tot.totNetAmount} clickable color="default"/>
            </Grid>
        </Grid>
        {/* E front F */}
        </Grid>
        </Grid>
  )
}


return (
  <React.Fragment>
  <CssBaseline />
  <Container maxWidth="xl">
    <Typography component="div" 
    style={{textAlign:'center', backgroundColor: '#fff', height: '100vh',direction:'rtl' }}>

 <Grid container> 

    <Grid item xs={12}>
     <Chip size="small" className={clashd.ChipHdSale} label="تقرير الإقفال اليومي" color="primary" />
    </Grid>

{/* S front Right */} 
<Grid container lg={5} md={5}>
    {/* S front up R */} 
    <Grid container lg={12} md={12} className={[clasup.rootup,]}>
    <Grid container spacing={1} xs={12} className={[clasup.upsub,clasup.upsubR,]}>

    <Grid item lg={6} md={6} sm={3} xs={6}>
    <TextField fullWidth variant="outlined" size="small" id="dateFrom" label="من تاريخ"
    className={clasTF.rootSale} InputProps={{ className: clasTD.input }}
    type="date" format="YYYY-MM-DD"
    name="dateFrom" value={rpt.dateFrom} onChange={changTField('dateFrom')}
    /></Grid>
    <Grid item lg={6} md={6} sm={3} xs={6}>
    <TextField fullWidth variant="outlined" size="small" id="timeFrom" label="الوقت "
        className={clasTF.rootSale} InputProps={{ className: clasTD.input }}
        type="time" 
        format="HH:mm"
        name="timeFrom" value={rpt.timeFrom} onChange={changTField('timeFrom')}
    /></Grid>
    

    <Grid item lg={6} md={6} sm={3} xs={6}>
    <TextField fullWidth variant="outlined" size="small" id="dateTo" label="إلى تاريخ"
    className={clasTF.rootSale} InputProps={{ className: clasTD.input }}
    type="date" format="YYYY-MM-DD"
    name="dateTo" value={rpt.dateTo} onChange={changTField('dateTo')}
    /></Grid>
    <Grid item lg={6} md={6} sm={3} xs={6}>
    <TextField fullWidth variant="outlined" size="small" id="timeTo" label="الوقت "
        className={clasTF.rootSale} InputProps={{ className: clasTD.input }}
        type="time" 
        format="HH:mm"
        name="timeTo" value={rpt.timeTo} onChange={changTField('timeTo')}
    /></Grid>
    {/* <Grid item lg={1} md={6} sm={3} xs={3}>
    <Autocomplete classes={clasAo}
              value={valueBranch}
              onChange={(event, newValue) => { changAutos('brnch', newValue) }}
              id="controllable-states-demo" size="small"
              options={optionsBranch}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              disableClearable
              renderInput={(params) => <TextField {...params} label="الفرع" variant="outlined" size="small" 
              InputLabelProps={{className:claslbl.clrSale}} />}
            />
    </Grid> */}

    <Grid item lg={6} md={6} sm={3} xs={5}>
    <Autocomplete classes={clasAo} 
              value={valueAutos.safe} 
              labelPlacement=""
              onChange={(event, newValue) => { changAutos('safe',newValue) }}
              id="controllable-states-demo" size="small"
              options={gets.Safes}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              // style={{  }}
              disableClearable
              renderInput={(params) => <TextField {...params} label="المخزن" variant="outlined" size="small" 
              InputLabelProps={{className:claslbl.clrSale}} />}
            />
    </Grid> 

    <Grid item lg={6} md={6} sm={3} xs={7}>
    <Autocomplete classes={clasAo} 
              value={valueAutos.stock} 
              labelPlacement=""
              onChange={(event, newValue) => { changAutos('stock',newValue) }}
              id="controllable-states-demo" size="small"
              options={gets.Stocks}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              // style={{  }}
              disableClearable
              renderInput={(params) => <TextField {...params} label="الحساب" variant="outlined" size="small" 
              InputLabelProps={{className:claslbl.clrSale}} />}
            />
   
   
    </Grid> 
  
    <Hidden smDown={['sm']}>
      <Grid item container spacing={1} className={clasui.contbnt}>
        <Grid item >
        <Button variant="contained" color="primary" size="large"
          className={[clasui.button,clasSp.clPri]} onClick={reLoadRpt}>عرض </Button>
        </Grid>
        <Grid item >
        <Button variant="contained" startIcon={<PrintIcon />} color="primary" size="large"
          className={[clasui.button,clasSp.clPri]} onClick={()=>toPosh('C')}>معاينة كاشير</Button>
        </Grid>

        {/* <Grid item >
        <Button variant="contained" startIcon={<ScannerIcon />} color="primary" size="large"
          className={[clasui.button,clasSp.clSec]} onClick={()=>toPosh('A')}>معاينة كبير</Button>
        </Grid> */}
        <Grid item >
        <Button variant="contained" startIcon={<KeyboardReturnSharpIcon />} color="secondary" size="large"
          className={[clasui.button,clasSp.clcan]} onClick={toBack}>خروج</Button>
        </Grid>
      </Grid>
      </Hidden>

    </Grid></Grid>
   {/* E front up R */}

   {/* S front down R */} 
   <Hidden smDown={['sm']}>{frontDownR()}</Hidden>
  {/* E front down R */}
</Grid>
{/* E front Right */} 

{/* S front Left */} 
<Grid container lg={7} md={7}>
    {/* s front up L */}
    <Grid container lg={12} md={12} className={[clasup.rootup,]}>
    <Grid container xs={12} className={[clasup.upsub,clasup.upsubL,]}>
      <Grid item xs={12} >
           <MaterialTable style={{boxShadow:'none'}}
                title='بحث عن الفواتير'
            // variant="outlined"
              options={{ 
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,

              // selection: true,
     // showTextRowsSelected: false,
              
               // action sCellStyle: -1,
                paging: false,
           
                headerStyle: headerS,
                cellStyle: cellS,

               //tableLayout: 'fixed',
              //  maxBodyHeight: '50vh',
              maxBodyHeight: '73vh',
              minBodyHeight: '73vh',

             }}
       
             localization={{
               
              body:{
                emptyDataSourceMessage:<h3 
                style={{ marginTop:'20vh', border:'solid 0px', boxShadow:'none'}}
                ></h3> } }}
             columns={columns}
             data={gets.trans}
             icons={tableIcons}
             
             onRowClick={(event, rowData) => {
              event.stopPropagation();
            }}

            />
      </Grid>    
    </Grid>
    </Grid>
    {/* e front up L */}

    {/* S front down L */}
    {/* <Hidden smDown={['sm']}>
    <Grid container lg={12} md={8} className={[clasup.rootdown, clasup.grdhivh]} 
    >
    <Grid container xs={12} className={[clasup.downsub,clasup.downsubL,]}>
    </Grid> </Grid></Hidden> */}
    {/* E front down L */}

</Grid>
{/* E front Left */} 

  {/* S front down R */} 
  <Hidden mdUp={['md']}>{frontDownR()}</Hidden>
{/* E front down R */}

</Grid>



  {/* s front fixed */}
  <Hidden mdUp={['md']}>
  <div className={clasSp.rootsd}>
      <SpeedDial ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDialsd} hidden={false}
        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
        onClose={handleClose} onOpen={handleOpen} open={open}>
        {actions.map((action) => ( <SpeedDialAction
            key={action.name} icon={action.icon}
            tooltipTitle={action.name} 
            // onClick={toPosh} 
            />))}
      </SpeedDial>
    </div>
    </Hidden>
  {/* e front fixes */}

    </Typography>
  </Container>
</React.Fragment>
 );
}