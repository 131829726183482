import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { sto } from "../../../../../Context/InshData";
import Print from "@material-ui/icons/Print";
import { uri } from "../../../../../help/Api";
import toast from "react-hot-toast";
import axios from "axios";
import { reFormatDate } from "../../../../../utils/utils";
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));

export default function TransrsSearchTable(props) {
    const { data,getFormatSTransferData, setPrevent,handCloseDw,getSearchHeadData,setEstelamAfter,isEstelam } = props;


    const stylH = {
        // borderWidth: 1,
        border: "solid 0.6px",
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 15,
        color: "#fff"
    }
    const stylRo = {
        BorderBottom: "solid 0.5px",
        //borderStyle: "solid",
        borderColor: "gray",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 14,
        color: "#000"
    }
    const onRowClick = async (rowData) => {
        setPrevent(false);
        await axios.get(uri + `StoreTransfer/importSub?transfer_id=${rowData.id}&&no=${rowData.no}`).then(res => {
            if (res.data?.importState == -1) {
            //  toast.error("رقم تحويل غير موجود")
            }
            else if (res.data?.importState == 0) {
            //  toast.error("تم استخدام رقم التحويل من قبل")
            }
            else {
                if (res.data?.estelam==1 && rowData.Type==1){
                    setEstelamAfter(true);
                }else{
                    setEstelamAfter(false);
                }
                handCloseDw();
                getSearchHeadData({...rowData},res.data?.transferItems);
            }
          
        }).catch(err => {
          console.log(err);
          toast.error("لم يتم جلب البيانات")
        });
        // setSubmitting(false)
      };
    


    const { t } = useTranslation(["forms"]);
    const classes = useStyles();
    return (
        <TableContainer component={Paper} >
            <Table
                //className={classes.table} 
                size="small" aria-label="a dense table" >
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor: "#3f51b5",
                            color: "#000",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 15,
                            border: "solid 0.6px"
                        }}
                    >
                        <TableCell style={stylH} align="center">
                            {"رقم الامر"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"التاريخ"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الوقت"}
                        </TableCell>
                      
                        <TableCell style={stylH} align="center">
                            {"المخزن المحول منه"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                        {"المخزن المحول الية"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"البيان"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {isEstelam?"رقم التحويل":"مركز التكلفة"}
                        </TableCell>
                        {/* <TableCell style={stylH} align="center">  </TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.id}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                color: "#000",
                                fontWeight: 600,
                                fontFamily: "Amiri-Regular",
                                fontSize: 15,
                                border: "solid 0.6px"
                            }}
                           >
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{row.no}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }} >{reFormatDate(row.date)}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{row.time}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{row.safeFromName}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{row.safeToName}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{row.notes}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{isEstelam?row.transfer_no: row.centerName}</TableCell>                           
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
