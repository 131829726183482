import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ScannerIcon from "@material-ui/icons/Scanner";
import Save from "@material-ui/icons/Save";

import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial,
} from '../../../../St/Reports/UseStyle';
import { Button, colors, Container, Hidden, Paper } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import { insh as inshst } from "../../../../Context/InshSt";

export default function SafesTransfersForm(props) {
    const { t } = useTranslation(["forms", "translation"]);

    const {
        AutoOptions,
        rptParamsSub,
        onChangeDataSub,
        onChangeAutoDataSubunit,
        valueAutosSub,
        selectItems,
        unitsOptions,
        onChangeBarcodeSearch,
        onChangeAutoDataSubCurr,
        onSubmit,
        onChangeDataSubQ,
        onChangeDataSubP,
        MathChangIdata
    } = props;

    const clasup = useStylesUp();
    const calsPurch = useStylesAutoPurch();
    const claslbl = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();
    const clasui = useStylesUiElemment();

    const handleFocus = (e) => e.target.select();

    return (
        <React.Fragment>

            <Grid container style={{ padding: 5 }} spacing={1}>
                <Grid item lg={2} md={2} sm={2} xs={3} style={{ paddingBottom: "0.5rem" }}>
                    <TextField
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="id"
                        label={t("forms:barcode")}
                        style={{ backgroundColor: colors.blue[50], marginBlock: 5 }}
                        name="barcode"
                        value={rptParamsSub.barcode}
                        autoFocus={true}

                        InputProps={{
                            onKeyDown: (event) => {
                                if (event.key === 'Enter') {
                                    onChangeBarcodeSearch(event);
                                }
                            },
                        }}
                        onFocus={handleFocus}

                        onChange={onChangeDataSub("barcode")}
                        />
                </Grid>


                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        value={valueAutosSub.curr}
                        labelPlacement=""
                        onChange={(event, newValue) => {
                           onChangeAutoDataSubCurr("symbol", newValue)
                        }}
                        id="controllable-states-demo"
                        size="small"
                        style={{ backgroundColor: colors.blue[50], marginBlock: 5 }}
                        options={AutoOptions.items}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={"اسم الصنف"}
                                variant="outlined"
                                size="small"
                            />

                        )}
                    />
                </Grid>


                <Grid item lg={2} md={2} sm={3} xs={3} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        value={valueAutosSub.units}
                        labelPlacement=""
                        onChange={(event, newValue) => {
                            onChangeAutoDataSubunit("units", newValue)
                        }}
                        id="units"
                        size="small"
                        disabled={selectItems}
                        style={{ backgroundColor: colors.blue[50], marginBlock: 5 }}
                        options={unitsOptions}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.unitName
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={"الوحدة"}
                                variant="outlined"
                                size="small"
                            />
                        )}
                    />
                </Grid>

                <Grid item lg={1} md={1} sm={2} xs={3} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small"
                        id="val1"
                        label=" الكمية"
                        className={clasTF.rootSafe}
                        style={{ backgroundColor: colors.blue[50], marginBlock: 5 }}

                        InputProps={{ className: claslbl.clrSafe, }}
                        type="text"
                        name="val1" value={rptParamsSub.val1} onChange={onChangeDataSubP('val1')}
                    />
                </Grid>
                <Grid item lg={1} md={1} sm={2} xs={3} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small"
                        id="qty_balance"
                        label="الكمية المتوفرة"

                        className={clasTF.rootSafe}
                        style={{ backgroundColor: colors.blue[50], marginBlock: 5 }}

                        InputProps={{ className: claslbl.clrSafe, readOnly: true }}
                        type="text"
                        name="qty_balance" value={rptParamsSub.qty_balance}
                    />
                </Grid>
                <Grid item lg={1} md={1} sm={2} xs={3} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small"
                        id="qty_balance"
                        label="الرصيد حسب الوحدة"

                        className={clasTF.rootSafe}
                        style={{ backgroundColor: colors.blue[50], marginBlock: 5 }}

                        InputProps={{ className: claslbl.clrSafe, readOnly: true }}
                        type="text"
                        name="unit_balance" value={rptParamsSub.unit_balance}
                    />
                </Grid>
                <Grid item lg={1} md={1} sm={2} xs={3} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small"
                        id="price"
                        label="السعر "
                        style={{ backgroundColor: colors.blue[50], marginBlock: 5 }}

                        className={clasTF.rootSafe}
                        InputProps={{ className: claslbl.clrSafe }}
                        type="text"
                        name="refno" value={rptParamsSub.price} onChange={onChangeDataSubP('price')}
                    />
                </Grid>

                <Grid item lg={2} md={2} sm={2} xs={2} style={{ paddingBottom: "0.5rem" }}>

                    <Container
                        maxWidth={false}
                        style={{
                            padding: "5px",
                        }}
                    >

                        <Hidden smUp={["sm"]}>

                            <Grid item>
                                <Button
                                    // disabled={fetching}
                                    variant="contained"
                                    startIcon={<Save />}
                                    size="small"
                                    style={
                                        (inshst.safe.iconBtn,
                                            { borderRadius: 0 ,backgroundColor:"#3f51b5"})
                                    }
                                    className={[clasui.button]}
                                    onClick={onSubmit}

                                    // onClick={() => addItemsList(rptParamsSub)}
                                >
                                    {"تنزيل"}
                                </Button>
                            </Grid>
                        </Hidden>
                        <Hidden only={["xs"]}>

                            <Grid item>
                                <Button
                                    // disabled={false}
                                    variant="contained"
                                    startIcon={<Save />}
                                    size="large"
                                    style={
                                        (inshst.sal.iconBtn,
                                            { borderRadius: 0 ,backgroundColor:"#3f51b5"})
                                    }
                                    className={[clasui.button]}
                                    // onClick={() => addItemsList(rptParamsSub)}
                                    onClick={onSubmit}

                                >
                                    {" تنزيل الصنف"}
                                </Button>
                            </Grid>
                        </Hidden>

                    </Container>

                </Grid>
            </Grid>

        </React.Fragment >
    );

}