import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { sto } from "../../../../../../Context/InshData";
import Print from "@material-ui/icons/Print";
import { reFormatDate } from "../../../../../../utils/utils";
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));

export default function SearchReceiptTable(props) {
    const { data,onRowClick,rptParams} = props;
    const data2 = data.map((row) => {
        return {
          ...row,
          acc_name:row.acc_name,
          custName:row.acc_name,
          emp_id:row.sales_emp,
        };
      });
    
     

    const stylH = {
        // borderWidth: 1,
        border: "solid 0.6px",
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 15,
        color: "#fff"
    }
    const stylRo = {
        BorderBottom: "solid 0.5px",
        //borderStyle: "solid",
        borderColor: "#000",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 14,
        color: "#000"
    }

    const { t } = useTranslation(["forms"]);
    const classes = useStyles();
    return (
        <TableContainer component={Paper} >
            <Table
                //className={classes.table} 
                size="small" aria-label="a dense table" >
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor:"#808080",
                            color: "#000",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 15,
                            border: "solid 0.6px"
                        }}
                    >
                        <TableCell style={stylH} align="center">
                            {"رقم السند"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"التاريخ"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"المبلغ"}
                        </TableCell>
                      
                        <TableCell style={stylH} align="center">
                            {"المستخدم"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"اسم الحساب"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"رقم الشييك"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"نوع الدفع"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"البيان"}
                        </TableCell>
                        
                      
                               
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data2.map((row) => (
                        <TableRow key={row.id}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                color: "#000",
                                border: "solid 0.6px"
                            }}
                           >

                            <TableCell style={stylRo} align="center" onClick={async (event, row) => { onRowClick(row);  event.stopPropagation();}}>{row.id}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }} >{row.date}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }} > {row.val?.toFixed(2) ?? "-"}</TableCell>

                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }} > {row.empName}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }} > {row.check_no}</TableCell>
                            <TableCell style={stylRo} align="center"  onClick={() => { onRowClick(row); }} > {row.acc_name}</TableCell>

                            <TableCell style={stylRo} align="center"  onClick={() => { onRowClick(row); }} > {row.type==1?"كاش":row.type==2?"شبكة":"بنك"}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{row.notes}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
