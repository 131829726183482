import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
    Button,
    Checkbox,
    colors,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial, useStylesAutoSafe
} from '../../../../St/Reports/UseStyle';
import { typeCosts, typeQties } from "../../../../services/safe-grd-services";
import { Save } from "@material-ui/icons";
import { insh as inshst } from "../../../../Context/InshSt";

export default function SafesTranferHead(props) {
    const {
        AutoOptions,
        rptParams,
        onChangeData,
        onChangeAutoData,
        valueAutos,
        typeTransfer,
        onChangeAutoCost,
        isEstelam,
        getTransData,
    } = props;

    const { t } = useTranslation(["forms"]);

    const clasup = useStylesUp();
    const calsPurch = useStylesAutoPurch();
    const claslbl = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();
    const clasASafe = useStylesAutoSafe();



    const clashd = useStylesHed();
    const clasBA = useStylesBA();
    const clasTD = useStylesTD();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    const handleFocus = (e) => e.target.select();

    return (
        <React.Fragment>
            <Grid container style={{ padding: 5 }} spacing={1}>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small"
                        id="no"
                        label="رقم الامر"
                        className={clasTF.rootSafe}
                        InputLabelProps={{ className: claslbl.clrBlackLblS }}
                        InputProps={{ className: claslbl.clrSafe, readOnly: true }}
                        type="text"
                        name="no" value={rptParams.no} onChange={onChangeData('no')}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small"
                        id="refno"
                        label="رقم المرجع"
                        placeholder="رقم المرجع"

                        className={clasTF.rootSafe}
                        InputLabelProps={{ className: claslbl.clrBlackLblS }}
                        InputProps={{ className: claslbl.clrSafe }}
                        type="text"
                        name="refno" value={rptParams.refno} onChange={onChangeData('refno')}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete classes={clasASafe}
                        value={valueAutos.Type_Cost}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoCost('Type_Cost', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={typeTransfer}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="'تحويل حسب' " variant="outlined" size="small"
                            InputLabelProps={{ className: claslbl.clrBlackLblS }}
                        />}
                    />

                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small" id="date" label=" التاريخ"
                        className={clasTF.rootSafe}
                        InputProps={{ className: claslbl.clrSafe }}
                        type="date" format="YYYY-MM-DD"
                        InputLabelProps={{ className: claslbl.clrBlackLblS }}
                        name="date" value={rptParams.date} onChange={onChangeData('date')}
                    /></Grid>

                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete classes={clasASafe}
                        value={valueAutos.safe_from}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoData('safe_from', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={AutoOptions.Safes}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="المخزن المحول منه" variant="outlined" size="small"
                            InputLabelProps={{ className: claslbl.clrBlackLblS }}
                        />}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete classes={clasASafe}
                        value={valueAutos.safe_to}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoData('safe_to', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={AutoOptions.Safes}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="المخزن المحول الية" variant="outlined" size="small"
                            InputLabelProps={{ className: claslbl.clrBlackLblS }}
                        />}
                    />
                </Grid>


                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete classes={clasASafe}
                        value={valueAutos.branch}
                        onChange={(event, newValue) => {
                            onChangeAutoData("branch", newValue);
                        }}
                        id="combo-box-demo"
                        size="small"
                        options={AutoOptions.Branches}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }

                        style={{}}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={isEstelam ? "الفرع المحول الية" : "الفرع المحول منه"}
                                variant="outlined"
                                InputLabelProps={{ className: claslbl.clrBlackLblS }}
                            />
                        )}

                    />
                </Grid>

                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete classes={clasASafe}
                        value={valueAutos.center}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoData('center', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={AutoOptions.center}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="مراكز التكلفة" variant="outlined" size="small"
                            InputLabelProps={{ className: claslbl.clrBlackLblS }} />}
                    />


                </Grid>

                <Grid item lg={8} md={8} sm={8} xs={12} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small"
                        id="refno"
                        label="البيان "
   
                        className={clasTF.rootSafe}
                        InputProps={{ className: claslbl.clrSafe }}
                        InputLabelProps={{ className: claslbl.clrBlackLblS }}
                        type="text"
                        name="refno" value={rptParams.notes} onChange={onChangeData('notes')}
                    />
                </Grid>
                {isEstelam && (
                    <Grid item container lg={5} md={5} sm={5} xs={12} spacing={1}>

                        <Grid item lg={11} md={11} sm={9} xs={8} >
                            <TextField
                                type="number"
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="transfer_no"
                                label={"رقم التحويل المراد استلامة"}
                                style={{ backgroundColor: colors.blue[50], marginBlock: 5 }}
                                name="transfer_no"
                                value={rptParams.transfer_no}
                                onChange={onChangeData('transfer_no')}
                                InputProps={{
                                    onKeyDown: (event) => {
                                        if (event.key === 'Enter') {
                                            getTransData(event);
                                        }
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item lg={1} md={1} sm={2} xs={3} >

                            <Button
                                variant="contained"
                                startIcon={<Save />}
                                size="small"
                                style={
                                    (inshst.safe.iconBtn,
                                        { borderRadius: 0, backgroundColor: "#3f51b5", marginBlock: 5 })
                                }
                                className={[clasui.button]}
                                onClick={getTransData}

                            >
                                {"جلب"}
                            </Button>
                        </Grid>
                    </Grid>
                )}

            </Grid>
        </React.Fragment>
    );
}
