import React, { useState, useEffect } from "react";
import { txtA } from "../../../../Label/Lbl";
import axios from "axios";
import { uri } from "../../../../help/Api";
import { Rept, sto } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useRptCa, useRptMain } from "../../../../St/Reviews/UseStyle";

function HeaderShC() {
  const clasm = useRptMain();
  const clas = useRptCa();

  const [info, setInfo] = useState({});
  const getInfo = async () => {
    const tb = {
      GetFoundation: true,
      parms: { iFoundid: 1, branch: sto.branchi },
      autos: {},
    };
    axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Grid item container className={clas.Hedroot}>
      <Grid item xs={12} className={clasm.mincntr}>
        <Typography>
          <ButtonBase>
            {" "}
            <img
              src={`data:image/jpeg;base64,${info.Logo}`}
              className={clas.Hedlogo}
              alt={info.nameA}
            />{" "}
          </ButtonBase>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography className={clasm.mincntr}>{info.nameA}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={clasm.mincntr}>{info.nameE}</Typography>
      </Grid>
    </Grid>
  );
}
export default HeaderShC;
