import { makeStyles } from "@material-ui/core/styles";

export const useBody = makeStyles((theme) => ({
  minclr: {
    fontWeight: "bold",
    fontFamily: "Amiri-Regular",
    textAlign: "center",
  },
  txt: {
    fontFamily: "Amiri-Regular",
  },

  BdymtTabl: {
    border: "solid 0.1px",
    borderRadius: "6px",
    padding: 1,
    margin: 10,
    minHeight: 470,
  },
  BdytotTitle: {
    border: "solid 0.1px black",
    borderRadius: "6px",
    textAlign: "center",
    color: "#be151f",
    fontWeight: "bold",
    margin: 2,
  },
  BdytotTitle2: {
    border: "solid 0.1px black",
    borderRadius: "6px",
    textAlign: "center",
    color: "#be151f",
    fontWeight: "bold",
    padding: 2,
  },
  totalItem: {
    padding: 2,
    border: "solid 0px",

    fontFamily: "Amiri-Regular",
  },
}));

export const useHeader = makeStyles((theme) => ({
  /* S Head */
  headerContainer: {
    width: "20.4cm",
    marginLeft: "0.3cm",
  },
  minclr: {
    color: "#7f0101",
    fontWeight: "bold",
    fontFamily: "Amiri-Regular",
    boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%)",
  },
  minroot: {
    // flexGrow: 1,
    // display:'flex',
    marginTop: 10,
    border: "solid 1px",
    borderRadius: "10px",
    padding: "5px",

    // zIndex: 1,
  },
  rightHeader: {
    textAlign: "center",
    whiteSpace: "normal",
    fontFamily: "Amiri-Regular",
    fontWeight: "900",
  },
  rightHeaderar: {
    textAlign: "right",
    whiteSpace: "normal",
    fontFamily: "Amiri-Regular",
    fontWeight: "900",
    paddingRight:"0.2cm"
  },
  leftHeaderen: {
    textAlign: "left",
    whiteSpace: "normal",
    fontFamily: "Amiri-Regular",
    fontWeight: "900",
    paddingLeft:"0.2cm"
  },
}));
