import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
  useStylesUp, useStylesHed, useStylesAuto,useStylesAutoPurch,useStylesTF, useStylesTD, useStylesBA, useStylesLbl
  , useStylesUiElemment,
  useStylesSpDial,
} from '../../../../../../St/Reports/UseStyle';


export default function SearchReceiptForm(props){

  const {
    type,tname,
    rptParams,
   onChangeData,
   onChangeAutoData,
   typePays,
 AutoOptions,
 isInternal,
 onChangeAutoDataCust
  }=props;

  const clasup = useStylesUp();
  const calsPurch = useStylesAutoPurch();
  const claslbl = useStylesLbl();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
    return (
          <React.Fragment>
              <Grid container style={{padding:5 }} spacing={1}>
              <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="رقم المستند"
                  type="number" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlackTxt }}
                  InputLabelProps={{className:claslbl.clrBlackLblS}}
                  name="id" value={rptParams.id} 
                  onChange={onChangeData("id")}/>
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="رقم المرجع"
                  type="text" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlackTxt }}
                  InputLabelProps={{className:claslbl.clrBlackLblS}}
                  name="refno" value={rptParams.refno} 
                  onChange={onChangeData("refno")}/>
                </Grid>
              
                <Grid item lg={2} md={2}  sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                    <TextField  fullWidth variant="outlined" size="small" id="datef" 
                    label="من تاريخ"
                    className={clasTF.rootCust} 
                    InputProps={{ className:claslbl.clrBlackTxt }}
                    InputLabelProps={{className:claslbl.clrBlackLblS}}
                    type="date" format="YYYY-MM-DD"
                    name="dateFrom" value={rptParams.dateFrom} 
                    onChange={onChangeData("dateFrom")}/>
                </Grid>
                <Grid item lg={2} md={2}  sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                    <TextField  fullWidth variant="outlined" size="small" id="datef" 
                    label="الى تاريخ"
                    className={clasTF.rootCust} 
                    InputProps={{ className:claslbl.clrBlackTxt }}
                    InputLabelProps={{className:claslbl.clrBlackLblS}}
                    type="date" format="YYYY-MM-DD"
                    name="dateTo" value={rptParams.dateTo} 
                    onChange={onChangeData("dateTo")}/>
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="المبلغ"
                  type="text" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlackTxt }}
                  InputLabelProps={{className:claslbl.clrBlackLblS}}
                  name="value" value={rptParams.value} 
                  onChange={onChangeData("value")}/>
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="المبلغ من"
                  type="text" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlackTxt }}
                  InputLabelProps={{className:claslbl.clrBlackLblS}}
                  name="valFrom" value={rptParams.valFrom} 
                  onChange={onChangeData("valFrom")}/>
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="المبلغ الى"
                  type="text" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlackTxt }}
                  InputLabelProps={{className:claslbl.clrBlackLblS}}
                  name="valto" value={rptParams.valto} 
                  onChange={onChangeData("valto")}/>
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}> 
                    <Autocomplete value={rptParams.pay_type}
                      onChange={(event, newValue) => { 
                         onChangeAutoData("pay_type", newValue)  
                      }}
                      id="combo-box-demo" size="small"
                      options={typePays} 
                      //getOptionLabel={(option) => option.name}
                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                      style={{color:'black',fontWeight:"bold",fontSize:"1rem"}}
                      disableClearable
                      renderInput={(params) => <TextField {...params}  InputLabelProps={{shrink: true,className:claslbl.clrBlackLblS}} label="نوع الدفع" variant="outlined" />}   
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={clasTF}
                        value={rptParams.user}
                        onChange={(event, newValue) => {
                            onChangeAutoData("user", newValue);
                            // console.log(newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={AutoOptions.Users}
                        style={{color:'black',fontWeight:"bold",fontSize:"1rem"}}
                        InputProps={{ className:claslbl.clrBlackTxt }}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                          }
                        renderInput={(params) => (
                            <TextField
                            id="user"
                                {...params}
                                label={"المستخدم"}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className:claslbl.clrBlackLblS}}
                            />
                        )}
                    />

                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}> 

                    <Autocomplete value={ rptParams.cust}
                      onChange={(event, newValue) => { 
                        onChangeAutoDataCust("cust", newValue)  
                      }}
                      id="combo-box-demo" size="small"
                      options={AutoOptions.Customers} 
                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                      InputProps={{ className:claslbl.clrBlackTxt }}
                      style={{color:'black',fontWeight:"bold",fontSize:"1rem"}}
                      renderInput={(params) => <TextField {...params}  InputLabelProps={{shrink: true,className:claslbl.clrBlackLblS}} 
                      label= {isInternal? "الحساب":"العميل"}
                      variant="outlined" />}   
                    />
                </Grid>
              
               
               
                <Grid item lg={4} md={4} sm={6} xs={12} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="البيان"
                  type="text" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlackTxt }}
                  InputLabelProps={{className:claslbl.clrBlackLblS}}
                  name="notes" value={rptParams.notes} 
                  onChange={onChangeData("notes")}/>
                </Grid>
              


 
            </Grid>  

          </React.Fragment>
          
    );
 
}