import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Lock } from "@material-ui/icons";
import { LockOpen } from "@material-ui/icons/LockOpen";



const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));

export default function ShiftsTables(props) {
    const { data, viewSearch, CloseShift, showButtons } = props;



    const { t } = useTranslation(["forms"]);
    const classes = useStyles();
    return (
        <TableContainer component={Paper}>
            <Table
                //className={classes.table} 
                size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor: colors.green[300],
                            color: "#fff",
                            textAlign: "-webkit-center",
                            margin: "0px",
                            whiteSpace: "nowrap",
                            width: "0px",
                            padding: "15px 0px",
                            textAlign: "right"
                        }}
                    >
                        <TableCell style={{ color: "#fff", textAlign: "right", fontWeight: "bold" }} align="center">
                            {"رقم الوردية"}
                        </TableCell>
                        <TableCell style={{ color: "#fff", textAlign: "right", fontWeight: "bold" }} align="center">
                            {"من تاريخ"}
                        </TableCell>
                        <TableCell style={{ color: "#fff", textAlign: "right", fontWeight: "bold" }} align="center">
                            {"الحالة"}
                        </TableCell>
                        <TableCell style={{ color: "#fff", textAlign: "right", fontWeight: "bold" }} align="center">
                            {"الموظف"}
                        </TableCell>

                       
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, i) => (
                        <TableRow key={row.title}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                textAlign: "right"
                            }}>
                            <TableCell style={{ textAlign: "right", fontWeight: "bold" }} align="center" onClick={() => { viewSearch(row); }}>{row.transNo}</TableCell>
                            <TableCell style={{ textAlign: "right", fontWeight: "bold" }} align="center" onClick={() => { viewSearch(row); }}>{row.minDate}</TableCell>
                            <TableCell style={{ textAlign: "right", fontWeight: "bold" }} align="center" onClick={() => { viewSearch(row); }}>{row.transType}</TableCell>
                            <TableCell style={{ textAlign: "right", fontWeight: "bold" }} align="center" onClick={() => { viewSearch(row); }} >{row.empName}</TableCell>                         
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
