import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
// Ust
import { useInvAF } from "../../../St/Reviews/UseStyle";
// csG
import { stElemnt } from "../../../St/Reviews/cStyle";

import { colors, Container, makeStyles, Typography } from "@material-ui/core";
import { useBody } from "./style";

const Body = (props) => {
  const { onUnmountComplete, data } = props;
  const clas = useInvAF();
  const calssBody = useBody();
  // const clasB = useInvBodyAF();
  const premiApp = JSON.parse(localStorage.getItem("premiApp")) || {};

  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};


  const cellS = {
    padding: "6.9px 2px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    // textAlign: '-webkit-right',
    border: "solid 0.1px",
    // borderRight: 'solid 0px',
    width: "200px",
    // color: '#000',
    fontFamily: "Amiri-Regular",
    // border:'solid 1px red',
  };
  var columns = [
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الإجمالي مع الضريبة
          <div style={{ marginTop: -5, paddingTop: 0 }}> Amount With Vat</div>
        </div>
      ),
      field: "tot_net_curr",
      headerStyle: { borderRight: "solid 0px" },
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الضريبة<div style={{ marginTop: -5, paddingTop: 0 }}> Vat</div>
        </div>
      ),
      field: "taxperc",
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الخصم<div style={{ marginTop: -5, paddingTop: 0 }}> Discount</div>
        </div>
      ),
      field: "discount",
    },

    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          السعر<div style={{ marginTop: -5, paddingTop: 0 }}> Price</div>
        </div>
      ),
      field: "new_exchange_price",
      cellStyle: { ...stElemnt.AF.cell, fontWeight: "bold" },
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الكمية<div style={{ marginTop: -5, paddingTop: 0 }}> Quentity</div>
        </div>
      ),
      field: "val1",
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الوحدة<div style={{ marginTop: -5, paddingTop: 0 }}> Unit</div>
        </div>
      ),
      field: "unit_name",
    },

    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الصنف<div style={{ marginTop: -5, paddingTop: 0 }}> Product</div>
        </div>
      ),
      field: "symbol",
      cellStyle: {
        ...cellS,
        fontWeight: "bold",
        border: "solid 0.1px",
        borderLeft: "solid 0px",
      },
    },
  ];
  var columns2 = [
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الإجمالي مع الضريبة
          <div style={{ marginTop: -5, paddingTop: 0 }}> Amount With Vat</div>
        </div>
      ),
      field: "tot_net_curr",
      headerStyle: { borderRight: "solid 0px" },
    },
  
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          السعر<div style={{ marginTop: -5, paddingTop: 0 }}> Price</div>
        </div>
      ),
      field: "new_exchange_price",
      cellStyle: { ...stElemnt.AF.cell, fontWeight: "bold" },
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الكمية<div style={{ marginTop: -5, paddingTop: 0 }}> Quentity</div>
        </div>
      ),
      field: "val1",
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الوحدة<div style={{ marginTop: -5, paddingTop: 0 }}> Unit</div>
        </div>
      ),
      field: "unit_name",
    },

    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الصنف<div style={{ marginTop: -5, paddingTop: 0 }}> Product</div>
        </div>
      ),
      field: "symbol",
      cellStyle: {
        ...cellS,
        fontWeight: "bold",
        border: "solid 0.1px",
        borderLeft: "solid 0px",
      },
    },
  ];
  return (
    <Container>
      <Grid
        item
        container
        className={clas.Bdyroot}
        style={{ direction: "rtl" }}
      >
        <Grid item container>
          <Grid item xs={12} className={calssBody.BdymtTabl}>
            <MaterialTable
              style={{ boxShadow: "none" }}
              //style={{direction:'ltr'}}
              options={{
                showTitle: false,
                toolbar: false,
                sorting: false,
                selection: false,
                // ToolBar Search
                searchFieldAlignment: "left",
                searchFieldVariant: "outlined",
                // ToolBar header
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,
                // body
                // rowStyle:(data,i)=>i%2!=0?{background:'#038b0321'}:null,
                // footer
                pageSize: 5,
                paginationType: "stepped",
                showFirstLastPageButtons: false,

                paging: false,
                headerStyle: {
                  ...stElemnt.AF.header,
                  backgroundColor: colors.grey[100],
                  fontWeight: "bold",
                  padding: "1px 1px",
                  border: "solid 0px",
                  borderBottom: "solid 0.1px",
                  borderRight: "solid 0.1px",
                },
                cellStyle: { ...stElemnt.AF.cell, fontWeight: "bold" },
              }}
              localization={{ body: { emptyDataSourceMessage: <h1></h1> } }}
              columns={premiApp?.billTemplate == 8?columns2:columns}
              data={data}
            />
          </Grid>
        </Grid>

        {/* <Grid container>
</Grid> */}
      </Grid>
    </Container>
  );
};

export default Body;
