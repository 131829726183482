import React, { useEffect } from "react";
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import MaterialTable from "material-table";
import { stElemnt } from "../../../../St/Reviews/cStyle";

import { colors, Container, makeStyles, Typography } from "@material-ui/core";

export function Totals({ data }) {
  const cls = useRptAF();
  return (
    <Grid
    container
    lg={12}
    md={12}
    className={cls.rootBdydown}
    style={{ paddingBottom: 5, backgroundColor: colors.grey[100],fontFamily: "Amiri-Regular"  }}
  >
    <Grid container xs={12} /*className={cls.rootchip}*/  style={{ paddingBottom: 5, 
      backgroundColor: colors.grey[100],fontFamily: "Amiri-Regular",
      border: "solid 1px",
      padding: "0.3rem 0.1rem 0.3rem 0.1rem", 
      borderRadius: "0px 0px 5px 0px" }}>
           
           <Grid item xs={2} className={[cls.chipVMU]}>
            {"عدد الأصناف"}
          </Grid>
      <Grid item xs={2} className={[cls.chipVMU]}>
      {Rept.tot.count}
      </Grid>
        <Grid item xs={2} className={[cls.chipVMU]}>
      {"إجمالي الكميات"}
      </Grid>
      <Grid item xs={2} className={[cls.chipVMU]}>
      {Rept.tot.totQun}
      </Grid>
      <Grid item xs={2} className={[cls.chipVMU]}>
      {"إجمالي الكلي للكميات"}
      </Grid>
      <Grid item xs={2} className={[cls.chipVMU]}>
      {Rept.tot.totCost}
      </Grid>
    </Grid>
  
  </Grid>
  );
}
function BodyTransfer({ data, isFullPage, isLast }) {
  const cls = useRptAF();
 
  const cellS = {

    padding: "0px 0px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    border: "solid 0.1px",
    fontSize: "0.65rem",
    width: "300px",
    fontFamily: "Amiri-Regular",
    fontWeight:"bold",
  };
  var columns = [
    { title: "م/", field: "idd" },
    { title: "الباركود", field: "barcode" },
    { title: "الصنف", field: "symbol" },
    { title: "الوحدة", field: "unitName" },
    { title: "الكمية", field: "val1" },
    { title: "الكمية الاجمالية", field: "value" },

    // { title: " السعر", field: "price" },
    // { title: "الاجمالي", field: "total" },
  ];

  const data2 = data.map((row,i) => {
    return {
      ...row,
      idd:i+1,
      total:parseFloat(row.total).toFixed(2),
    };
  });
  return (
    <Grid item container className={cls.rootBdy}>
      <Grid item container className={cls.rootBdyup}  >
        <Grid
          item
          xs={12}
          // style={{
          //   maxHeight: isFullPage ? isLast ?  "61rem": "61.5rem": "43.3rem",
          //   minHeight: isFullPage ? isLast ?  "61rem": "61.5rem": "43.3rem",
          //  // marginTop: isFullPage ?"1rem" :"0rem",
          // }}
        >
          <MaterialTable
            style={{ boxShadow: "none" }}
            // style={{direction:'ltr'}}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              // ToolBar header
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              headerStyle: {
                ...stElemnt.AF.header,
                backgroundColor: colors.grey[100],
                fontWeight: "bold",
                paddingTop: "2px",
                paddingBottom:"2px",
                border: "solid 0px",
                border: "solid 0.1px",
               
                
              },
              paging: false,
             // headerStyle: { ...stElemnt.AF.header, backgroundColor: "grey" },
              cellStyle: {
                ...stElemnt.AF.cell,
                padding: "0px 1px",
                // fontSize: 8,
              },
              // maxBodyHeight: isFullPage ? "56.3rem" : "35.6rem",
              // minBodyHeight: isFullPage ? "56.3rem" : "35.6rem",
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h5 style={{ color: "GrayText" }}>
                    لا توجد ارصدة بعد في هذا الحساب
                  </h5>
                ),
              },
            }}
            columns={columns}
            data={data2}
          />
          {isLast && <Totals data={data ?? []} />}
        </Grid>
        {/* <Grid item xs={12}>
        </Grid> */}
      </Grid>
  
      
    </Grid>
  );
}

export default BodyTransfer;
