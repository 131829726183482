import React from "react";
import Typography from "@material-ui/core/Typography";
import Swipeable from "../../../../Fronts/Main/Swipeable";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import { Button, colors } from "@material-ui/core";
import CheckboxItem, { useStylesAcc } from "../CheckBoxComponent";
import DiscountPermissions from "../discount-permissions";
const formData = [
 
  {
    name: "UpdatePurchDate",
    label: "السماح بتعديل تاريخ امر الشراء",
    visible: true,
  },
  {
    name: "UpdatePurchPrice",
    label: "السماح بتعديل سعر الشراء ",
    visible: true,
  },
  {
    name: "purchVatUpdate",
    label: "السماح بتعديل الضريبه عند تسجيل امر الشراء",
    visible: true,
  },
  {
    name: "IS_Delete",
    label: "السماح بحذف امر الشراء بعد الترحيل",
    visible: true,
  },
  {
    name: "IS_Update",
    label: "السماح بتعديل امر الشراء  بعد الترحيل",
    visible: true,
  },
  {
    name: "use_price_without_vat",
    label: "استخدام السعر لا يشمل الضريبة في امر الشراء",
    visible: true,
  },
  {
    name: "Purch_than_Less_Purch_Item",
    label: "السماح بتسجيل سعر اقل من  سعر الشراء في بيانات الاصناف",
    visible: true,
  },

  {
    name: "Cust_PurchPrice",
    label: "اعتماد اخر سعر شراء للمورد لكل صنف",
    visible: false,
  },
  {
    name: "Hide_Purch_Bill_Price",
    label: "اخفاء سعر البيع",
    visible: true,
  },
  {
    name: "Purch_out_goods",
    label: "عرض طباعة تصريح خروج بضاعة لمرتجع مشتريات",
    visible: false,
  },
 
  { 
    name: "allow_discount", 
    label: "السماح بالخصم ", 
    visible: true 
  },
  {
    name: "inv_post_unlock",
    label: "السماح بتعديل او حذف فاتورة بعد الترحيل",
    visible: false,
  },
  
];

export default function OrderPermission(props) {
  const { onChange, object, update, sub } = props;

  const classes = useStylesAcc();

  return (
    <div className={classes.root}>
      <Accordion expanded={true}>



        <AccordionDetails >
          <Grid item container spacing={1}>
            {formData
              ?.filter((d) => d.visible)
              ?.map((item) => {
                // console.log(object);
                return (
                  <Grid xs={6}>
                    <CheckboxItem
                      value={object[item.name] ?? null}
                      name={item.name}
                      label={item.label}
                      onChange={onChange}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </AccordionDetails>

        <AccordionDetails>
          {" "}
          <Typography> {"إعدادات الخصومات "}  
          
            </Typography>{" "}    
             
                   
        </AccordionDetails>

        <AccordionDetails style={{backgroundColor:colors.grey[50]}}>
          { object?.allow_discount ? <DiscountPermissions {...props} />: 
          <Typography variant="h5" style={{textAlign:"center"}}> 
          {/* <br />   */}
          {/* {"\n this settings don't work if past discount allowed attribute doesn't enables"} */}
          {"\n تاكد من تفعيل السماح بالخصم حتى يتم تفعيل الخيارات"}

            </Typography> }
        </AccordionDetails>
        <Divider className={classes.divider} />
        <AccordionDetails className={classes.subTitle}>
          {" "}
          <Typography>
            {" "}
            <Button
              variant="contained"
              color="secondary"
              size="large"
              disabled={props.submitting}
              onClick={update}
            >
              {"حفظ التعديلات"}
            </Button>
          </Typography>{" "}
        </AccordionDetails>
      </Accordion>
      <Swipeable />
    </div>
  );
}
