import { colors, Container, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useStylesAcc } from "./CheckBoxComponent";
export default function UserForm(props) {
  const { users, gData, setGData } = props;
  //   const [gData, setGData] = useSharedData();
  const classes = useStylesAcc();

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        padding: "10px",
        margin: "0px",
        backgroundColor: colors.grey[50],
      }}
      component={Paper}
    >
      {gData.id == 1 ? (
        <Grid item lg={8} md={8} sm={7} xs={6}>
          <Typography className={classes.heading2}>{"اعدادات المبيعات"}</Typography>

        </Grid>
      ):gData.id == 2?(
        <Grid item lg={8} md={8} sm={7} xs={6}>
        <Typography className={classes.heading2}>{"اعدادات نقاط البيع"}</Typography>

      </Grid>
      ):
        gData.id == 3?(
          <Grid item lg={8} md={8} sm={7} xs={6}>
          <Typography className={classes.heading2}>{"اعدادات المشتريات "}</Typography>
  
        </Grid>
      ):gData.id == 4?(
        <Grid item lg={8} md={8} sm={7} xs={6}>
        <Typography className={classes.heading2}>{"اعدادات البيانات الاساسية "}</Typography>

      </Grid>):gData.id == 5?(
        <Grid item lg={8} md={8} sm={7} xs={6}>
        <Typography className={classes.heading2}>{"اعدادات عرض السعر "}</Typography>

      </Grid>):
      (
        <Grid item lg={8} md={8} sm={7} xs={6}>
        <Typography className={classes.heading2}>{"اعدادات امر الشراء "}</Typography>

      </Grid>)}

      <Grid item lg={3} md={3} sm={4} xs={6}>
        <Autocomplete
          value={gData.user}
          onChange={(event, newValue) => {
            // onChangeAutoData("user", newValue);
            setGData({ ...gData, user: newValue });
          }}
          id="combo-box-demo"
          size="small"
          options={users}
          //getOptionLabel={(option) => option.name}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.username
          }
          style={{}}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              label="المستخدم"
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Container>
  );
}
