import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import { sto } from "../../../../Context/InshData";
import { uri } from "../../../../help/Api";
import LoadingPage from "../../../../services/LoadingPage";
import SafesTransfer from "./SafesTransfer";
const defaultAutos = {
  Safes: [],
};

export default function SafesRecieveCon() {
  
  
  const tb = {
    wait: true,
    Safe: true,
    Branch: true,
    costcenters:true,
    other: true,
    Units: true,
    EmpItems:true,
    stor: sto,

    parms: { safe: -1,tname:"Estelam" ,iempid: sto.empi,
    },
  };

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "GetD",
    tb
  );

  if (fetchAll || !AutoOptions?.Safes) return <LoadingPage />;

  return (
    <SafesTransfer
      {...{
        AutoOptions: AutoOptions?.Safes ? AutoOptions : defaultAutos,
        isEstelam:true,
      }}
    />
  );
}
