import React, { useState, useEffect } from "react";

import { CostType, SAdefaultValues } from "../../../../../services/currencySalesProfit-services";
import {
  Button,
  colors,
  Container,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import SelectTypesFilter from "./CurrencySalesProfit-typeFilter";
import CurrencySalesProfitForm from "./CurrencySalesProfit-form";
import CurrencySalesProfitTable from "./CurrencySalesProfit-table";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { insh as inshst } from "../../../../../Context/InshSt";

import {
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { selectDateOrTime } from "../../../../../services/saleAnalysis-services";
import { sto } from "../../../../../Context/InshData";
import moment from "moment";
import DailyReportFilter from "../EveryDay/DailyReportFilter";
import CurrenciesProTables from "./CurrenciesProTables";
import { excelHeaders } from "../../../../../services/accMasn-services";
import { CSVLink } from "react-csv";
import { TableChart } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.green[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },
  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
}));

export default function CurrencySalesProfit(props) {
  const { AutoOptions } = props;

  let isPurches = false;
  let idSafe = AutoOptions.Safes[0].id;
  let nameSafe = AutoOptions.Safes[0].name;

  let idBranch = AutoOptions.Branches[0].id;
  let nameBranch = AutoOptions.Branches[0].name;

  const SearchActivityReports = {
    dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    timeFrom: moment(new Date()).format('HH:mm'),
    dateTo: moment(new Date()).format('YYYY-MM-DD'),
    timeTo: moment(new Date()).format('HH:mm'),
    safe: { id: sto.User_typei == 1 ? -1 : idSafe, name: sto.User_typei == 1 ? 'الكل' : nameSafe },
    branch: { id: sto.User_typei == 1 ? -1 : idBranch, name: sto.User_typei == 1 ? "الكل" : nameBranch },
    curr: { id: -1, name: 'الكل' },
    selectTime: selectDateOrTime[0],
    chk_withvat: false,
    type_Cost: CostType[0],
  };
  const [rptParams, setRptParams] = React.useState(SearchActivityReports);
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);
  const his = useHistory();

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `RptSales/CurrencySalesProfit?currency=${rptParams.curr.id}
    &&branch=${rptParams?.branch.id}
    &&dateFrom=${rptParams?.dateFrom + ' ' + rptParams.timeFrom}
    &&dateTo=${rptParams?.dateTo + ' ' + rptParams.timeTo}
    &&safe=${rptParams.safe?.id}
    &&chk_withvat=${rptParams?.chk_withvat}
    &&type_Cost=${rptParams.type_Cost?.id}
    &&date_time=${rptParams.selectTime.name}`,
      dependencyArray: [],
    },
  });

  const printHandle2 = () => {
    const info = {
      ...rptParams,
      data: data?.CurrencySalesProfit,
      totals: data?.totals,
      no: 2,
      title: "صافي مبيعات وارباح الاصناف",
    };

    console.log("the name is", info);
    his.push({ pathname: "/SalesManPrint", state: info });

    // window.location.reload(false);
  };
  let excel_name = "ItemsProfitReport";
  const excelHeaders = [
    { label: t("item"), key: "txtType", },
    { label: t("quantity-sold"), key: "qty", },
    { label: t("total-sales-value"), key: "totNet", },
    { label: t("total-sales-cost"), key: "cost", },
    { label: t("profit"), key: "Profit", },
  ];
  const printHandle = () => {
    if (data.lenght == 0) {
      alert("لاتوجد بيانات");
    } else {
      const info = {
        data: data?.CurrencySalesProfit,
        tot: data?.totals,
        title: "صافي مبيعات وارباح الاصناف",
        auto: {},
        fDate: rptParams.dateFrom,
        tDate: rptParams.dateTo,
        ElmntShow: true,
        safe: rptParams.safe?.name,
        stock: "الكل",
        branch: rptParams.branch?.name,
        cust: "الكل",
        sales_man: "الكل",
        username: "الكل",
        group: "الكل",
        labelname: t("item"),
        //totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
      };
      localStorage.setItem("iInfos", JSON.stringify(info));
      his.push({ pathname: "/AnalysisPrint" });
      window.location.reload(true);
    }

  };
  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography size="small" className={classes.headerTitle}>
          {t("translation:sale-profit")}
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            borderBottomColor: colors.green[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <Grid container item lg={12} md={12} sm={12} xs={12} >
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <DailyReportFilter {...{ rptParams, setRptParams, isPurches }} />
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={12}>
              <SelectTypesFilter {...{ rptParams, setRptParams }} />
            </Grid>
          </Grid>
          <CurrencySalesProfitForm
            {...{ AutoOptions, rptParams, onChangeData, onChangeAutoData, isPurches }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Hidden smUp={["sm"]}>
              <Grid item>
                <Button
                  disabled={fetching}
                  variant="contained"
                  startIcon={<ScannerIcon />}
                  size="large"
                  style={(inshst.sal.iconBtn, { borderRadius: 0 })}
                  className={[clasui.button]}
                  onClick={() => fetchURL()}
                >
                  {t("forms:show")}
                </Button>
              </Grid>
            </Hidden>
            <Hidden only={["xs"]}>
              <Grid item>
                <Button
                  disabled={fetching}
                  variant="contained"
                  startIcon={<ScannerIcon />}
                  size="large"
                  style={(inshst.sal.iconBtn, { borderRadius: 0 })}
                  className={[clasui.button]}
                  onClick={() => fetchURL()}
                >
                  {t("forms:view")}
                </Button>
              </Grid>
            </Hidden>

            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={(inshst.sal.iconBtn, { borderRadius: 0 })}
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Hidden smUp={["sm"]}>
              <Grid item>
                {data && !fetching && data.saleAnalysis && (
                  <CSVLink
                    separator={";"}
                    filename={excel_name}
                    data={data.saleAnalysis}
                    headers={excelHeaders}
                  >
                    {" "}
                    <Button
                      disabled={fetching}
                      variant="contained"
                      startIcon={<TableChart />}
                      size="large"
                      color="primary"
                      style={{ borderRadius: 0 }}
                      className={[clasui.button]}
                    >
                      {"اكسل"}
                    </Button>
                  </CSVLink>
                )}
              </Grid>
            </Hidden>
            <Hidden only={["xs"]}>
              <Grid item>
                {data && !fetching && data.CurrencySalesProfit && (
                  <CSVLink
                    separator={";"}
                    filename={excel_name}
                    data={data.CurrencySalesProfit}
                    headers={excelHeaders}
                  >
                    {" "}
                    <Button
                      disabled={fetching}
                      variant="contained"
                      startIcon={<TableChart />}
                      size="large"
                      color="primary"
                      style={{ borderRadius: 0 }}
                      className={[clasui.button]}
                    >
                      {"تصدير اكسل"}{" "}
                    </Button>
                  </CSVLink>
                )}
              </Grid>
            </Hidden>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={(inshst.sal.iconBtn, { borderRadius: 0 })}
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("forms:quantity-sold"),
                  value: data?.totals?.qtyTotal,
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("total-sales-value"),
                  value: data?.totals?.totNetTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("total-sales-cost"),
                  value: data?.totals?.costTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("profit"),
                  value: data?.totals?.profitTotal.toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.green[300]} />
              {!fetching && (
                // <CurrencySalesProfitTable
                <CurrenciesProTables
                  {...{
                    data: data?.CurrencySalesProfit
                      ? data.CurrencySalesProfit
                      : [],
                    title: rptParams.type_Cost.label,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
