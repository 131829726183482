import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../../Context/InshData";
import { uri } from "../../../../../../help/Api";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../../../services/LoadingPage";
import SearchReceipt from "./SearchReceipt";
const defaultAutos = {
  Branches: [],
};

export default function SearchReaceiptCon(props) {
  const {
    openSearchMdl,  handCloseDw,
    onRowClick, URL ,type,tname,AutoOptions, transition ,isInternal} = props;

//   const t = type==2?2:1;
//   const url = uri + "Auto" + `/custm?t=${t}`;
//   const { data: CustomerOptions, fetching: fetchCust } = useFetchURL({
//     ...{ url, dependencyArray: [] },

  return (

    <SearchReceipt
      {...{
        openSearchMdl,  handCloseDw,
        onRowClick, URL ,type,tname,AutoOptions,transition,isInternal
          }}
    />
  );
}
