import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";

import LoadingPage from "../../../services/LoadingPage";
import * as _ from "lodash";
import { stElemnt } from "../../../St/Reviews/cStyle";
import { Grid, Typography } from "@material-ui/core";
import FooterA from "../../Safes/Jard/A4/FooterA";
import { Rept } from "../../../Context/InshData";
import useChunkMyData from "../../../components/Hooks/useChunkMyData";
import { useLocation } from "react-router-dom";
import { sto } from "../../../Context/InshData";
import HeaderAShi from "./A4/HeaderAShi";
import BodyDaily from "../EvryDay/A4/BodyDa";

function BillPage(props) {

    const { chunk, isFirst, isLast, info, loc } = props;

    return (
        <Grid item container style={{ ...stElemnt.AF.Reviws.pdf_page }}>

            <Fragment>
                <Grid xs={12} item style={{ maxHeight: "27.5cm", minHeight: "27.5cm" }}>
                    {isFirst && <HeaderAShi info={info} loc={loc} data={chunk} />}
                    <BodyDaily
                        data={chunk ?? []}
                        isLast={isLast}
                    />
                </Grid>

                <Grid xs={12} item style={{ maxHeight: "1.8cm", minHeight: "1.8cm" }}>
                    <FooterA found={info}
                        isLast={isLast} />
                </Grid>
            </Fragment>
        </Grid>
    );
}
function RevAShiftsCon(props) {
    const loc = useLocation();
    const { chunks, chunksCount } = useChunkMyData(Rept.data, 30, 37);
    const [info, setInfo] = useState({});

    const [loading, setLoading] = useState(true);

    let count_id = 0;
    const getInfo = async () => {
        const tb = {
            GetFoundation: true,
            wait: true,
            parms: {
                iFoundid: 1, branch: sto.branchi,
            },
            autos: {},
        };
        console.log(tb);
        await axios
            .post(`${uri}Res`, tb)
            .then((res) => {
                console.log("res.data.RptInv");
                setInfo(res.data.Found);
            })
            .catch((error) => {
                console.log("catch");
            });
    };
    useEffect(() => {
        setTimeout(async () => {
            await getInfo();
            setLoading(false);
            // onUnmountComplete();
        }, 0);
    }, []);

    if (!info || loading) return <LoadingPage />;


    return (
        <Typography component="div" style={stElemnt.AF.Reviws.ReviwsJard}>

            <Typography component="div" >
                {chunks.map((page, i) => {
                    return (
                        <BillPage
                            {...{
                                chunk: page,
                                isFirst: i == 0,
                                isLast: i == chunksCount - 1,
                                info,
                                loc
                            }}
                        />
                    );
                })}
            </Typography>
        </Typography>
    );
}
export default RevAShiftsCon;
