import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial,
} from '../../../../../St/Reports/UseStyle';
import { get } from "@awesome-cordova-plugins/core/decorators/common";
import { invoicePayTypes } from "../../../../../services/invoice-service";
import { sto } from "../../../../../Context/InshData";
export default function TransfersSearchForm(props) {
    const {
        rptParams,
        onChangeData,
        isEstelam,
        changTField,
    } = props;

    const { t } = useTranslation(["forms"]);

    const clasup = useStylesUp();
    const calsPurch = useStylesAutoPurch();
    const claslbl = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();


    const clashd = useStylesHed();
    const clasBA = useStylesBA();
    const clasTD = useStylesTD();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    return (
        <React.Fragment>
            <Grid container style={{ padding: 5 }} spacing={1}>

                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small"
                        id="bill_no"
                        label="رقم الامر"
                        className={clasTF.rootSafe}
                        InputProps={{ className: claslbl.clrBlackTxt }}
                        type="text"
                        name="no" value={rptParams.bill_no} onChange={onChangeData('no')}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small"
                        id="refno"
                        label="رقم المرجع"
                        placeholder="رقم المرجع"

                        className={clasTF.rootSafe}
                        InputProps={{ className: claslbl.clrBlackTxt }}
                        type="text"
                        name="refno" value={rptParams.refno} onChange={onChangeData('refno')}
                    />
                </Grid>
                {isEstelam && (
                    <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                        <TextField fullWidth variant="outlined" size="small"
                            id="refno"
                            label="رقم امر التحويل"
                            placeholder="رقم امر التحويل"

                            className={clasTF.rootSafe}
                            InputProps={{ className: claslbl.clrBlackTxt }}
                            type="text"
                            name="transfer_no" value={rptParams.transfer_no} onChange={onChangeData('transfer_no')}
                        />
                    </Grid>
                )}
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small" id="dateFrom" label="من تاريخ"
                        className={clasTF.rootSafe}
                        InputProps={{ className: claslbl.clrBlackTxt }}
                        type="date" format="YYYY-MM-DD"
                        name="dateFrom" value={rptParams.dateFrom} onChange={onChangeData('dateFrom')}
                    /></Grid>

                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small" id="dateTo" label="إلى تاريخ"
                        className={clasTF.rootSafe}
                        InputProps={{ className: claslbl.clrBlackTxt }}
                        type="date" format="YYYY-MM-DD"
                        name="dateTo" value={rptParams.dateTo} onChange={onChangeData('dateTo')}
                    /></Grid>


            </Grid>
        </React.Fragment>
    );
}
