import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial,
} from '../../../../../St/Reports/UseStyle';
import { stElemnt } from "../../../../../St/comps/cStyle";
//=====================================================================
import SubUnits from '../../subUnits/SubUnits';
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";
import { useStylesContainers } from '../../../../../St/comps/UseStyle';
import { Checkbox, colors, Container, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import ItemsImage from '../items-image';
import Button from '../../../../../components/controls/Button';
import ScannerIcon from "@material-ui/icons/Scanner";
import CodeIcon from "@material-ui/icons/Code";

import { backgroundImage1 } from '../../../../../components/invoice-generator/backgrounImage';
import KhamItems from '../KhamItems/KhamItems';
export default function ItemsForms(props) {

    const {

        AutoOptions,
        items,
        setItems,
        changTField,
        changAutos,
        currUnitList,
        setCurrUnitList,
        valueAutos,
        autos_curr,
        setOpenMulti,
        styl,
        clasui,
        changePurchPrice,
        dataList,
        setDataList
    } = props;

    const clasup = useStylesUp();
    const calsPurch = useStylesAutoPurch();


    const clsConts = useStylesContainers();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();
    const clasTD = useStylesTD();
    const claslbl = useStylesLbl();
    const { t } = useTranslation(["forms", "translation"]);

    const [showPic, setShowPic] = useState(false);
    return (
        <React.Fragment>
            <Grid container style={{ padding: 5 }} justifyContent="center" spacing={2}>
                <Grid
                    item
                    container
                    spacing={1}

                    justifyContent='center'

                    lg={12} md={12} sm={12} xs={12}
                >

                    <Grid item lg={3} md={3} sm={4} xs={3}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="id"

                            label={t("forms:product-id")}
                            //className={clasTF.clrBlack}
                            InputLabelProps={{ className: claslbl.clrBlackLblS }}
                            InputProps={{ className: claslbl.clrBlackTxt, readOnly: true }}
                            // InputProps={{ className: clasTD.input, readOnly: true }}
                            type="text"
                            name="id"
                            value={items.id}
                            
                            onChange={changTField("id")}
                        />
                    </Grid>

                    <Grid item lg={7} md={7} sm={7} xs={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="barcode"
                            label={t("forms:barcode")}
                            type="text"
                            name="barcode"
                            value={items.barcode}
                            InputLabelProps={{ className: claslbl.clrBlackLblS,
                                   
                             }}
                            InputProps={{ className: claslbl.clrBlackTxt }}
                            onChange={changTField("barcode")}
                        />
                    </Grid>

                    <Grid item container lg={2} md={2} sm={2} xs={3} >


                        <Grid item>

                            <Button
                                disabled={false}
                                variant="contained"
                                startIcon={<ScannerIcon />}
                                size="large"
                                style={{ backgroundColor: "#f18584", borderRadius: 0 }}
                                className={[clasui.buttonB]}
                                onClick={() => setOpenMulti(true)}
                            >
                                {"Barcode"}
                            </Button>
                        </Grid>

                        {/* </Container> */}
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="symbol"
                            label={t("forms:product-name-ar")}
                            className={clasTF.rootCust}
                            InputLabelProps={{ className: claslbl.clrBlackLbl }}
                            InputProps={{ className: claslbl.clrBlackTxt }}
                            type="text"
                            name="symbol"
                            value={items.symbol}
                            onChange={changTField("symbol")}
                        />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="nameEN"
                            label={t("forms:product-name-en")}
                            className={clasTF.rootCust}
                            InputLabelProps={{ className: claslbl.clrBlackLbl }}
                            InputProps={{ className: claslbl.clrBlackTxt }}
                            type="text"
                            name="nameEN"
                            value={items.nameEN}
                            onChange={changTField("nameEN")}
                        />
                    </Grid>
                    <Grid
                        item
                        className={clasTF.tool}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        style={{
                            padding: "0px",
                            color: "#81007f",
                            textAlign: "-webkit-center",
                            marginBottom: 5
                        }}
                    >
                        <IconButton
                            color="inherit"
                            style={{
                                padding: "10px",
                                color: "#81007f",
                                border: "solid 0.1px",
                                borderRadius: 50,
                            }}
                            aria-label="open drawer"
                        // onClick={handleOpenCust_omer}
                        >
                            <AddIcon />
                        </IconButton>
                    </Grid>

                    <Grid item className={clasTF.tool} lg={5} md={10} sm={10} xs={10} >
                        <Autocomplete
                            classes={clasAo}
                            value={valueAutos.group_id}
                            labelPlacement=""
                            onChange={(event, newValue) => {
                                changAutos("group_id", newValue);
                            }}
                            id="controllable-states-demo"
                            size="small"
                            options={AutoOptions.Groupst}
                            getOptionLabel={(option) =>
                                typeof option === "string" ? option : option.name
                            }
                            // style={{  }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("forms:product-group")}
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{ className: claslbl.clrBlackLbl }}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item className={clasTF.tool} lg={5} md={5} sm={12} xs={12}>
                        <Autocomplete
                            classes={clasAo}
                            value={valueAutos.branch}
                            labelPlacement=""
                            onChange={(event, newValue) => {
                                changAutos("branch", newValue);
                            }}
                            id="controllable-states-demo"
                            size="small"
                            options={AutoOptions.Branches}
                            getOptionLabel={(option) =>
                                typeof option === "string" ? option : option.name
                            }
                            // style={{  }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("forms:branch")}
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{ className: claslbl.clrBlackLbl }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "0.3rem" }} >
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="purch_price"
                            label={t("forms:purch-price")}
                            // InputProps={{ className: clasTD.input }}
                            InputLabelProps={{ className: claslbl.clrBlackLbl }}
                            InputProps={{ className: claslbl.clrBlackTxt }}
                            type="text"
                            name="purch_price"
                            value={items.purch_price}
                            onChange={changTField("purch_price")}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "0.3rem" }}  >
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="sale_price"
                            label={t("forms:sale-price")}
                            style={{ marginBottom: 5 }}
                            //InputProps={{ className: clasTD.input }}
                            InputLabelProps={{ className: claslbl.clrBlackLbl }}
                            InputProps={{ className: claslbl.clrBlackTxt }}
                            type="text"
                            name="sale_price"
                            value={items.sale_price}
                            onChange={changTField("sale_price")}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={4} xs={6} style={{ marginBottom: "0.3rem" }} >
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="min_sale_price"
                            label={t("forms:little-sale-price")}
                            // InputProps={{ className: clasTD.input }}
                            InputLabelProps={{ className: claslbl.clrBlackLbl }}
                            InputProps={{ className: claslbl.clrBlackTxt }}
                            type="text"
                            name="min_sale_price"
                            value={items.min_sale_price}
                            onChange={changTField("min_sale_price")}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "0.3rem" }} >
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="Distributor_price"
                            label={t("moz-price")}
                            //InputProps={{ className: clasTD.input }}
                            InputLabelProps={{ className: claslbl.clrBlackLbl }}
                            InputProps={{ className: claslbl.clrBlackTxt }}
                            type="text"
                            name="Distributor_price"
                            value={items.Distributor_price}
                            onChange={changTField("Distributor_price")}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "0.3rem" }} >
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="jomla_price"
                            label={t("forms:wholesale-price")}
                            // InputProps={{ className: clasTD.input }}
                            InputLabelProps={{ className: claslbl.clrBlackLbl }}
                            InputProps={{ className: claslbl.clrBlackTxt }}
                            type="text"
                            name="jomla_price"
                            value={items.jomla_price}
                            onChange={changTField("jomla_price")}
                        />
                    </Grid>
                    <Grid item lg={6} md={4} sm={4} xs={6} style={{ marginBottom: "0.3rem" }} >
                        <Autocomplete
                            classes={clasAo}
                            value={valueAutos.type}
                            labelPlacement=""
                            onChange={(event, newValue) => {
                                changAutos("type", newValue);
                            }}
                            id="controllable-states-demo"
                            size="small"
                            options={autos_curr.currType}
                            getOptionLabel={(option) =>
                                typeof option === "string" ? option : option.name
                            }
                            // style={{  }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("forms:product-type")}
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{ className: claslbl.clrBlackLbl }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item className={clasTF.tool} lg={7} md={7} sm={7} xs={7} style={{ marginBottom: "0.3rem" }} >
                        <Autocomplete
                            classes={clasAo}
                            value={valueAutos.tax_group}
                            labelPlacement=""
                            onChange={(event, newValue) => {
                                changAutos("tax_group", newValue);
                            }}
                            id="controllable-states-demo"
                            size="small"
                            options={AutoOptions.tax_groups}
                            getOptionLabel={(option) =>
                                typeof option === "string" ? option : option.name
                            }
                            // style={{  }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("forms:tax-group")}
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{ className: claslbl.clrBlackLbl }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item className={clasTF.tool} lg={5} md={5} sm={5} xs={5} style={{ marginBottom: "0.3rem" }} >
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="tax"
                            label=" % "
                            InputLabelProps={{ className: claslbl.clrBlackLbl }}
                            InputProps={{ className: clasTD.clrBlackTxt, readOnly: true }}
                            type="text"
                            name="tax"
                            value={items.tax}
                        /*onChange={changTField('tax')}*/
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "0.3rem" }} >
                        <Autocomplete
                            classes={clasAo}
                            value={valueAutos.unit}
                            labelPlacement=""
                            onChange={(event, newValue) => {
                                changAutos("unit", newValue);
                            }}
                            id="controllable-states-demo"
                            size="small"
                            options={AutoOptions.Units}
                            getOptionLabel={(option) =>
                                typeof option === "string" ? option : option.name
                            }
                            // style={{  }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("forms:default-unit")}
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{ className: claslbl.clrBlackLbl }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "0.3rem" }} >
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="prody_date"
                            label={t("forms:procuction-date")}
                            InputLabelProps={{ className: clasTD.clrBlackLbl }}
                            InputProps={{ className: clasTD.clrBlackTxt }}
                            type="date"
                            format="YYYY-MM-DD"
                            name="prody_date"
                            value={items.prody_date}
                            onChange={changTField("prody_date")}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={6} style={{ marginBottom: "0.3rem" }} >
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="expiry_date"
                            label={t("forms:expiry-date")}
                            InputLabelProps={{ className: clasTD.clrBlackLbl }}
                            InputProps={{ className: clasTD.clrBlackTxt }}
                            type="date"
                            format="YYYY-MM-DD"
                            name="expiry_date"
                            value={items.expiry_date}
                            onChange={changTField("expiry_date")}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={6} style={{ marginBottom: "0.3rem" }} >
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        fullWidth
                                        checked={items?.is_tab3_curr}
                                        onChange={(e) => {
                                            setItems({
                                                ...items,
                                                is_tab3_curr: e.target.checked ? 1 : 0,
                                            });
                                            console.log("the currl is", items);
                                        }}
                                        name="is_tab3_curr"
                                    />
                                }
                                size="small"
                                color="primary"
                                fontSize="small"
                                label="ضريبة تبغ"
                                InputLabelProps={{ className: claslbl.clrBlackLbl }}
                                InputProps={{ className: clasTD.clrBlackTxt }}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={6} style={{ marginBottom: "0.3rem" }} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    fullWidth
                                    checked={showPic}
                                    onChange={(e) => {
                                        setShowPic(e.target.checked)
                                    }}
                                />
                            }
                            size="small"
                            color="primary"
                            fontSize="small"
                            label="Add Picture"
                            InputLabelProps={{ className: claslbl.clrBlackLbl }}
                            InputProps={{ className: clasTD.clrBlackTxt }}
                        />
                    </Grid>
                    {showPic && (<Grid item container lg={12} md={12} tyle={{ marginBottom: "0.3rem" }} className={clsConts.rootup}>

                        <Grid
                            item
                            container
                            style={{ textAlign: "center" }}
                            xs={12}
                            className={[clsConts.subUp, clsConts.subUpL]}
                        >
                            <ItemsImage
                                {...{
                                    items,
                                    setItems,
                                }}
                            />
                        </Grid>
                    </Grid>)}
                    {items?.type == 3 ? (
                        <KhamItems
                            {...{
                                // addSubUnitFunc: () => {},
                                AutoOptions,
                                khamItemsList: AutoOptions.Items?.filter(c => c.type == 2),
                                // units: gets.Units,
                                dataList,
                                setDataList,
                                changePurchPrice,
                                items,
                                setItems,
                            }}
                        />
                    ):
                    <SubUnits
                    {...{
                        items,
                        addSubUnitFunc: () => { },
                        units: AutoOptions.Units,
                        currUnitList,
                        setCurrUnitList,
                    }}
                />}
                  

                </Grid>
            </Grid>



        </React.Fragment>
    );

}