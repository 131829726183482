import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useInvAF, useInvMain } from "../../../St/Reviews/UseStyle";
import { Container, Divider, Input, makeStyles } from "@material-ui/core";
import { sto } from "../../../Context/InshData";
import { txtA, txtE } from "../../../Label/Lbl";
import moment from "moment";
import { useBody, useHeader } from "./style";

function Header(props) {
  const { locInv, sta ,info} = props;
  const clasm = useInvMain();
  const clas = useInvAF();
  const calssBody = useBody();
  const classHeader = useHeader();
  // const clasH = useInvHeadrAF();
  const premiApp = JSON.parse(localStorage.getItem("premiApp")) || {};

  //  musa



  //  const secondColor = '#31b835';
  const secondColor = "#be151f";
  const styf = { fontWeight: "1200" };
  const stysub = { fontWeight: "bold", color: secondColor };
  const styfC = { fontWeight: "500" };
  // useEffect(() => {
  //   setTimeout(async () => {
  //     await getInfo();
  //     // onUnmountComplete();
  //   }, 0);
  // }, []);

  return (
    <Fragment>


      {info.header_img == null && premiApp?.billTemplate == 7 ? 
      
      (
        <Grid
          item
          container
          //className={classHeader.headerContainer}
          style={{ paddingTop:"0.3rem", fontWeight: "bold" ,   
          }}
        >
          <Grid item xs={5} className={classHeader.leftHeaderen}>
            <div style={styf}>{info.Disc1_header_en}</div>
            <div style={stysub}>{info.Disc2_header_en}</div>
            <div style={styfC}>{info.Disc3_header_en}</div>{" "}
            <div style={styfC}>{info.Disc4_header_en}</div>
            <div style={styf}>{"Vat number " + info.tax_no}</div>
          </Grid>
  
          <Grid item xs={2} className={clasm.mincntr}>
            <div>
              <ButtonBase>
                {" "}
                <img
                  src={`data:image/jpeg;base64,${info.Logo}`}
                  className={clas.Hedlogo}
                  alt={info.nameA}
                />{" "}
              </ButtonBase>
            </div>
          </Grid>
  
          <Grid item xs={5} className={classHeader.rightHeaderar}>
           <div style={styf}>{info.Disc1_header_ar}</div>{" "}
            <div style={stysub}>{info.Disc2_header_ar}</div>{" "}
            <div style={styfC}>{info.Disc3_header_ar}</div>{" "}
            <div style={styfC}>{info.Disc4_header_ar}</div>{" "}
            <div style={styf}>{"الرقم الضريبي " + info.tax_no}</div>
          </Grid>
        </Grid>) 
      
      :
            (<Grid item xs={12} justifyContent="center" style={{ minWidth: "20cm", maxWidth: "20cm", minHeight: "3.6cm", maxHeight: "3.6cm", paddingBottom: "0.2cm", paddingTop: "0.1cm" }}>
              <div>
                <ButtonBase>
                  {" "}
                  <img
                    src={`data:image/jpeg;base64,${info.header_img}`}
                    className={clas.headerImg}
                    alt={info.nameA}
                  />{" "}
                </ButtonBase>
              </div>
            </Grid>)}

      <Container>
        <Divider style={{ background: secondColor, height: 1.5 }} />
        <Divider
          style={{ background: secondColor, marginTop: 3, height: 1.5 }}
        />
      </Container>
      <Container>
        <Grid
          container
          item
          // className={clas.Bdyroot}
          xs={12}
          style={{ direction: "rtl", paddingTop: "0.2rem" }}
        >
          <Grid xs={3} item container style={{ paddingTop: 10 }}>
            <Grid item xs={6} className={calssBody.txt}>
              التاريخ
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>
                {moment(sta?.tot?.date).format("YYYY/MM/DD")}
              </Typography>
            </Grid>
            {premiApp.billTemplate !== 7 && (
            <Grid item xs={6} className={calssBody.txt}>
            اجمالي الكمية{" "}
          </Grid>
        
          )}
           {premiApp.billTemplate !== 7 && (
            <Grid item xs={6}>
            <Typography className={calssBody.minclr}>
              {sta?.info?.reduce(
                (sum, item) => Number(sum) + Number(item.val1),
                0
              )}
            </Typography>
          </Grid>
          )}
           
          </Grid>
          <Grid xs={6} item>
            {locInv.ProcTypeInvProc != "03" && (
              <Typography
                className={calssBody.minclr}
                variant="h5"
                gutterBottom
              >
                {/* {info.nameA} */}
                {sta?.tot?.tax_no_cust === ""
                  ? "فاتورة ضريبية مبسطة"
                  : "فاتورة ضريبية"}
              </Typography>
            )}
            <Typography className={calssBody.minclr} variant="h6" gutterBottom>
              {locInv.invN + " "}
              {!premiApp?.hidePayType
                ? sta?.tot?.inv_type_name + " " + sta?.tot.pay_type_name
                : ""}
              {premiApp?.hidePayType && sta?.tot?.inv_type_name == "نقدية"
                ? sta?.tot?.inv_type_name
                : ""}
            </Typography>
          </Grid>
          <Grid
            xs={3}
            item
            container
            style={{
              paddingTop: 10,
              paddingLeft: 10,
              direction: "ltr",
              color: secondColor,
            }}
          >
            <Grid item xs={6}>
              <Typography
                className={calssBody.txt}
                style={{ fontWeight: "bold" }}
              >
                Inv No
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>
                {sta?.tot?.id}
              </Typography>
            </Grid>
            <Grid item xs={6} className={calssBody.txt}>
              Ref No
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>
                {sta?.tot?.refno}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container style={{ marginTop: 15 }}>
            <Grid
              item
              xs={10}
              className={calssBody.txt}
              container
              style={{ fontWeight: "bold" }}
            >
              <Grid item xs={2}>
                {txtA.custmName + "/ "}
              </Grid>
              <Grid item xs={10}>
                {sta?.tot?.cust_name}
                {sta?.tot?.cust_address?.length > 2
                  ? "  -  " + sta?.tot?.cust_address
                  : ""}
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={10}
              container
              className={calssBody.txt}
              style={{ fontWeight: "bold", paddingTop: 5 }}
            >
              <Grid item xs={2}>
                {txtA.taxNumr + "/ "}
              </Grid>
              <Grid item xs={10}>
                {sta?.tot?.tax_no_cust}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}
export default Header;
