import React, { useState, useEffect } from "react";

import { SAdefaultValues, selectDateOrTime } from "../../../../../services/saleAnalysis-services";
import {
    Button,
    Chip,
    colors,
    Container,
    Dialog,
    Grid,
    Hidden,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
    useStylesHed,
    useStylesSpDial,
    useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import { sto } from "../../../../../Context/InshData";
import ReplyIcon from "@material-ui/icons/Reply";
import TransfersSearchTable from "./TransfersSearchTable";
import TransfersSearchForm from "./TransfersSearchForm";

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
    },
    itemW: {
        // display:'flex',
        width: "90%",
    },
    headerTitle: {
        backgroundColor: colors.green[50],
        textAlign: "center",
        fontSize: 20,
        width: "100%",
    },

    allWidth: {
        minWidth: 650,
    },
    checStyl: {
        marginTop: "17px",
        fontSize: "16px",
        padding: "-1px",
    },
    purchTotalCard: {
        backgroundColor: colors.blue[50],
        borderColor: colors.blue[300],
    },
}));

export default function TransfersSearch(props) {
    const {isEstelam,
        getFormatSTransferData,
        handOpenDw,
        TransitionUp,setPrevent,openDw,transition,handCloseDw,getSearchHeadData,setEstelamAfter} = props;
  
    const initialSearchObject = {
        dateFrom:  moment(new Date()).format('YYYY-MM-DD'),
        timeFrom: moment(new Date()).startOf("day").format("HH:mm"),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
        timeTo: moment(new Date()).format('HH:mm'),
        branch:sto.branchi,
        refno:'',
        no:'',
        type:isEstelam?2:1,
        transfer_no:'',
    };
   
    const [rptParams, setRptParams] = React.useState(initialSearchObject);
    const classes = useStyles();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    const { t } = useTranslation(["forms", "translation"]);

    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const viewHandle = async () => {

        setData([]);
        setFetching(true);
        await axios
            .get(
                uri +
                `RptSafes/TransfersSearch?branch=${rptParams.branch}
        &&trans_no=${rptParams?.no}
        &&fDate=${rptParams.dateFrom + ' ' + rptParams.timeFrom}
        &&tDate=${rptParams.dateTo + ' ' + rptParams.timeTo}
        &&refno=${rptParams?.refno}
        &&transfer_no=${rptParams?.transfer_no}
        &&type=${rptParams.type}`
            )
            .then((res) => {
                setData(res.data);
                console.log(" the data is", res.data);
                setFetching(false);
            })
            .catch((err) => {
                setFetching(false);
                console.log(err);
                // toast.err("يوجد خطأ في الجلب");
                setData([]);
            });
    };
    const his = useHistory();


    useEffect(() => {
        console.log("the data is", rptParams);
    });
    const changTField = (prop) => (e) => {
        setRptParams({ ...rptParams, [prop]: e.target.value });

        // prop=='dateFrom' ? rptParams.dateFrom=e.target.value : rptParams.dateTo=e.target.value
    };
    const onChangeData = (propName) => (event) => {
        setRptParams({ ...rptParams, [propName]: event.target.value });
    };
    const onChangeAutoData = (propName, newValue) => {
        setRptParams({ ...rptParams, [propName]: newValue });
    };
    return (
        <Dialog
            fullScreen
            open={openDw}
            onClose={handOpenDw}
            TransitionComponent={transition}
            key={transition ? transition.name : ""}
        >
            <div className={classes.root}>
                <Grid container item xs={12}>
                    <Typography
                        size="small"
                        className={classes.headerTitle}
                        color="primary"
                    >
                        {!isEstelam?" البـحـث فـي اوامر التحويل المخزني ":" البـحـث فـي اوامر الاستلام المخزني "}
                    </Typography>
                </Grid>
                <Typography
                    component="div"
                    style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
                >
                    <Container
                        component={Paper}
                        maxWidth={false}
                        style={{
                            padding: "10px",
                            paddingBottom: "0px",
                            borderBottomColor:  colors.blue[500] ,
                            borderBottomWidth: "1px",
                            borderBottomStyle: "solid",
                        }}
                    >
                        <TransfersSearchForm
                            {...{
                                rptParams,
                                onChangeData,
                                isEstelam,
                                changTField,
                            }}
                        />
                        <Grid
                            container
                            justifyContent="center"
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            spacing={1}
                        >
                            <Hidden smUp={["sm"]}>
                                <Grid item>
                                    <Button
                                        disabled={fetching}
                                        variant="contained"
                                        startIcon={<ScannerIcon />}
                                        size="large"
                                        style={
                                            ( inshst.sal.iconBtn,
                                                { borderRadius: 0 })
                                        }
                                        className={[clasui.button]}
                                        onClick={() => viewHandle()}
                                    >
                                        {t("forms:show")}
                                    </Button>
                                </Grid>
                            </Hidden>
                            <Hidden only={["xs"]}>
                                <Grid item>
                                    <Button
                                        disabled={fetching}
                                        variant="contained"
                                        startIcon={<ScannerIcon />}
                                        size="large"
                                        style={
                                            (inshst.sal.iconBtn,
                                                { borderRadius: 0 })
                                        }
                                        className={[clasui.button]}
                                        onClick={() => viewHandle()}
                                    >
                                        {t("forms:view")}
                                    </Button>
                                </Grid>
                            </Hidden>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    // startIcon={<KeyboardReturnSharpIcon />}
                                    startIcon={<ReplyIcon />}
                                    color="secondary"
                                    size="large"
                                    aria-label="open drawer"
                                    className={[clasui.button, clasSp.clcan]}
                                    style={
                                        (inshst.sal.iconBtn,
                                            { borderRadius: 0 })
                                    }
                                    onClick={() => handCloseDw()}
                                >
                                    {/* {t("forms:exit")} */}
                                    {"رجوع"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>

                    <br />
                    <Container
                        component={Paper}
                        maxWidth={false}
                        style={{
                            paddingRight: "5px",
                            paddingLeft: "5px",
                        }}
                    >
                        <Grid container justifyContent="center">
                            <Grid item container justifyContent="center" xs={12}>
                                <BeatLoader loading={fetching} color={colors.blue[300]} />
                                {!fetching && (
                                    <TransfersSearchTable
                                        {...{
                                            data: data?.transData ? data.transData : [],
                                            getFormatSTransferData, setPrevent,handCloseDw,getSearchHeadData,setEstelamAfter,
                                            isEstelam
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </Typography>
            </div>
        </Dialog>
    );
}
