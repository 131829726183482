import React, { useState, useEffect } from "react";

import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import TotalCard from "../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
// import { SearchActivityReports } from "../../../../../services/accMasn-services";
import moment from "moment";
import axios from "axios";
import { sto } from "../../../../Context/InshData";
import DailyJenralForms from "./DailyJenralForms";
import DailyJenralTable from "./DailyJenralTable";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor:"#d3d3d3",
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  accountCard: {
    backgroundColor: "#d3d3d3",
    borderColor: "#808080",
  },
}));

export default function DailyJenral(props) {
  const { AutoOptions } = props;

   const docTypes = [
    { id: -1, name: "الكل" },
    { id: 1, name: "فاتورة شراء " },
    { id: 2, name: "فاتورة بيع" },
    { id: 3, name: "مرتجع فاتورة شراء" },
    { id: 4, name: "مرتجع فاتورى بيع" },
    { id: 5, name: "سند قبض خارجي" },
    { id: 6, name: "سند صرف خارجي" },
    { id: 7, name: "سند قبض داخلي" },
    { id: 8, name: "سند صرف داخلي" },
    { id: 9, name: "مخزون اول مدة" },
    { id: 10, name: "قيد يومية " },
    { id: 11, name: "قيد افتتاحية" },
    { id: 12, name: "تبديل مبيعات" },
  ];
  
  const resSubType = [
    { id: -1, name: "عدم استثناء العمليات النقدية" },
    { id: 1, name: "استثناء العمليات النقديثة" },
  ];
  
  const SearchDialyJenral = {
    dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    timeFrom: moment(new Date()).format('HH:mm'),
    dateTo: moment(new Date()).format('YYYY-MM-DD'),
    timeTo: moment(new Date()).format('HH:mm'),
    type: docTypes[0],
    branch: { id: -1, name: "الكل" },
    res_id:'',
    book_id:'',
    doc_id:'',
    doc_id2:'',
    resSub:resSubType[1],
  };

  const [rptParams, setRptParams] = React.useState(SearchDialyJenral);
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [sum, setSum] = useState(false);
  const viewHandle = async () => {

    setData([]);
    setFetching(true);
    await axios
      .get(
        uri +
        `AccountReports/dialyJenral?res_id=${rptParams?.res_id}
        &&type=${rptParams.type?.id}
        &&doc_id=${rptParams?.doc_id}
        &&doc_id2=${rptParams?.doc_id2}
        &&book_id=${rptParams.book_id}
        &&branch=${rptParams?.branch?.id}
        &&dateFrom=${rptParams.dateFrom + ' ' + rptParams.timeFrom}
        &&dateTo=${rptParams.dateTo + ' ' + rptParams.timeTo}
       &&res_sub=${rptParams?.resSub?.id}`
      )
      .then((res) => {
        setData(res.data);
        console.log(" the data is", res.data);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
        console.log(err);
        // toast.err("يوجد خطأ في الجلب");
        setData([]);
      });
  };

  const his = useHistory();
  const printHandle = () => {
      
    if (data.length == 0) {
      alert("لاتوجد بيانات");
    } else {
      const info = {
        data: data?.dailyData,
        tot: data?.totals,
        from: "النشاط التجاري",
        title: "تقرير اليومية العامة",
        tot1:0,
        tot2:1 ,
        tot3:1,
        fDate:rptParams?.dateFrom,
        tDate: rptParams?.dateTo,
        nDate: moment(new Date()).format("YYYY-MM-DD"),
        branch: rptParams.branch?.name,
      };
      localStorage.setItem("iInfos", JSON.stringify(info));
      his.push({ pathname: "/ReviewActivity" });
      window.location.reload(true);
    }
  };

  let excel_name = "تقرير اليومية العامة";
  const excelHeaders = [
    { label: "رقم القيد", key: "doc_no" },
    { label: "رقم الحساب", key: "acc_no" },
    { label: "اسم الحساب", key: "NameAcount" },
    { label: "العملة", key: "symbol" },
    { label: "البيان", key: "notes" },
    { label: "مدين", key: "dept" },
    { label: "دائن", key: "credit" },
    { label: "مركز التكلفة", key: "Namecostcenter" },
  ];

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          {"تقرير اليومية العامة"}
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: "#d3d3d3",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <DailyJenralForms
            {...{
                rptParams,
                onChangeData,
                onChangeAutoData,
              AutoOptions,
              docTypes,
              resSubType
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Hidden smUp={["sm"]}>
              <Grid item>
                <Button
                  disabled={fetching}
                  variant="contained"
                  startIcon={<ScannerIcon />}
                  size="large"
                  style={
                    (inshst.sal.iconBtnAcc,
                        { borderRadius: 0 })
                }
                className={[clasui.buttonAcc]}
                  onClick={() => viewHandle()}
                >
                  {t("forms:show")}
                </Button>
              </Grid>
            </Hidden>
            <Hidden only={["xs"]}>
              <Grid item>
                <Button
                  disabled={fetching}
                  variant="contained"
                  startIcon={<ScannerIcon />}
                  size="large"
                  style={
                    (inshst.sal.iconBtnAcc,
                        { borderRadius: 0 })
                }
                className={[clasui.buttonAcc]}
                  onClick={() => viewHandle()}
                >
                  {t("forms:view")}
                </Button>
              </Grid>
            </Hidden>

            <Hidden smUp={["sm"]}>
              <Grid item >
                {data && !fetching && data.dailyData && (
                  <CSVLink
                    separator={";"}
                    filename={excel_name}
                    data={data.dailyData}
                    headers={excelHeaders}
                  >
                    {" "}
                    <Button
                      disabled={fetching}
                      variant="contained"
                      startIcon={<TableChart />}
                      size="large"
                      color="primary"
                      style={{ borderRadius: 0 }}
                      className={[clasui.buttonAcc]}
                    >
                      {"اكسل"}
                    </Button>
                  </CSVLink>
                )}
              </Grid>
            </Hidden>
            <Hidden only={["xs"]}>
              <Grid item >
                {data && !fetching && data.dailyData && (
                  <CSVLink
                    separator={";"}
                    filename={excel_name}
                    data={data.dailyData}
                    headers={excelHeaders}
                  >
                    {" "}
                    <Button
                      disabled={fetching}
                      variant="contained"
                      startIcon={<TableChart />}
                      size="large"
                      color="primary"
                      style={{ borderRadius: 0 }}
                      className={[clasui.buttonAcc]}
                    >
                      {"تصدير اكسل"}{" "}
                    </Button>
                  </CSVLink>
                )}
              </Grid>
            </Hidden>
            {/* <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.buttonAcc]}
                style={
                  ( inshst.sal.iconBtnAcc,
                    { borderRadius: 0 })
                }
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={
                  ( inshst.sal.iconBtnAcc,
                    { borderRadius: 0 })
                }
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className:  classes.accountCard ,
                  title: " عدد السجلات",
                  value: data?.totals?.qtyTotal,
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                    className:  classes.accountCard ,
                    title:"إجمالي (المدين)",
                  value: data?.totals?.minusTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                    className:  classes.accountCard ,
                  title: "إجمالي ( الدائن )",
                  value: data?.totals?.costTotal.toFixed(2),
                }}
              />
            </Grid>

          </Grid>
        </Container>
        {/* <br /> */}
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color="#808080" />
              {!fetching && (
                <DailyJenralTable
                  {...{
                    data: data?.dailyData ? data.dailyData : [],
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
