import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
} from "@material-ui/core";
export const useStylesAcc = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  heading2: {
    // fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    // fontWeight: "bold",
    fontSize:"1.2rem",
    color:"#000",
    paddingTop:"0.2rem"

  },
  subTitle: {
    direction: "rtl",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  divider: {
    margin: theme.spacing(1, 4, 2, 4),
  },
}));

export default function CheckboxItem(props) {
  const { onChange, name, label, value } = props;
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            fullWidth
            checked={value}
            onChange={(e) => {
              onChange(null, {
                name: name,
                value: e.target.checked,
              });
            }}
            name={name}
            
          />
        }
        size="small"
        color="primary"
        fontSize="small"
        label={label}
      />
    </FormGroup>
  );
}
