import React, { useState, useEffect } from "react";
import {
    Button,
    Chip,
    colors,
    Container,
    Grid,
    Hidden,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
    useStylesHed,
    useStylesSpDial,
    useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import LockIcon from "@material-ui/icons/Lock";

import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import toast from "react-hot-toast";

import { sto } from "../../../../../Context/InshData";
import ShiftsTables from "./ShiftsTables";
import ShiftsReportTable from "./ShiftsReportTable";
import ShiftsReportForm from "./ShiftsReportForm";


const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
    },
    itemW: {
        // display:'flex',
        width: "90%",
    },
    headerTitle: {
        backgroundColor: colors.green[50],
        textAlign: "center",
        fontSize: 20,
        width: "100%",
    },

    allWidth: {
        minWidth: 650,
    },
    checStyl: {
        marginTop: "17px",
        fontSize: "16px",
        padding: "-1px",
    },
    purchTotalCard: {
        backgroundColor: colors.blue[50],
        borderColor: colors.blue[300],
    },
}));

export default function ShiftsReport(props) {
    const { AutoOptions, isPurches } = props;

    const shiftOptions = [
        { id: 0, name: "مفتوحة" },
        { id: 1, name: "مغلقة" },
    ];
    const SearchCloseReports = {
        transFrom: {},
        transTo: {},
        dateFrom: moment(new Date()).format('YYYY-MM-DD'),
        timeFrom: moment(new Date()).format('HH:mm'),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
        timeTo: moment(new Date()).format('HH:mm'),
        trans_Close: shiftOptions[0],
        user: AutoOptions.Users[0],
        branch: sto.branchi,
    };
    const [rptParams, setRptParams] = React.useState(SearchCloseReports);
    const [optionsShifts, setOptionsShifts] = useState(AutoOptions.Shifts);
    const [allowShifts, setAllowShifts] = useState(true);
    const [showButtons, setShowButtons] = useState(false);




    const classes = useStyles();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    const [data, setData] = useState([]);
    const [dataShifts, setDataShifts] = useState([]);
    const [shiftsRow, setShiftsRow] = useState({});

    const [fetching, setFetching] = useState(false);
    const [fetchingS, setFetchingS] = useState(false);

    const [sum, setSum] = useState(false);
    const { t } = useTranslation(["forms", "translation"]);

    const viewHandle = async () => {
        // if (rptParams.transFrom.trans_no == "" || rptParams.transTo.trans_no == "" ||
        //     rptParams.transTo.trans_no == null || rptParams.transFrom.trans_no == null) {

        //     alert("برجاء  تحديد رقم الوردية");
        // } else {
            setDataShifts([]);
            setFetchingS(true);
            await axios
                .get(
                    uri +
                    `RptSales/getShifts?dateF=${rptParams.dateFrom + ' ' + rptParams.timeFrom}
        &&dateT=${rptParams.dateTo + ' ' + rptParams.timeTo}
               &&branch=${rptParams?.branch}
    &&user=${rptParams.user?.id}
    &&trans_Close=${rptParams.trans_Close?.id}
    &&transFrom=${rptParams.transFrom?.trans_no}
    &&transTo=${rptParams.transTo?.trans_no}`
                )
                .then((res) => {
                    setDataShifts(res?.data);
                    // console.log(" the data is", res.data);
                    setFetchingS(false);
                })
                .catch((err) => {
                    // console.log(err);
                    // toast.err("يوجد خطأ في الجلب");
                    setFetchingS(false);
                });
       // }
    };

    const viewSearch = async (rowData) => {


        setData([]);
        setFetching(true);
        setShowButtons(false);
        setShiftsRow(rowData);
        await axios
            .get(
                uri +
                `RptSales/ShiftsReport?dateF=${rowData?.minDate}
        &&dateT=${rowData?.maxDate}
    &&branch=${rowData?.branchId}
    &&user=${rowData.empId}
    &&trans_Close=${rowData.transClose}
    &&trans_no=${rowData.transNo}`
            )
            .then((res) => {
                setData(res.data);
                console.log(" the data is", res.data);
                setFetching(false);
                setShowButtons(true);
            })
            .catch((err) => {
                setFetching(false);
                console.log(err);
                // toast.err("يوجد خطأ في الجلب");
                setData([]);
                setShowButtons(false);
            });

    };
    const his = useHistory();

    const shitCloseEnd = (rowdata) => {
        var otherData = dataShifts?.shiftsAll.filter((obj) => obj.transNo !== rowdata.transNo);
        setDataShifts([...otherData]);
        setShowButtons(false);
    }

    const printHandleA4 = (dataS, dataT) => {
        if (data.lenght == 0) {
            alert("لاتوجد بيانات");
        } else {
            setData([]);
            shitCloseEnd(shiftsRow);
            setShiftsRow({});
            const info = {
                data: dataT?.shiftsData,
                tot: dataT?.totals,
                from: 'الحركة اليومي',
                title: ' تقرير ملخص  اقفال وردية الموظف ',
                user: rptParams.user.name,
                fDate: dataS.minDate,
                tDate: dataS.maxDate,
                user: rptParams.user.name,
                trans_no: dataS.transNo,
                nDate: moment(new Date()).format('YYYY-MM-DD'),
                // totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
            }
            localStorage.setItem("iInfos", JSON.stringify(info));
            his.push({ pathname: "/ReviewShifts" });
            window.location.reload(true);
        }
    };

    const printHandleCashier = (dataS, dataT) => {

        if (data.lenght == 0) {
            alert("لاتوجد بيانات");
        } else {
            setData([]);
            setShiftsRow({});
            const info = {
                // rout:rpt.parms,
                data: dataT?.shiftsData,
                tot: dataT?.totals,
                from: 'الحركة اليومي',
                title: ' تقرير اقفال وردية الموظف ',

                stock: "",
                safe: "",
                user: rptParams.user.name,
                fDate: dataS.minDate,
                tDate: dataS.maxDate,
                transNo: dataS.transNo,
                nDate: moment(new Date()).format('YYYY-MM-DD'),
                // totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
            }
            localStorage.setItem("iInfos", JSON.stringify(info));
            his.push({ pathname: "/CahierShifts" });
            window.location.reload(true);
        }
    };
    const loadOptinsShift = async (user_id, trnclose,fromData,todate) => {
        setOptionsShifts([]);
        await axios
            .get(
                uri +
                `RptSales/shiftOptions?dateF=${fromData + ' ' + rptParams.timeFrom}
        &&dateT=${todate + ' ' + rptParams.timeTo}
        &&branch=${rptParams?.branch}
        &&user=${user_id}
        &&trans_Close=${trnclose}`
            )
            .then((res) => {
                setOptionsShifts(res.data?.shiftOptions);
                setAllowShifts(false);
            })
            .catch((err) => {
                console.log(err);
                // toast.err("يوجد خطأ في الجلب");
            });
    };

    const CloseShift = (props, dataR, dataST) => {

        const ShiftsClose = {
            sales_emp: shiftsRow.empId,
            branch: shiftsRow.branchId,
            trans_no: shiftsRow.transNo,
        };
        if (shiftsRow.transClose == 1) {
            alert("الوردية قد تم اغلاقها مسبقا")
        } else {
            console.log("the curre data is ", ShiftsClose);
            axios
                .put(uri + "Shifts", ShiftsClose)
                .then((res) => {
                    if (res.data?.data == 1) {
                        toast.success("تم الاقفال بنجاح");
                        setData([]);
                        shitCloseEnd(shiftsRow);
                        setShiftsRow({});
                        if (props === "close") {

                        } else if (props === "small") {
                            printHandleCashier(dataR, dataST);

                        }
                        else if (props === "big") {
                            printHandleA4(dataR, dataST);

                        }
                    } else {
                        toast.success("تم الاقفال بنجاح");
                        setData([]);
                        shitCloseEnd(shiftsRow);
                        setShiftsRow({});
                        if (props === "close") {

                        } else if (props === "small") {
                            printHandleCashier(dataR, dataST);

                        }
                        else if (props === "big") {
                            printHandleA4(dataR, dataST);

                        }
                    }
                })
                .catch((res) => {
                    //  alert("تم إضافة الصنف هذا مسبقا");
                    // ButtonSuccess("save", 1000);
                    toast.success("لم يتم الاقفال بنجاح");

                })


        }

    };


    const changTFieldFrom = (prop) => (e) => {
        setRptParams({ ...rptParams, [prop]: e.target.value });
        loadOptinsShift(rptParams.user?.id, rptParams.trans_Close?.id ,e.target.value,rptParams?.dateTo);
    };

    const changTFieldTo = (prop) => (e) => {
        setRptParams({ ...rptParams, [prop]: e.target.value });
        loadOptinsShift(rptParams.user?.id, rptParams.trans_Close?.id ,rptParams.dateFrom,e.target.value);
    };
    const onChangeData = (propName) => (event) => {
        setRptParams({ ...rptParams, [propName]: event.target.value });
    };
    const onChangeAutoData = (propName, newValue) => {
        setRptParams({ ...rptParams, [propName]: newValue });
    };

    const onChangeAutoUsers = (propName, newValue) => {
        setRptParams({ ...rptParams, [propName]: newValue });

        loadOptinsShift(newValue.id, rptParams.trans_Close?.id ,rptParams?.dateFrom,rptParams?.dateTo);
    };
    const onChangeAutoTransC = (propName, newValue) => {
        setRptParams({ ...rptParams, [propName]: newValue });

        loadOptinsShift(newValue.id, rptParams.trans_Close?.id ,rptParams?.dateFrom,rptParams?.dateTo);
    };
    return (
        <div className={classes.root}>
            <Grid container item xs={12}>
                <Typography
                    size="small"
                    className={classes.headerTitle}
                    color="primary"
                >
                    {" تقرير  اقفال الوردية"}
                </Typography>
            </Grid>
            <Typography
                component="div"
                style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
            >
                <Grid container item lg={12} md={12} sm={12} xm={12} className={clasui.rootchip}>

                    <Grid container item lg={6} md={6} sm={6} xm={12} className={clasui.rootchip}>

                        <Container
                            component={Paper}
                            maxWidth={false}
                            style={{
                                padding: "10px",
                                paddingBottom: "0px",
                                borderBottomColor: colors.green[300],
                                borderBottomWidth: "1px",
                                borderBottomStyle: "solid",
                            }}
                        >
                            <ShiftsReportForm
                                {...{
                                    AutoOptions,
                                    rptParams,
                                    onChangeData,
                                    onChangeAutoData,
                                    optionsShifts,
                                    allowShifts,
                                    onChangeAutoUsers,
                                    shiftOptions,
                                    onChangeAutoTransC,
                                    changTFieldFrom,
                                    changTFieldTo,

                                }}
                            />
                            <Grid item container justifyContent="center" xs={12}>
                                <BeatLoader loading={fetchingS} color={colors.green[300]} />
                                {!fetchingS && (
                                    <ShiftsTables
                                        {...{
                                            data: dataShifts?.shiftsAll ? dataShifts?.shiftsAll : [],
                                            viewSearch, CloseShift, showButtons
                                        }}
                                    />
                                )}

                            </Grid>
                            <Grid
                                item
                                container

                                justifyContent="center"
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                spacing={1}
                            >
                                <Hidden smUp={["sm"]}>

                                    <Grid item>
                                        <Button
                                            disabled={fetching}
                                            variant="contained"
                                            startIcon={<ScannerIcon />}
                                            size="large"
                                            style={
                                                (inshst.sal.iconBtn,
                                                    { borderRadius: 0 })
                                            }
                                            className={[clasui.button]}
                                            onClick={() => viewHandle()}
                                        >
                                            {t("forms:show")}
                                        </Button>
                                    </Grid>
                                </Hidden>
                                <Hidden only={["xs"]}>

                                    <Grid item>
                                        <Button
                                            disabled={fetchingS}
                                            variant="contained"
                                            startIcon={<ScannerIcon />}
                                            size="large"
                                            style={
                                                (inshst.sal.iconBtn,
                                                    { borderRadius: 0 })
                                            }
                                            className={[clasui.button]}
                                            onClick={() => viewHandle()}
                                        >
                                            {t("forms:view")}
                                        </Button>
                                    </Grid>
                                </Hidden>

                                {showButtons && shiftsRow.transClose == 0 && (
                                    <Grid item>
                                        <Button
                                            disabled={fetching}
                                            variant="contained"
                                            startIcon={<LockIcon />}
                                            color="primary"
                                            size="large"
                                            className={[clasui.button]}
                                            style={
                                                (inshst.sal.iconBtn,
                                                    { borderRadius: 0 })
                                            }
                                            onClick={() => CloseShift("close", shiftsRow, data)}

                                        >
                                            {"اقفال"}
                                        </Button>
                                    </Grid>
                                )}
                                {showButtons && shiftsRow.transClose == 0 && (
                                    <Grid item>
                                        <Button
                                            disabled={fetching}
                                            variant="contained"
                                            startIcon={<PrintIcon />}
                                            color="primary"
                                            size="large"
                                            className={[clasui.button]}
                                            style={
                                                (inshst.sal.iconBtn,
                                                    { borderRadius: 0 })
                                            }
                                            onClick={() => CloseShift("big", shiftsRow, data)}
                                        >
                                            {"معاينة كبير واقفال"}
                                        </Button>
                                    </Grid>
                                )}
                                {showButtons && shiftsRow.transClose == 0 && (
                                    <Grid item>
                                        <Button
                                            disabled={fetching}
                                            variant="contained"
                                            startIcon={<PrintIcon />}
                                            color="primary"
                                            size="large"
                                            className={[clasui.button]}
                                            style={
                                                (inshst.sal.iconBtn,
                                                    { borderRadius: 0 })
                                            }
                                            onClick={() => CloseShift("small", shiftsRow, data)}
                                        >
                                            {"معاينة كاشير واقفال"}
                                        </Button>
                                    </Grid>
                                )}

                                {showButtons && shiftsRow.transClose == 1 && (
                                    <Grid item>
                                        <Button
                                            disabled={fetching}
                                            variant="contained"
                                            startIcon={<PrintIcon />}
                                            color="primary"
                                            size="large"
                                            className={[clasui.button]}
                                            style={
                                                (inshst.sal.iconBtn,
                                                    { borderRadius: 0 })
                                            }
                                            onClick={() => printHandleCashier(shiftsRow, data)}
                                        >
                                            {"معاينة كاشير"}
                                        </Button>
                                    </Grid>
                                )}
                                {showButtons && shiftsRow.transClose == 1 && (
                                    <Grid item>
                                        <Button
                                            disabled={fetching}
                                            variant="contained"
                                            startIcon={<PrintIcon />}
                                            color="primary"
                                            size="large"
                                            className={[clasui.button]}
                                            style={
                                                (inshst.sal.iconBtn,
                                                    { borderRadius: 0 })
                                            }
                                            onClick={() => printHandleA4(shiftsRow, data)}
                                        >
                                            {"معاينة كبير"}
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        startIcon={<KeyboardReturnSharpIcon />}
                                        color="secondary"
                                        size="large"
                                        className={[clasui.button, clasSp.clcan]}
                                        style={
                                            (inshst.sal.iconBtn,
                                                { borderRadius: 0 })
                                        }
                                        onClick={() => his.goBack()}
                                    >
                                        {t("forms:exit")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container maxWidth={true} style={{ padding: "4px" }}>
                            <Grid container spacing={1} style={{ paddingTop: "10px" }}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <TotalCard
                                        {...{
                                            className: null,
                                            title: "صافي الكاش",
                                            value: data?.totals?.totRsedStok.toFixed(2),

                                        }}
                                    />
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <TotalCard
                                        {...{
                                            className: null,
                                            title: "صافي الشبكة",
                                            value: data?.totals?.totRsedNetW.toFixed(2),

                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <TotalCard
                                        {...{
                                            className: null,
                                            title: "صافي البنك",
                                            value: data?.totals?.totBanks.toFixed(2),

                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>

                                    <TotalCard
                                        {...{
                                            className: null,
                                            title: "صافي الآجل",
                                            value: data?.totals?.totAgels.toFixed(2),

                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                        <br />
                    </Grid>
                    <Grid container lg={6} md={6} sm={6} xm={12} className={clasui.rootchip}>
                        <Container
                            component={Paper}
                            maxWidth={false}
                            style={{
                                padding: "10px",
                            }}
                        >
                            <Grid container justifyContent="center">
                                <Grid item container justifyContent="center" xs={12}>
                                    <BeatLoader loading={fetching} color={colors.green[300]} />
                                    {!fetching && (
                                        <ShiftsReportTable
                                            {...{
                                                data: data?.shiftsData ? data.shiftsData : [],
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
            </Typography>
        </div>
    );
}
