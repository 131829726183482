import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
  useStylesUp, useStylesHed, useStylesAuto,useStylesAutoPurch,useStylesTF, useStylesTD, useStylesBA, useStylesLbl
  , useStylesUiElemment,
  useStylesSpDial,
} from '../../../../St/Reports/UseStyle';


export default function DailyJenralForms(props){

  const {
    rptParams,
   onChangeData,
   onChangeAutoData,
 AutoOptions,
 docTypes,
 resSubType
  }=props;

  const clasup = useStylesUp();
  const calsPurch = useStylesAutoPurch();
  const claslbl = useStylesLbl();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
    return (
          <React.Fragment>
              <Grid container style={{padding:5 }} spacing={1}> 
                <Grid item lg={2} md={2}  sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem",marginTop:"0.3rem"}}>
                    <TextField  fullWidth variant="outlined" size="small" id="datef" 
                    label="من تاريخ"
                    className={clasTF.rootCust} 
                    InputProps={{ className:claslbl.clrBlackTxt }}
                    InputLabelProps={{className:claslbl.clrBlackLblS}}
                    type="date" format="YYYY-MM-DD"
                    name="dateFrom" value={rptParams.dateFrom} 
                    onChange={onChangeData("dateFrom")}/>
                </Grid>
                <Grid item lg={2} md={2}  sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem",marginTop:"0.3rem"}}>
                    <TextField  fullWidth variant="outlined" size="small" id="datef" 
                    label="الى تاريخ"
                    className={clasTF.rootCust} 
                    InputProps={{ className:claslbl.clrBlackTxt }}
                    InputLabelProps={{className:claslbl.clrBlackLblS}}
                    type="date" format="YYYY-MM-DD"
                    name="dateTo" value={rptParams.dateTo} 
                    onChange={onChangeData("dateTo")}/>
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="رقم القيد"
                  type="number" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlackTxt }}
                  InputLabelProps={{className:claslbl.clrBlackLblS}}
                  name="res_id" value={rptParams.res_id} 
                  onChange={onChangeData("res_id")}/>
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}> 
                    <Autocomplete value={rptParams.type}
                      onChange={(event, newValue) => { 
                         onChangeAutoData("type", newValue)  
                      }}
                      id="combo-box-demo" size="small"
                      options={docTypes} 
                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                      style={{color:'black',fontWeight:"bold",fontSize:"1rem"}}
                      disableClearable
                      renderInput={(params) => <TextField {...params} 
                       InputLabelProps={{shrink: true,className:claslbl.clrBlackLblS}} label="نوع المستند" variant="outlined" />}   
                    />
                </Grid>
                {rptParams.type?.id !==-1 && (rptParams.type?.id ==10 || rptParams.type?.id ==11 )?(
                      <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                      <TextField fullWidth variant="outlined" size="small" id="dateT" 
                      label="رقم المستند"
                      type="number" 
                      className={clasTF.rootCust} 
                      InputProps={{ className:claslbl.clrBlackTxt }}
                      InputLabelProps={{className:claslbl.clrBlackLblS}}
                      name="doc_id2" value={rptParams.res_id} 
                      onChange={onChangeData("doc_id2")}/>
                    </Grid>
                   
                ):rptParams.type?.id !=-1 && rptParams.type?.id !=10 && rptParams.type?.id !=11  ?(
                    <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                    <TextField fullWidth variant="outlined" size="small" id="dateT" 
                    label="رقم المستند"
                    type="number" 
                    className={clasTF.rootCust} 
                    InputProps={{ className:claslbl.clrBlackTxt }}
                    InputLabelProps={{className:claslbl.clrBlackLblS}}
                    name="doc_id" value={rptParams.doc_id} 
                    onChange={onChangeData("doc_id")}/>
                  </Grid>
                 
                ):null }
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={clasTF}
                        value={rptParams.branch}
                        onChange={(event, newValue) => {
                            onChangeAutoData("branch", newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={AutoOptions.Branches}
                        disableClearable={true}
                        style={{color:'black',fontWeight:"bold",fontSize:"1rem"}}
                        InputProps={{ className:claslbl.clrBlackTxt }}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                          }
                        renderInput={(params) => (
                            <TextField
                            id="user"
                                {...params}
                                label={"القؤع"}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className:claslbl.clrBlackLblS}}
                            />
                        )}
                    />

                </Grid>
      
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}> 
                    <Autocomplete value={rptParams.resSub}
                      onChange={(event, newValue) => { 
                         onChangeAutoData("resSub", newValue)  
                      }}
                      id="combo-box-demo" size="small"
                      options={resSubType} 
                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                      style={{color:'black',fontWeight:"bold",fontSize:"1rem"}}
                      disableClearable
                      renderInput={(params) => <TextField {...params} 
                       InputLabelProps={{shrink: true,className:claslbl.clrBlackLblS}} label="نوع العرض" variant="outlined" />}   
                    />
                </Grid>
 
            </Grid>  

          </React.Fragment>
          
    );
 
}