import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
    colors,
    Container,
    CssBaseline,
    Hidden,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    useStylesAutoPurch
    , useStylesUiElemment,
    useStylesSpDial,
    useStylesLbl,
    useStylesAutoSafe,
} from '../../../../St/Reports/UseStyle';

import CircularProgress from "@material-ui/core/CircularProgress";
import {
    useStylesUp,
    useStylesHed,
    useStylesAuto,
    useStylesTF,
    useStylesTD,
    useStylesBA,
} from "../../../../St/comps/UseStyle";
import { sto } from "../../../../Context/InshData";
const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
    },
    itemW: {
        // display:'flex',
        width: "90%",
    },
    userTitle: {
        textAlign: "right",
        fontSize: 16,
        width: "100%",
        fontWeight: "bold",
        paddingTop: "0.6rem"

    },
    headerTitle: {
        textAlign: "center",
        fontSize: 20,
        width: "100%",
        fontWeight: "bold"
    },
    headerTitle2: {
        backgroundColor: colors.green[50],
        textAlign: "center",
        fontSize: 20,
        width: "100%",
        direction: "rtl"
    },
    headerTitleP: {
        backgroundColor: colors.blue[50],
        textAlign: "center",
        fontSize: 20,
        width: "100%",
        direction: "rtl"
    },
    allWidth: {
        minWidth: 650,
    },
    checStyl: {
        marginTop: "17px",
        fontSize: "16px",
        padding: "-1px",
    },
    purchTotalCard: {
        backgroundColor: colors.blue[50],
        borderColor: colors.blue[300],
    },
}));

export default function InvoiceFooter(props) {
    const {
        isPurches,
        setting,
        idata,
        valueType,
        uiElement,
        changIdata,
        isStock,
        permistionInv,
        changinvDisc,
        invDisc,
        changClick,
        payment,
        changPayment,
        sums,
        currentUserName,
    } = props;

    const { t } = useTranslation(["forms"]);

    const clasup = useStylesUp();
    const calsPurch = useStylesAutoPurch();
    const claslbls = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();


    const clashd = useStylesHed();
    const clasSaf = useStylesAutoSafe();
    const clasBA = useStylesBA();
    const clasTD = useStylesTD();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <Typography
                component="div"
                style={{ direction: "rtl" }}
            >
                <CssBaseline />
                <Container
                    component={Paper}
                    maxWidth={false}
                    style={{
                        padding: "10px",
                        paddingBottom: "0px",
                        borderBottomColor: isPurches ? "#008080" : isStock ? "#3f51b5" : colors.green[300],
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                    }}
                >
                    <React.Fragment>
                        <Grid container style={{ padding: 5 }} spacing={1} >


                            <Grid item lg={3} md={3} sm={4} xs={6} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small"
                                    id="refno"
                                    label={t("note")}
                                    className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS : isStock ? claslbls.clrBlackLblS : claslbls.clrSale }}
                                    inputProps={{ borderColor: "black", className: claslbls.clrBlackLblS }}
                                    type="text"
                                    name="notes"
                                    value={idata.notes}
                                    onChange={changIdata("notes")}
                                />
                            </Grid>
                            <Grid item lg={2} md={2} sm={3} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small" id="stay"
                                    label={t("remaining")}
                                    className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                                    InputLabelProps={{
                                        className: isPurches ? claslbls.clrBlackLblS : isStock ? claslbls.clrBlackLblS :
                                            claslbls.clrBlackLblSal, readOnly: true
                                    }}
                                    inputProps={{ className: claslbls.clrBlackLblS, readOnly: true }}
                                    type="text"
                                    name="remaining"
                                    value={payment.stay}
                                    placeholder="0"
                                    onChange={changIdata("stay")}
                                /></Grid>
                            <Grid item lg={2} md={2} sm={3} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small" id="paidup"
                                    label={t("paid")}
                                    className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS : isStock ? claslbls.clrBlackLblS : claslbls.clrBlackLblSal }}
                                    inputProps={{ className: claslbls.clrBlackLblS, readOnly: valueType.id == 1 ? false : true }}
                                    type="text"
                                    name="paidup"
                                    value={payment.paidup}
                                    placeholder="0"
                                    onChange={changPayment("paidup")
                                    }
                                /></Grid>
                            <Grid item lg={1} md={1} sm={2} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small" id="val1"
                                    className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS : isStock ? claslbls.clrBlackLblS : claslbls.clrBlackLblSal }}
                                    inputProps={{ className: claslbls.clrBlackLblS, readOnly: true }}
                                    type="text"
                                    label={t("total-quantity")}
                                    name="val1"
                                    value={sums.val1}
                                /></Grid>
                            {setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12" ? (

                                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                                    <TextField fullWidth variant="outlined" size="small" id="tot_sale"
                                        className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                                        InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS : isStock ? claslbls.clrBlackLblS : claslbls.clrBlackLblSal }}
                                        inputProps={{ className: claslbls.clrBlackLblS, readOnly: true }}
                                        type="text"
                                        label={setting.priceTotNM}
                                        name="tot_sale"
                                        value={parseFloat(idata.tot_sale).toFixed(2)}
                                        placeholder="0"
                                    /></Grid>) : (
                                <Grid item lg={2} md={2} sm={3} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                    <TextField fullWidth variant="outlined" size="small"
                                        className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                                        InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS : isStock ? claslbls.clrBlackLblS : claslbls.clrBlackLblSal }}
                                        inputProps={{ className: claslbls.clrBlackLblS, readOnly: true }}
                                        type="text"
                                        id="tot_purch"
                                        label={setting.priceTotNM}
                                        name="tot_purch"
                                        value={parseFloat(idata.tot_purch).toFixed(2)}
                                        placeholder="0"
                                    /></Grid>
                            )}
                            <Grid item lg={2} md={2} sm={3} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small"
                                    className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS : isStock ? claslbls.clrBlackLblS : claslbls.clrBlackLblSal }}
                                    inputProps={{ className: claslbls.clrBlackLblS, readOnly: true }}
                                    type="text"
                                    id="amountWithVat"
                                    label={t("total-after-descount")}
                                    name="amountWithVat"
                                    value={idata.amountWithVat}
                                    placeholder="0"
                                /></Grid>


                            <Hidden only={["xs"]}>
                                <Grid item lg={2} md={2} sm={2} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                    <Typography
                                        size="small"

                                        className={classes.userTitle}
                                        color="primary"
                                    >
                                        {"المستخدم :"}{"  "}{sto.usernames}
                                    </Typography>
                                </Grid>
                            </Hidden>
                            <Grid item lg={2} md={2} sm={3} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small"
                                    className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS : isStock ? claslbls.clrBlackLblS : claslbls.clrBlackLblSal }}
                                    inputProps={{ className: claslbls.clrBlackLblS, readOnly: true }}
                                    type="text"
                                    id="amountAfterDisc"
                                    label={t("total-before-tax")}
                                    name="amountAfterDisc"
                                    value={idata.amountAfterDisc}
                                    placeholder="0"
                                /></Grid>
                            <Grid item lg={2} md={2} sm={3} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small"
                                    className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS : isStock ? claslbls.clrBlackLblS : claslbls.clrBlackLblSal }}
                                    inputProps={{ className: claslbls.clrBlackLblS, readOnly: true }}
                                    type="text"
                                    id="amountOfVat"
                                    label={t("total-value-add")}
                                    name="amountOfVat"
                                    value={parseFloat(idata.amountOfVat).toFixed(2)}
                                    placeholder="0"
                                /></Grid>

                            <Grid item lg={2} md={2} sm={3} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small"
                                    className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS : isStock ? claslbls.clrBlackLblS : claslbls.clrBlackLblSal }}
                                    inputProps={{ className: claslbls.clrBlackLblS, readOnly: true }}
                                    type="text"
                                    id="netAmountSub"
                                    label={t("net")}
                                    name="netAmountSub"
                                    value={parseFloat(idata.netAmount).toFixed(2)}
                                    placeholder="0"
                                /></Grid>
                            <Grid item lg={2} md={2} sm={3} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small"
                                    className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS : isStock ? claslbls.clrBlackLblS : claslbls.clrBlackLblSal }}
                                    inputProps={{ className: claslbls.clrBlackLblS, readOnly: uiElement.minus }}
                                    id="minus"
                                    label={t("discount-amount")}
                                    disabled={
                                        (permistionInv.APDC === 2 || permistionInv.APDC === 3) && permistionInv.DSOB == 1
                                            ? false
                                            : true
                                    }
                                    type="text"
                                    onClick={() => changClick("minus", false)}
                                    name="minus"
                                    value={invDisc.minus}
                                    placeholder="0"
                                    onChange={changinvDisc("minus")}
                                /></Grid>
                            <Grid item lg={2} md={2} sm={3} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small"
                                    className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS : isStock ? claslbls.clrBlackLblS : claslbls.clrBlackLblSal }}
                                    inputProps={{ className: claslbls.clrBlackLblS, readOnly: uiElement.rate }}
                                    id="rate"
                                    label={t("discount")}
                                    disabled={
                                        (permistionInv.APDC === 1 || permistionInv.APDC === 3) && permistionInv.DSOB == 1
                                            ? false
                                            : true
                                    }
                                    type="text"
                                    onClick={() => changClick("rate", false)}
                                    name="rate"
                                    value={invDisc.rate}
                                    placeholder="0"
                                    onChange={changinvDisc("rate")}
                                /></Grid>
                            <Hidden only={["xs"]}>
                                <Grid item lg={2} md={2} sm={2} xs={3} style={{ paddingBottom: "0.5rem" }}></Grid>
                            </Hidden>
                        </Grid>
                    </React.Fragment>
                </Container>
            </Typography>
        </div>
    );
}
