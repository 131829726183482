import React, { Fragment, useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
    Button,
    Checkbox,
    Chip,
    colors,
    Container,
    CssBaseline,
    FormControlLabel,
    FormGroup,
    Hidden,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    useStylesAutoPurch
    , useStylesUiElemment,
    useStylesSpDial,
    useStylesLbl,
    useStylesAutoSafe,
} from '../../../../St/Reports/UseStyle';
import AddIcon from "@material-ui/icons/Add";

import CircularProgress from "@material-ui/core/CircularProgress";
import {
    useStylesUp,
    useStylesHed,
    useStylesAuto,
    useStylesTF,
    useStylesTD,
    useStylesBA,
    useStylesFU,
    useStylesCAB,
    SaleCheckbox,
    useStylesDSM,
    useStylesDPM,
    StyledMenuItem,
    StyledMenu,
    useStylesSN,
    SaleSwitch,
} from "../../../../St/comps/UseStyle";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import StopIcon from "@material-ui/icons/Stop";
const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
    },
    itemW: {
        // display:'flex',
        width: "90%",
    },
    headerTitle: {
        // backgroundColor: colors.green[50],
        textAlign: "center",
        fontSize: 20,
        width: "100%",
        fontWeight:"bold"
    },
    headerTitle2: {
        backgroundColor: colors.green[50],
        textAlign: "center",
        fontSize: 20,
        width: "100%",
        direction:"rtl"
    },
    headerTitleP: {
        backgroundColor: colors.blue[50],
        textAlign: "center",
        fontSize: 20,
        width: "100%",
        direction:"rtl"
    },
    allWidth: {
        minWidth: 650,
    },
    checStyl: {
        marginTop: "17px",
        fontSize: "16px",
        padding: "-1px",
    },
    purchTotalCard: {
        backgroundColor: colors.blue[50],
        borderColor: colors.blue[300],
    },
}));

export default function InvoicesHead(props) {
    const {
        AutoOptions,
        isPurches,
        setting,
        changChckedYahm,
        checked,
        handleOpenCustomer,
        changAutoCustomers
        , optionsCustomers,
        changAutoSalesMen,
        valueSalesmen,
        changAutoCostcenter,
        valueCostcenter,
        idata,
        changAutoType,
        valueType,
        valueSafes,
        changAutoSafes,
        changAutoStock,
        valueStock,
        changAutoBank,
        valueBank,
        changSwitch,
        uiElement,
        switchState,
        lbl,
        claslbl,
        gets,
        onChangeSearch,
        handleFocus,
        onChangeData,
        rptParams,
        setShowPaid,
        exsub,
        handClickInvs,
        infoInv,
        changIdata,
        omlaValue,
        showPaid,
        onChangeAutoDataSubCurr,
        clasCAB,
        changInvs,
        loading,
        toWhere,
        buttonClassnameSavetmp,
        changAutoOmla,
        SaleCheckbox,
        valueCustomers,
        permiCuCo,
        itmTble,
        footerInvoices,
        maxShifts,
        isStock,
        handleOpenItems
    } = props;

    const { t } = useTranslation(["forms"]);

    const clasup = useStylesUp();
    const calsPurch = useStylesAutoPurch();
    const claslbls = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();


    const clashd = useStylesHed();
    const clasSaf=useStylesAutoSafe();
    const clasBA = useStylesBA();
    const clasTD = useStylesTD();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    const classes = useStyles();

    const headerTitle = {
        backgroundColor: "premary",
        textAlign: "center",
        fontSize: 20,
        width: "100%",
    }
    return (
        <div className={classes.root}>

            <Typography
                component="div"
                style={{ direction: "rtl" }}
            >

                <Grid item xs={12} container spacing={0} className={isPurches?classes.headerTitleP: classes.headerTitle2}>

                        <Grid item lg={2} md={4} sm={4} xs={3}>
                        <FormControlLabel
                            id="costWithVat"
                            value="costWithVat"
                            name="costWithVat"
                            className={clashd.ckBoxHd} //disabled={true}
                            control={
                                <SaleCheckbox
                                    checked={checked.costWithVat}
                                    onChange={changChckedYahm}
                                    color="primary"
                                />
                            }
                            label={permiCuCo.CWVtxt}
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item lg={8} md={4} sm={4} xs={6}>
                        <Typography
                            size="small"
                            className={classes.headerTitle}
                            color="primary"
                        >
                            {setting.titleHed}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={4} sm={4} xs={3}></Grid>

                </Grid>

                <CssBaseline />
                <Container
                    component={Paper}
                    maxWidth={false}
                    style={{
                        padding: "10px",
                        paddingBottom: "0px",
                        borderBottomColor:isPurches?"#008080":isStock?"#3f51b5" : colors.green[300],
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                    }}
                >
                    <React.Fragment>
                        <Grid container style={{ padding: 5 }} spacing={1} >

                            <Grid
                                item

                                style={{
                                    paddingRight: "10px",
                                    color:isPurches?colors.blue[500]:isStock?colors.blue[500]: colors.green[50],
                                    textAlign: "-webkit-center",
                                }}
                            >
                                <IconButton
                                    color="inherit"
                                    style={{
                                        background:isPurches?"#008080":isStock?"#3f51b5": colors.green[500],
                                        padding: "10px",
                                        color: "#fff",
                                    }}
                                    aria-label="open drawer"
                                    onClick={handleOpenCustomer}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={10} style={{ paddingBottom: "0.5rem" }}>

                                <Autocomplete classes={isPurches ? calsPurch :isStock?clasSaf: clasAo}
                                    value={valueCustomers}
                                    onChange={(event, newValue) => {
                                        changAutoCustomers(newValue);
                                    }}
                                    id="combo-box-demo"
                                    size="small"
                                    options={optionsCustomers}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }

                                    style={{}}
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={setting.cusType==1?"العميل":"المورد"}
                                            variant="outlined"
                                            InputLabelProps={{ className:  isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbls.clrSale}}
                                        />
                                    )}

                                />
                            </Grid>
                            <Grid item lg={1} md={1} sm={2} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small"
                                    id="id"
                                    label={t("invoice-id")}
                                    className={isPurches ? clasTF.rootPurch :isStock? clasTF.rootPurch: clasTF.rootSale}
                                    InputProps={{ className: isPurches ? claslbl.clrPurch :isStock?claslbls.clrBlackLblS: claslbls.clrSale, readOnly: true }}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbls.clrSale }}
                                    type="text"
                                    name="id" value={infoInv.id}
                                />
                            </Grid>
                            <Grid item lg={1} md={1} sm={2} xs={4} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small"
                                    id="refno"
                                    label={t("ref-no")}
                                    className={isPurches ? clasTF.rootPurch :isStock? clasTF.rootPurch: clasTF.rootSale}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbls.clrSale }}
                                    type="text"
                                    name="refno"
                                    value={idata.refno}
                                    onChange={changIdata("refno")}
                                />
                            </Grid>
                            <Grid item lg={1} md={1} sm={3} xs={5} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small" id="dateFrom"
                                    label={t("date")}
                                    className={isPurches ? clasTF.rootPurch :isStock? clasTF.rootPurch: clasTF.rootSale}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbls.clrBlackLblSal }}
                                    type="date" format="YYYY-MM-DD"
                                    name="date"
                                    value={idata.date}
                                    onChange={changIdata("date")}
                                /></Grid>
                            <Grid item lg={1} md={1} sm={3} xs={4} style={{ paddingBottom: "0.5rem" }}>
                                <Autocomplete
                                    classes={isPurches ? calsPurch : isStock?clasSaf:clasAo}
                                    value={valueSalesmen}
                                    onChange={(event, newValue) => {
                                        changAutoSalesMen(newValue);
                                    }}
                                    id="controllable-states-demo"
                                    size="small"
                                    options={gets.salesmen}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            id="sales_man"
                                            {...params}
                                            label={t("delegate")}
                                            variant="outlined"
                                            size="small"
                                            InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbls.clrSale }}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item lg={1} md={1} sm={3} xs={4} style={{ paddingBottom: "0.5rem" }}>
                                <Autocomplete
                                    classes={isPurches ? calsPurch :isStock?clasSaf: clasAo}
                                    value={valueType}
                                    onChange={(event, newValue) => {
                                        changAutoType(newValue, true);
                                    }}
                                    id="controllable-states-demo"
                                    size="small"
                                    options={AutoOptions.types}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            id="typeinvs"
                                            {...params}
                                            label={t("invoice-type")}
                                            variant="outlined"
                                            size="small"
                                            InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbls.clrBlackLblS }}
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item lg={1} md={1} sm={3} xs={4} style={{ paddingBottom: "0.5rem" }}>
                                <Autocomplete
                                    classes={isPurches ? calsPurch :isStock?clasSaf: clasAo}
                                    value={valueCostcenter}
                                    onChange={(event, newValue) => {
                                        changAutoCostcenter(newValue);
                                    }}
                                    id="controllable-states-demo"
                                    size="small"
                                    options={gets.costcenters}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            id="center"
                                            {...params}
                                            label={"مركز التكلفة"}
                                            variant="outlined"
                                            size="small"
                                            InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbl.clrSale }}
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item lg={1} md={1} sm={3} xs={4} style={{ paddingBottom: "0.5rem" }}>
                                <Autocomplete classes={isPurches ? calsPurch :isStock?clasSaf: clasAo}
                                    value={valueSafes}
                                    onChange={(event, newValue) => {
                                        changAutoSafes(newValue);
                                    }}
                                    id="combo-box-demo"
                                    size="small"
                                    options={AutoOptions.Safes}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }

                                    style={{}}
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("safe")}
                                            variant="outlined"
                                            InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbls.clrBlackLblSal }}
                                        />
                                    )}

                                />
                            </Grid>
                            {uiElement.bank == true && (
                                <Grid item lg={1} md={1} sm={3} xs={4} style={{ paddingBottom: "0.5rem" }}>
                                    <Autocomplete
                                        classes={isPurches ? calsPurch :isStock?clasSaf: clasAo}
                                        value={valueStock}
                                        onChange={(event, newValue) => {
                                            changAutoStock(newValue);
                                        }}
                                        id="controllable-states-demo"
                                        size="small"
                                        options={AutoOptions.Stocks}
                                        getOptionLabel={(option) =>
                                            typeof option === "string" ? option : option.name
                                        }
                                        disableClearable
                                        renderInput={(params) => (
                                            <TextField
                                                id="bank"
                                                {...params}
                                                label={t("account")}
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbls.clrBlackLblSal }}
                                            />
                                        )}
                                    />

                                </Grid>
                            )}

                            {uiElement.bank == false && (
                                <Grid item lg={1} md={1} sm={2} xs={4} style={{ paddingBottom: "0.5rem" }}>
                                    <Autocomplete
                                        classes={isPurches ? calsPurch :isStock?clasSaf: clasAo}
                                        value={valueBank}
                                        onChange={(event, newValue) => {
                                            changAutoBank(newValue);
                                        }}
                                        id="controllable-states-demo"
                                        size="small"
                                        options={gets.Banks}
                                        getOptionLabel={(option) =>
                                            typeof option === "string" ? option : option.name
                                        }
                                        disableClearable
                                        renderInput={(params) => (
                                            <TextField
                                                id="bank"
                                                {...params}
                                                label={"الحساب"}
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbls.clrBlackLblSal }}
                                            />
                                        )}
                                    />

                                </Grid>
                            )}
                            <Grid item lg={1} md={1} sm={3} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                <FormControlLabel
                                    control={
                                        <Tooltip title="التبديل بين شبكة وكاش">
                                            <Grid item lg={1} md={1} sm={2} xs={3}>
                                                <SaleSwitch
                                                    size="small"
                                                    disabled={uiElement.payMethod}
                                                    checked={switchState.cashORnetworksw}
                                                    onChange={changSwitch("cashORnetworksw")}
                                                    id="cashORnetworksw"
                                                    value="cashORnetworksw"
                                                    name="cashORnetworksw"
                                                />
                                            </Grid>
                                        </Tooltip>
                                    }
                                    className={claslbl.clorSw}
                                    label={lbl.chashNetwork}
                                    labelPlacement="start"
                                />
                            </Grid>
                            <Hidden only={["xs"]}>
                            <Grid item lg={1} md={1} sm={2} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                <TextField fullWidth variant="outlined" size="small"
                                    id="trans_no"
                                    label={"رقم الوردية"}
                                    className={isPurches ? clasTF.rootPurch :isStock? clasTF.rootPurch: clasTF.rootSale}
                                    InputProps={{ className: isPurches ? claslbl.clrPurch :isStock?claslbls.clrBlackLblS: claslbls.clrSale, readOnly: true }}
                                    InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbls.clrSale }}
                                    type="text"
                                    name="trans_no" value={maxShifts}
                                />
                            </Grid>
                            </Hidden>
                            <Grid item lg={1} md={1} sm={2} xs={4} style={{ paddingBottom: "0.5rem" }}>
                                <Autocomplete classes={isPurches ? calsPurch :isStock?clasSaf: clasAo}
                                    value={omlaValue}
                                    onChange={(event, newValue) => {
                                        changAutoOmla(newValue);
                                    }}
                                    id="combo-box-demo"
                                    size="small"
                                    options={gets.omlats}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }

                                    style={{}}
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("currency")}
                                            variant="outlined"
                                            InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbls.clrBlackLblSal }}
                                        />
                                    )}

                                />
                            </Grid>
                            <Grid item lg={2} md={2} sm={3} xs={8} style={{ paddingBottom: "0.5rem" }}>
                                <TextField
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    id="barcode"
                                    label={t("forms:barcode")}
                                    style={{ backgroundColor:isPurches?colors.blue[50]:isStock?colors.blue[50]: colors.green[50] }}
                                    name="barcode"
                                    autoFocus={true}
                                    value={rptParams.barcode}
                                    InputProps={{
                                        onKeyDown: (event) => {
                                            if (event.key === 'Enter') {
                                                onChangeSearch(event);
                                            }
                                        },
                                    }}
                                    onFocus={handleFocus}

                                    onChange={onChangeData("barcode")} />
                            </Grid>
                            <Grid item lg={2} md={2} sm={3} xs={7} style={{ paddingBottom: "0.5rem" }}>
                                <Autocomplete classes={isPurches ? calsPurch :isStock?clasSaf: clasAo}
                                    value={rptParams.curr}
                                    onChange={(event, newValue) => {
                                        onChangeAutoDataSubCurr("curr", newValue);
                                    }}
                                    id="combo-box-demo"
                                    options={AutoOptions.InvItems}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    disableClearable
                                    style={{ backgroundColor:isPurches? colors.blue[50]:isStock?colors.blue[50]: colors.green[50] }}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={"ابحث عن صنف"}
                                            variant="outlined"
                                            InputLabelProps={{ className: isPurches ? claslbls.clrBlackLblS :isStock?claslbls.clrBlackLblS: claslbls.clrBlackLblSal }}
                                        />
                                    )}

                                />
                            </Grid>

                            <Hidden only={"xs"}>
                                
                            <Grid
                                item

                                style={{
                                    paddingRight: "10px",
                                    color:isPurches?colors.blue[500]:isStock?"#3f51b5": colors.green[50],
                                    textAlign: "-webkit-center",
                                }}
                            >
                                <IconButton
                                    color="inherit"
                                    style={{
                                        background:isPurches?"#008080":isStock?"#3f51b5": colors.green[500],
                                        padding: "10px",
                                        color: "#fff",
                                    }}
                                    aria-label="open drawer"
                                    onClick={handleOpenItems}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                            </Hidden>
                            <Hidden smUp={["sm"]}>
                                {exsub > 0 ? (
                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                        <div className={clasCAB.wrapperCAB}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{
                                                    ...{
                                                        background: "#ff8080",
                                                        color: "#fff",
                                                        direction: "initial",
                                                    },
                                                }}
                                                className={buttonClassnameSavetmp}
                                                disabled={loading.savetmp}
                                                onClick={() => toWhere("savetmp", "e")}
                                                startIcon={<StopIcon />}
                                            >
                                            </Button>
                                            {loading.savetmp && (
                                                <CircularProgress
                                                    size={24}
                                                    className={clasCAB.buttonProgressCAB}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                ) : null}
                                <Grid item lg={1} md={1} sm={2} xs={3} style={{ paddingBottom: "0.5rem" }}>
                                    <FormControlLabel
                                        id="showPaid"
                                        value="showPaid"
                                        name="showPaid"
                                        className={claslbl.clor}
                                        control={
                                            <SaleCheckbox
                                                checked={showPaid}
                                                onChange={(e) => {
                                                    setShowPaid(e.target.checked);
                                                    // changeIsPay(e.target.checked);
                                                }}
                                                color={isPurches?"#008080":isStock?"#3f51b5":"primary"}
                                            />
                                        }
                                        label="الدفع"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Hidden>
                            <Hidden only={["xs"]}>
                                <Grid item lg={1} md={1} sm={2} xs={4} style={{ paddingBottom: "0.5rem" }}>
                                    <FormControlLabel
                                        id="showPaid"
                                        value="showPaid"
                                        name="showPaid"
                                        className={claslbl.clor}
                                        control={
                                            <SaleCheckbox
                                                checked={showPaid}
                                                onChange={(e) => {
                                                    setShowPaid(e.target.checked);
                                                    // changeIsPay(e.target.checked);
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="شاشة الدفع"
                                        labelPlacement="end"
                                    />
                                </Grid>


                                {exsub > 0 ? (
                                    <Grid item lg={2} md={2} sm={2} xs={4}>
                                        <div className={clasCAB.wrapperCAB}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                style={{
                                                    ...{
                                                        background: "#ff8080",
                                                        color: "#fff",
                                                        direction: "initial",
                                                    },
                                                }}
                                                className={buttonClassnameSavetmp}
                                                disabled={loading.savetmp}
                                                onClick={() => toWhere("savetmp", "e")}
                                                startIcon={<StopIcon />}
                                            >
                                                {t("invoice-suspension")}
                                            </Button>
                                            {loading.savetmp && (
                                                <CircularProgress
                                                    size={24}
                                                    className={clasCAB.buttonProgressCAB}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                ) : null}
                            </Hidden>
                            {setting.PrTy_InPr === "11" || setting.PrTy_InPr === "12" ? (
                                <Fragment>
                                    <Grid item lg={2} md={3} sm={4} xs={6}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            id="InvId_Return"
                                            label="رقم المرتجع"
                                            className={clasTF.root}
                                            InputProps={{ className: clasTF.input }}
                                            name="InvId_Return"
                                            value={infoInv.InvId_Return}
                                            onChange={changInvs("InvId_Return")}
                                            placeholder=""
                                        />
                                    </Grid>

                                    <Grid item lg={1} md={1} sm={1} xs={1}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            fontSize="large"
                                            size="medium"
                                            onClick={handClickInvs}
                                        >
                                            تنزيل
                                        </Button>
                                    </Grid>
                                </Fragment>
                            ) : (
                                <Fragment></Fragment>
                            )}

                        </Grid>



                    </React.Fragment>
                    {itmTble()}

                </Container>
                <CssBaseline />
                <div className={classes.root}>

                    <Container
                        component={Paper}
                        maxWidth={false}
                        style={{
                            padding: "10px",
                            paddingBottom: "0px",
                            borderBottomColor: isPurches?"#008080":isStock?"#3f51b5": colors.green[300],
                            borderBottomWidth: "1px",
                            borderBottomStyle: "solid",
                        }}
                    >
                    </Container>
                    {footerInvoices()}

                </div>
            </Typography>

        </div>
    );
}
