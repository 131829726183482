import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../../services/LoadingPage";
import ShiftsReport from "./ShiftsReport";
const defaultAutos = {
    Users: [],
};

export default function ShiftsReportCon() {

  const tb = {
    wait: true,
    User: true,
    Shift:true,
    parms:{
        branch:sto.branchi,
        iempid:sto.iempid,
    }
};

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "GetD",
    tb,
  );

  if (
    fetchAll ||
    !AutoOptions?.Users 

  )
    return <LoadingPage />;

  return (
    <ShiftsReport
      {...{
        AutoOptions: AutoOptions?.Users ? AutoOptions : defaultAutos,
        isPurches:false,
      }}
    />
  );
}
