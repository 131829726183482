import React from "react";
import Typography from "@material-ui/core/Typography";
import Swipeable from "../../../../Fronts/Main/Swipeable";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";
import CheckboxItem, { useStylesAcc } from "../CheckBoxComponent";
const formData = [
  {
    name: "used_Nsale_screen",
    label: "استخدام شاشة المبيعات",
    visible: true,
  },
  {
    name: "used_Rsale_screen",
    label: "استخدام شاشة المرتجع",
    visible: true,
  },
  {
    name: "used_switch_screen",
    label: "استخدام شاشة التبديل",
    visible: false,
  },
  {
    name: "used_Items_screen",
    label: "استخدام شاشة تسجيل الأصناف",
    visible: true,
  },
  {
    name: "used_Group_screen",
    label: "استخدام شاشة المجموعات",
    visible: true,
  },
  {
    name: "used_Bcode_screen",
    label: "إستخدام طباعة الباركود",
    visible: false,
  },
  {
    name: "used_Jarde_screen",
    label: "استخدام جرد الأصناف",
    visible: true,
  },
  {
    name: "used_jrans_screen",
    label: "استخدام جرد حركة الصنف تفصيلي",
    visible: true,
  },
  {
    name: "used_Dialy_screen",
    label: "إستخدام تقرير  الحركة اليومية",
    visible: true,
  },
  {
    // this field for users table
    name: "used_Point_screen",
    label: "تصفير ظهور الإحصائيات في نقطة البيع",
    visible: false,
  },
  {
    name: "used_Treas_screen",
    label: "استخدام تقرير حركة الصندوق",
    visible: true,
  },
  {
    name: "used_Accnt_screen",
    label: "استخدام شاشة كشف الحساب",
    visible: true,
  },
  {
    name: "used_SSarf_screen",
    label: "استخدام شاشة سند الصرف",
    visible: true,
  },
  {
    name: "used_itmove_screen",
    label: "استخدام شاشة تقرير حركة الأصناف",
    visible: true,
  },
  {
    name: "used_Sqabd_screen",
    label: "استخدام شاشة سند القبض",
    visible: true,
  },
  {
    name: "used_purch_screen",
    label: "استخدام شاشة المشتريات",
    visible: true,
  },
  {
    name: "used_Rpurch_screen",
    label: "استخدام شاشة مرتجع المشتريات",
    visible: true,
  },
  {
    name: "notShowSales",
    label: "عدم إظهار المبيعات (إظهار عدد الفواتير)",
    visible: true,
  },
  {
    name: "disable_customer",
    label: "تعطيل التعامل مع اضافة العملاء(عميل نقدي فقط)",
    visible: true,
  },
  {
    name: "pos_dashboard_zero",
    label: "تصفير ظهور الإحصائيات في نقطة البيع",
    visible: true,
  },
  { name: "", label: "السماح بالخصم ", visible: false },
];

export default function POSPermissions(props) {
  const { onChange, object, update, sub } = props;

  const classes = useStylesAcc();

  return (
    <div className={classes.root}>
      <Accordion expanded={true}>
        {/* <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ background: "#d9dcf078" }}
        >
          <Typography className={classes.heading}>
            إعدادات نقاط البيع
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.subTitle}>
        </AccordionDetails> */}

        <AccordionDetails>
          <Grid item container spacing={1}>
            {formData
              ?.filter((d) => d.visible)
              ?.map((item) => {
                // console.log(object);
                return (
                  <Grid xs={6}>
                    <CheckboxItem
                      value={object[item.name] ?? null}
                      name={item.name}
                      label={item.label}
                      onChange={onChange}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </AccordionDetails>

        <Divider className={classes.divider} />

        <AccordionDetails className={classes.subTitle}>
          {" "}
          <Typography>
            {" "}
            <Button
              variant="contained"
              color="secondary"
              size="large"
              disabled={props.submitting}
              onClick={update}
            >
              {"حفظ التعديلات"}
            </Button>
          </Typography>{" "}
        </AccordionDetails>
      </Accordion>
      <Swipeable />
    </div>
  );
}
