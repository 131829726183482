import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial
} from '../../../../../St/Reports/UseStyle';
export default function ShiftsReportForm(props) {
    const {
        AutoOptions,
        rptParams,
        onChangeData,
        onChangeAutoData,
        optionsShifts,
        allowShifts,
        onChangeAutoUsers,
        shiftOptions,
        onChangeAutoTransC,
        changTFieldFrom,
        changTFieldTo,
    } = props;

    const { t } = useTranslation(["forms"]);
    const clasAo = useStylesAuto();

    const clasup = useStylesUp();
    const clashd = useStylesHed();
    const clasBA = useStylesBA();
    const clasTF = useStylesTF();
    const clasTD = useStylesTD();
    const claslbl = useStylesLbl();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    return (
        <React.Fragment>
            <Grid container style={{ padding: 5 }} spacing={1}>

                <Grid item lg={6} md={6} sm={6} xs={6}  style={{paddingBottom:"0.5rem"}}>
                    <TextField fullWidth variant="outlined" size="small" id="dateFrom" label="من تاريخ"
                        className={clasTF.rootSale} InputProps={{ className: clasTD.input }}
                        type="date" format="YYYY-MM-DD"
                        name="dateFrom" value={rptParams.dateFrom} onChange={changTFieldFrom('dateFrom')}
                    /></Grid>
              
                <Grid item lg={6} md={6} sm={6} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <TextField fullWidth variant="outlined" size="small" id="dateTo" label="إلى تاريخ"
                        className={clasTF.rootSale} InputProps={{ className: clasTD.input }}
                        type="date" format="YYYY-MM-DD"
                        name="dateTo" value={rptParams.dateTo} onChange={changTFieldTo('dateTo')}
                    /></Grid>

                
                <Grid item lg={6} md={6} sm={6} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <Autocomplete classes={clasAo}
                        value={rptParams.trans_Close}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoTransC('trans_Close', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={shiftOptions}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="حالة الوردية" variant="outlined" size="small"
                            InputLabelProps={{ className: claslbl.clrSale }} />}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <Autocomplete classes={clasAo}
                        value={rptParams.transFrom}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoData('transFrom', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={optionsShifts}
                        disabled={allowShifts}

                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="من رقم وردية" variant="outlined" size="small"
                            InputLabelProps={{ className: claslbl.clrSale }} />}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <Autocomplete classes={clasAo}
                        value={rptParams.transTo}
                        labelPlacement=""
                        disabled={allowShifts}
                        onChange={(event, newValue) => { onChangeAutoData('transTo', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={optionsShifts}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="الى رقم وردية" variant="outlined" size="small"
                            InputLabelProps={{ className: claslbl.clrSale }} />}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} style={{paddingBottom:"0.5rem"}}>
                    <Autocomplete classes={clasAo}
                        value={rptParams.user}
                        onChange={(event, newValue) => {
                            onChangeAutoUsers("user", newValue);
                        }}
                        id="combo-box-demo"
                        size="small"
                        options={AutoOptions.Users}
                        //getOptionLabel={(option) => option.name}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        style={{}}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                label={t("user")}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    );
}
