import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { Checkbox, colors, Divider, FormControlLabel, FormGroup } from "@material-ui/core";
import {
  useStylesTF,
  useStylesTD,
  useStylesContainers,
  useStylesHed,
  useStylesLbl,
} from "../../../St/comps/UseStyle";
import { payTypes } from "../../../services/global-services";
import { useTranslation } from "react-i18next";

export default function CustomersForm(props) {
  const { object, data, onChange,defaultSalman } = props;
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const clsConts = useStylesContainers();
  const claslbl = useStylesLbl();
  const { t } = useTranslation(["forms", "translation"]);

  const onChangeData = (prop) => (event) => {
    onChange(event, { name: prop, value: event.target.value });
  };
  // const onChangeAutoData = (propName, newValue) => {
  //   setRptParams({ ...rptParams, [propName]: newValue });
  const [valueGroups, setValueGroups] = useState();
  // };

  object.salesmen=defaultSalman;
  return (
    <Grid container lg={12} md={12}>
      {/* <Chip size="large" className={clashd.ChipHdCustomer} label={"بيانات العميل"} /> */}
      <Grid item container spacing={1} xs={12} className={[clsConts.rootup]}>
        <React.Fragment>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"name"}
              label={t("forms:cust-name")}
              className={clasTF.rootSale}
              style={{ backgroundColor: colors.red[50] }}
              InputProps={{ className: clasTF.inputSale }}
              type="text"
              name={"name"}
              value={object.name}
              onChange={onChangeData("name")}
            />
          </Grid>

          <Grid item lg={3} md={3} sm={3} xs={6}>
            <Autocomplete // classes={clasAo}
              value={object.act}
              onChange={(event, newValue) => {
                onChange(null, { name: "act", value: newValue });
              }}
              id="controllable-states-dem"
              size="small"
              options={data?.Acts}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              style={{ backgroundColor: colors.red[50] }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valuePricePay"
                  {...params}
                  label={t("forms:group")}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>

          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"mobile"}
              label={t("forms:mobile-number")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"mobile"}
              value={object.mobile}
              onChange={onChangeData("mobile")}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"tax_no"}
              label={t("forms:tax-number")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTF.inputSale }}
              name={"tax_no"}
              value={object.tax_no}
              onChange={onChangeData("tax_no")}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"acc_no"}
              label={t("forms:account-code")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              disabled={true}
              name={"acc_no"}
              value={object.acc_no}
              onChange={onChangeData("acc_no")}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"IValue"}
              label={t("forms:initial-balance")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="number"
              name={"IValue"}
              value={object.IValue}
              onChange={onChangeData("IValue")}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider style={{ margin: "10px" }} />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"cust_PresValue"}
              label={t("forms:discount")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="number"
              style={{ backgroundColor: colors.grey[50] }}
              name={"cust_PresValue"}
              value={object.cust_PresValue}
              onChange={onChangeData("cust_PresValue")}
            />
          </Grid>

          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"cust_DiscValue"}
              label={t("forms:discount-amount")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input, readOnly: true }}
              type="text"
              style={{ backgroundColor: colors.grey[50] }}
              name={"cust_DiscValue"}
              value={object.cust_DiscValue}
              onChange={onChangeData("cust_DiscValue")}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"maxdepit"}
              label={t("forms:dept-limit")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="number"
              style={{ backgroundColor: colors.grey[50] }}
              name={"maxdepit"}
              value={object.maxdepit}
              onChange={onChangeData("maxdepit")}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider style={{ margin: "10px" }} />
          </Grid>

          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"national_id"}
              label={t("forms:id-number")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"national_id"}
              value={object.national_id}
              onChange={onChangeData("national_id")}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"tel"}
              label={t("forms:phone-number1")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"tel"}
              value={object.tel}
              onChange={onChangeData("tel")}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Autocomplete // classes={clasAo}
              value={object.Cust_inv_type}
              onChange={(event, newValue) => {
                onChange(null, { name: "Cust_inv_type", value: newValue });
              }}
              id="controllable-states-dem"
              size="small"
              options={payTypes}
              style={{ backgroundColor: colors.red[50] }}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              //style={{  }}  // disabled={}
              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valuePricePay"
                  {...params}
                  label={t("forms:pay-type")}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>

          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Autocomplete
              value={object.country}
              onChange={(event, newValue) => {
                onChange(null, { name: "country", value: newValue });
              }}
              // style={{backgroundColor: colors.grey[50]}}
              id="controllable-states-demo"
              size="small"
              options={data?.Countries}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              disableClearable
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t("forms:countery")}
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ className: claslbl.clrSale }}
                />
              )}
            />
          </Grid>

          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Autocomplete
              value={object.city}
              onChange={(event, newValue) => {
                onChange(null, { name: "city", value: newValue });
              }}
              id="controllable-states-demo"
              size="small"
              options={data?.Cities}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              // style={{backgroundColor: colors.grey[50]}}

              disableClearable
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t("forms:city")}
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ className: claslbl.clrSale }}
                />
              )}
            />
          </Grid>

          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Autocomplete
              value={object.area}
              onChange={(event, newValue) => {
                onChange(null, { name: "area", value: newValue });
              }}
              id="controllable-states-demo"
              size="small"
              options={data?.Areas}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              // style={{backgroundColor: colors.grey[50]}}
              disableClearable
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t("forms:area")}
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ className: claslbl.clrSale }}
                />
              )}
            />
          </Grid>
          {props.type == 1 && (
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <Autocomplete // classes={clasAo}
                value={object.salesmen}
                onChange={(event, newValue) => {
                  onChange(null, { name: "salesmen", value: newValue });
                }}
                id="controllable-states-dem"
                size="small"
                options={data?.salesmen ?? []}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                //style={{  }}  // disabled={}
                // disableClearable
                renderInput={(params) => (
                  <TextField
                    id="valuePricePay"
                    {...params}
                    label={t("forms:delegate")}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
          )}
            <Grid item lg={3} md={3} sm={3} xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"desc3"}
              label={t("السجل التجاري")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"desc3"}
              value={object.desc3}
              onChange={onChangeData("desc3")}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              style={{ backgroundColor: colors.grey[50] }}
              id={"address"}
              label={t("forms:address")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"address"}
              value={object.address}
              onChange={onChangeData("address")}
            />
          </Grid>
  
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"notes"}
              label={t("forms:note")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"notes"}
              value={object.notes}
              onChange={onChangeData("notes")}
            />
          </Grid>
          <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={<Checkbox fullWidth checked={object.CustandSup == 3} 
                          onChange={(e)=>{
                            
                            onChange(e, { name: "CustandSup", value: e.target.checked? 3: object.type });
                            // onChangeAutoData("CustandSup", e.target.checked) 
                          }}
                          name="custWithSupp" />}
                          size="small"
                          color="primary"
                          fontSize="small"    
                          label="تسجيل كعميل ومورد"
                      />
                      </FormGroup>
                      
                  </Grid>
        </React.Fragment>
      </Grid>
    </Grid>
  );
}
