import React, {useEffect, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import {useStylesHed} from '../../../St/comps/UseStyle';
import StocksForm from './customers-form';
import  { Toaster } from 'react-hot-toast';
import StocksAppBar from './customers-appBar';
import customerAPI from '../../../api/customer';
import useFormDataCRUD from '../../../components/Hooks/useEDSCURD';
import { defaultCustomer, SyncLocalStorage } from '../../../services/global-services';
import { Paper } from '@material-ui/core';
import { insh as inshst } from '../../../Context/InshSt';
import GroupIcon from '@material-ui/icons/Group';
import { useStyleC } from '../../../Fronts/Menues/st/UseStyle';
import { style } from '../../../Fronts/Menues/st/cStyle';
import { useTranslation } from 'react-i18next';
import CustomersSubForm from './customers-sub-data-form';

export default function Customers(props){

  const {data, type, fetchURL, isDialog,defaultSalman} = props;
  const {t} = useTranslation(['translation', 'forms']);
  const defaultValues = {...defaultCustomer, 
          acc_no: props.newAccount,
          type: type,
          CustandSup: type,
          act:data?.Acts[0],
          salesman:defaultSalman
        };
      const {
        onChange,
        submit,
        update,
        remove,
        submitting, 
        errorMessage,
        onShow,
        onEnd,
        object,
        formType,
        
    } = useFormDataCRUD(
        customerAPI.create,
        customerAPI.update,
        customerAPI.remove,
        undefined,
        defaultValues,
        null,
        async()=>{
          if(isDialog){
            await SyncLocalStorage();
          } else fetchURL();
        },
    );


  const title = type==1? t('translation:customer'):t('translation:customer');
  const title1 = type==1? t('translation:cust-add'):t('translation:sup-add');
    const clashd = useStylesHed();
    const classes = useStyleC();
  const styheader =  
  {textAlign:'center', backgroundColor: "#faf1f1", direction:'rtl' }



return (

<React.Fragment >
    
    <CssBaseline />
    <Toaster />

    <Typography component="div" style={styheader}>
    <Grid item container style={{paddingBottom:"50px"}}> 
        <Grid item xs={12}>
          <Typography variant={'h5'}
                  component='h3'
                  style={{
                    marginBlock:10,
                  }}
                    >
                      {
                        formType == 'edit' ?  (title+ ' '+t('forms:edit-data')) : title1
                      }

          </Typography>
        </Grid>
         <Grid container
         justifyContent="space-around" 
        //  justifyContent="center"
         >
                    <Grid item lg={8} md={8} sm={12}>
                        <Container component={Paper} maxWidth={true}
                            style={{      
                            padding:'15px', 
                            // paddingRight:'0px',
                            // backgroundColor: colors.[50],
                            paddingTop:'0px',
                            borderColor: inshst.colors.cust,
                            borderTopWidth:'0px',
                            borderLeftWidth:'0px',
                            borderBottomWidth: '0px',
                            borderRightWidth:'10px',
                            borderStyle:'solid'}}
                            
                        >
                          <Grid
                                container
                                justify="space-between"
                                >
                                   <Grid item>
                                      <Chip size="large" className={clashd.ChipHdCustomer} label={type==1?t('forms:cust-data'): t('forms:sup-data')} />
                                   </Grid>
                                   <Grid item>
                                      <GroupIcon className={classes.iconCard} style={style.color.customer}/>
                                   </Grid>
                            </Grid>
 
                          <StocksForm {...{...props,
                                  onChange,
                                    submit,
                                    update,
                                    remove,
                                    submitting, 
                                    errorMessage,
                                    onShow,
                                    onEnd,
                                    object,
                                    formType,
                                    }}/>
                                                  
                        </Container>
                    </Grid>
          
                    <Grid item lg={3} md={3} sm={12}>
                        <Container component={Paper} maxWidth={true}
                            style={{      
                            padding:'10px', 
                            paddingTop:'0px',
                            borderColor: inshst.colors.cust,
                            borderTopWidth:'0px',
                            borderLeftWidth:'0px',
                            borderBottomWidth: '0px',
                            borderRightWidth:'0px',
                            borderStyle:'solid'}}
                        >
                          <Grid
                                container
                                justify="center"
                                style={{paddingBottom:'20px'}}
                                >
                                   <Grid item>
                                      <Chip size="large" className={clashd.ChipHdCustomer} label={"بيانات العنوان الوطني"} />
                                   </Grid>                                  
                            </Grid>
 
                          <CustomersSubForm {...{...props,
                                  onChange,
                                    submit,
                                    update,
                                    remove,
                                    submitting, 
                                    errorMessage,
                                    onShow,
                                    onEnd,
                                    object,
                                    formType,
                                    defaultSalman
                                    }}/>
                                                  
                        </Container>
                    </Grid>
        </Grid>
    </Grid>
    <StocksAppBar {...{
      ...props,
      onChange,
      defaultValues,
      submit,
      update,
      remove,
      submitting, 
      errorMessage,
      onShow,
      onEnd,
      object,
      formType,
      }}/>
    </Typography>
</React.Fragment>
 );
 
}