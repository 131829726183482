import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial,
} from '../../../../../St/Reports/UseStyle';
import { get } from "@awesome-cordova-plugins/core/decorators/common";
import { invoicePayTypes } from "../../../../../services/invoice-service";
import { sto } from "../../../../../Context/InshData";
export default function SearchInvForm(props) {
    const {
        AutoOptions,
        rptParams,
        onChangeData,
        onChangeAutoData,
        isPurches,
        fetching,
        gets,
        customers,
        changTField,
    } = props;

    const { t } = useTranslation(["forms"]);

    const clasup = useStylesUp();
    const calsPurch = useStylesAutoPurch();
    const claslbl = useStylesLbl();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();


    const clashd = useStylesHed();
    const clasBA = useStylesBA();
    const clasTD = useStylesTD();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    return (
        <React.Fragment>
            <Grid container style={{ padding: 5 }} spacing={1}>

                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small"
                        id="bill_no"
                        label="رقم الفاتورة"
                        className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
                        InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
                        type="text"
                        name="bill_no" value={rptParams.bill_no} onChange={onChangeData('bill_no')}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small"
                        id="refno"
                        label="رقم المرجع"
                        placeholder="رقم المرجع"

                        className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
                        InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
                        type="text"
                        name="refno" value={rptParams.refno} onChange={onChangeData('refno')}
                    />
                </Grid>

                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.inv_type}
                        onChange={(event, newValue) => {
                            onChangeAutoData("inv_type", newValue);
                            // console.log(newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={gets.types}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                          }
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                            id="typeinvs"
                                {...params}
                                label={"نوع الفاتورة"}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}
                    />

                </Grid>

                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.pay_type}
                        onChange={(event, newValue) => {
                            onChangeAutoData("pay_type", newValue);
                            // console.log(newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={invoicePayTypes}
                        disabled={rptParams?.inv_type?.id != 2}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                          }
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                            id="pay_type"
                                {...params}
                                label={"نوع الدفع"}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}
                    />

                </Grid>

                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.bank}
                        onChange={(event, newValue) => {
                            onChangeAutoData("bank", newValue);
                            // console.log(newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={gets.Banks}
                        disabled={rptParams?.pay_type?.id != 2}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                          }
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                            id="bank"
                                {...params}
                                label={"البنك"}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}
                    />

                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.user}
                        onChange={(event, newValue) => {
                            onChangeAutoData("user", newValue);
                            // console.log(newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={AutoOptions.Users}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                          }
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                            id="sales_emp"
                                {...params}
                                label={"المستخدم"}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}
                    />

                </Grid>
                {sto.User_typei==1&& ( 
                     <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete
                        classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.sales_man}
                        onChange={(event, newValue) => {
                            onChangeAutoData("sales_man", newValue);
                            // console.log(newValue);
                        }}
                        id="controllable-states-demo"
                        size="small"
                        options={gets.salesmen}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                          }
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                            id="sales_man"
                                {...params}
                                label={t("delegate")}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}
                    />

                </Grid>
            )}
            
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.branch}
                        onChange={(event, newValue) => {
                            //   setBranch(newValue);
                            onChangeAutoData("branch", newValue);

                        }}
                        id="combo-box-demo"
                        size="small"
                        options={AutoOptions.Branches}
                        disabled={true}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }

                        style={{}}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("branch")}
                                variant="outlined"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}

                    />
                </Grid>
                
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete classes={isPurches ? calsPurch : clasAo}
                        value={rptParams.cust}
                        onChange={(event, newValue) => {
                            onChangeAutoData("cust", newValue);
                        }}
                        id="combo-box-demo"
                        size="small"
                        options={customers}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }

                        style={{}}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={"العميل"}
                                variant="outlined"
                                InputLabelProps={{ className: isPurches ? claslbl.clrBlack : claslbl.clrSale }}
                            />
                        )}

                    />
                </Grid>

                
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small"
                        id="cust_mobile`"
                        label="هاتف العميل"
                        className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
                        InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
                        type="text"
                        name="cust_mobile" value={rptParams.cust_mobile} onChange={onChangeData('cust_mobile')}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small" id="dateFrom" label="من تاريخ"
                        className={isPurches ? clasTF.rootPurch : clasTF.rootSale} InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
                        type="date" format="YYYY-MM-DD"
                        name="dateFrom" value={rptParams.dateFrom} onChange={onChangeData('dateFrom')}
                    /></Grid>
                {rptParams.selectTime.id == 2 && (
                    <Grid item lg={2} md={2} sm={3} xs={6}>
                        <TextField fullWidth variant="outlined" size="small" id="timeFrom" label="الوقت "
                            className={isPurches ? clasTF.rootPurch : clasTF.rootSale} InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
                            type="time"
                            format="HH:mm"
                            name="timeFrom" value={rptParams.timeFrom}  onChange={onChangeData('timeFrom')}
                        /></Grid>
                )}
                <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small" id="dateTo" label="إلى تاريخ"
                        className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
                         InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
                        type="date" format="YYYY-MM-DD"
                        name="dateTo" value={rptParams.dateTo} onChange={onChangeData('dateTo')}
                    /></Grid>

                {rptParams.selectTime.id == 2 && (
                    <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
                        <TextField fullWidth variant="outlined" size="small" id="timeTo" label="الوقت "
                            className={isPurches ? clasTF.rootPurch : clasTF.rootSale} 
                            InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
                            type="time"
                            format="HH:mm"
                            name="timeTo" value={rptParams.timeTo} onChange={onChangeData('timeTo')}
                        /></Grid>
                )}

            </Grid>
        </React.Fragment>
    );
}
