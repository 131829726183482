import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../../services/LoadingPage";
import AddItems from "./addItems";
const defaultAutos = {
  Branches: [],
};

export default function AddItemsContainer(props ) {
    const{locat, DialogClose}=props;
  const tb = {
    wait: true,
    other: true,
    Groupst: true,
    groups: true,
    AllBranches: true,
    tax_groups: true,
    Units: true,
    Items: true,
    // Khams: true,

   // stor:sto,
    parms: {
      iempid: sto.empi,
      fltrBranch: true,
      scurrenciestype: "C",
      tname: "curr",
      takei: 40,
    },
    autos: {  },
  };


  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "GetD",
    tb,
  );

  if (
    fetchAll ||
    !AutoOptions?.Branches
  )
    return <LoadingPage />;

  return (

    <AddItems
      {...{
        AutoOptions: AutoOptions?.Branches ? AutoOptions : defaultAutos,
        locat, isDialog:false, DialogClose,
      }}
    />
  );
}
