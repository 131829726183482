import React, { useState, useEffect } from "react";

import {
  Button,
  Chip,
  CircularProgress,
  colors,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Slide,
  Typography,
} from "@material-ui/core";

import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import { useHistory } from "react-router-dom";
import {
  useStylesCAB,
  useStylesContainers,
  useStylesDPM,
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import clsx from "clsx";
import { insh as inshst } from "../../../../../Context/InshSt";

import { autos } from "../../../../../Context/Local";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import { insh, sto, wsto } from "../../../../../Context/InshData";
import ItemsAppBar from "./ItemsAppBar";
import ItemsForms from "./ItemsForm";
import { stElemnt } from "../../../../../St/comps/cStyle";
import { useStylesBA } from "../../../../../St/comps/UseStyle";
import { getLS } from "../../../../../utils/useLS";
import { locRem } from "../../../../../Context/Locstor";
import useBreakpoint from "../../../../../utils/useBreakpoint";
import ListItemsCon from "../ListItem/ListItemCon";
import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import MultiCode from "../MultiBarcode/MultiCode";
import BarcodeDialog from "../barcodePrint/BarcodeDialog";
import DeleteConfirmDialog from "../../../../../components/DeleteConfirmDialog";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    // direction: "rtl",
    minHeight: "100%",

  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.red[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
    fontWeight: "bold",
    marginBottom: "0.5rem"
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  purchTotalCard: {
    backgroundColor: colors.blue[50],
    borderColor: colors.blue[300],
  },

}));
function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function AddItems(props) {

  const { AutoOptions, locat, isDialog, DialogClose } = props;
  const SearchActivityReports = {
    id: "",
    symbol: "",
    nameEN: "",
    barcode: "1111",
    group_id: null,
    unit: null,
    purch_price: 0,
    sale_price: 0,
    tax: 0,
    tax_group: null,
    min_sale_price: 0,
    Distributor_price: 0,
    jomla_price: 0,
    prody_date: moment(new Date()).format("YYYY-MM-DD"),
    expiry_date: moment(new Date()).format("YYYY-MM-DD"),
    is_tab3_curr: 0,
    branch: 1,
    subUnits: [],
  };
  const autos_curr = {
    currType: [
      { id: 1, name: "مخزني" },
      { id: 2, name: "خام" },
      { id: 3, name: "صناعي" },
      { id: 4, name: "خدمي" },
      { id: 5, name: "لايباع" },
    ],
  };

  const inshValueAuto = {
    // group: {id: -1, name: "الكل" },
    group_id: { id: AutoOptions.Groupst[0].id, name: AutoOptions.Groupst[0].name },
    branch: { id: AutoOptions.Branches[0].id, name: AutoOptions.Branches[0].name },
    tax_group: { id: AutoOptions.tax_groups[0].id, name: AutoOptions.tax_groups[0].name },
    unit: { id: AutoOptions.Units[0].id, name: AutoOptions.Units[0].name },
    type: { id: autos_curr.currType[0].id, name: autos_curr.currType[0].name },
  };
  const multiCodeValue = {
    // symbol:"",
    id: 0,
    barcode: "",
    curr: AutoOptions.other.lastid,
    unitID: AutoOptions.Units[0].id,
  };
  const [items, setItems] = useState({
    ...SearchActivityReports,
    tax_group: AutoOptions.tax_groups[0].id, tax: AutoOptions.tax_groups[0].tax, type: autos_curr.currType[0].id, group_id: AutoOptions.Groupst[0].id,
    barcode: AutoOptions.other.barcode, id: AutoOptions.other.lastid, unit: AutoOptions.Units[0].id, subUnits: AutoOptions.Untis,
  });

  const [valueAutos, setValueAutos] = useState(inshValueAuto);
  const [loadItems, setLoadItems] = useState(AutoOptions.Items);
  const [multiCodeList, setMultiCodeList] = useState([]);
  const point = useBreakpoint();
  const [styl, setStyl] = useState(inshst.custm);

  const clsConts = useStylesContainers();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();

  const clasCAB = useStylesCAB();
  const locSto = getLS("sto", {});
  const [loading, setLoading] = useState(insh.LoadAndSucc);
  const [success, setSuccess] = useState(insh.LoadAndSucc);
  const [showPic, setShowPic] = useState(false);
  const [or, setOr] = useState(insh.Or);

  const inshParm = { itake: 20, symple: "", groupSearch: null };
  const [parm, setParm] = useState(inshParm);

  const timer = React.useRef();
  const [uiElement, setUiElement] = useState({});

  const [transition, setTransition] = React.useState(undefined);


  const [currUnitList, setCurrUnitList] = useState([]);

  const [currKhamList, setCurrKhamList] = useState([]);

  const [setting, setSetting] = useState({
    screen: "",
    tb: { parms: {}, autos: {} },
  });

  const [style, setStyle] = useState({});

  const buttonClassnameNew = clsx({ [clasCAB.buttonSuccessCAB]: success.new });
  const buttonClassnameSave = clsx({
    [clasCAB.buttonSuccessCAB]: success.save,
  });
  const buttonClassnameDelete = clsx({
    [clasCAB.buttonSuccessCAB]: success.delete,
  });

  const [prevent, setPrevent] = useState(insh.prevents);

  /* s PMD */
  const [openPMD, setOpenPMD] = useState(false);
  const [barcodeDialogOpen, setBarcodeDialogOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openMulti, setOpenMulti] = useState(false);


  const handCloseDw = async () => {
    newToI(1);
    await setOpenPMD(false);
  };
  const handOpenDw = (Transition) => {
    setTransition(() => Transition);
    setOpenPMD(true);
  };
  /* e PMD */

  const [gets, setGets] = useState(insh.tbname);

  const [kham, setKham] = useState(insh.khams);
  const [currenciesAll, setCurrenciesAll] = useState([]);
  const [isNew, setIsNew] = useState(false);




  // const ust_o = (updatedData) => {
  function usto(olddata) { }

  const reloadGets = async () => {
    const tb = {
      wait: true,
      other: true,
      // Items: true,
      parms: {
        iempid: sto.empi,
        fltrBranch: true,
        scurrenciestype: "C",
        tname: "curr",
        // takei: 1,
      },
    };

    await axios
      .post(uri + "GetD", tb)
      .then((res) => {
        // setGets(res.data);
        setItems({
          ...SearchActivityReports,
          id: res.data.other.lastid,
          barcode: res.data.other.barcode,
          unit: AutoOptions.Units[0].id,
          group_id: AutoOptions.Groupst[0].id,
          type: autos_curr.currType[0].id,
          tax_group: AutoOptions.tax_groups[0].id,
          tax: AutoOptions.tax_groups[0].value,

        });

      })
      .catch((error) => {
      });
  };
  const [dataList, setDataList] = useState([]);

  const changePurchPrice = () => {
    const sum = dataList?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.price;
    }, 0);

    setItems({ ...items, purch_price: sum });
  }
  const changAutos = (prop, newValue) => {
    // console.log(prop+' - '+newValue.id)
    setValueAutos({ ...valueAutos, [prop]: newValue })
    setItems({
      ...items,
      [prop]: newValue.id,
      tax: prop == "tax_group" ? newValue.value : items.tax,
    });
  };

  const changTField = (prop) => (e) => {
    // console.log(prop+' - '+e.target.value)
    setItems({ ...items, [prop]: e.target.value });
  };

  const changTFSearch = (prop) => (e) => {
    setParm({ ...parm, [prop]: e.target.value, itake: 5 });
  };

  const chekeBreack = () =>
    point == "1" || point == "2" || point == "3"
      ? handOpenDw(TransitionUp)
      : null;


  const getItemsData = (item, curr_unt, sub_barcode, itemComps) => {
    // setOr({ ...or, postput: "toPut" });
    // chekeBreack();
    setOpenPMD(false);

    let res = { group_id: {}, branch: {}, tax_group: {}, unit: {}, type: {} };
    AutoOptions.Groupst.map((x) => {
      if (x.id == item.group_id) res.group_id = x;
    });
    AutoOptions.Branches.map((x) => {
      if (x.id == item.branch) res.branch = x;
    });
    AutoOptions.tax_groups.map((x) => {
      if (x.id == item.tax_group) res.tax_group = x;
    });
    AutoOptions.Units.map((x) => {
      if (x.id == item.unit) res.unit = x;
    });
    autos_curr.currType.map((x) => {
      if (x.id == item.type) res.type = x;
    });
    setItems({
      ...items,
      id: item.id,
      barcode: item.barcode,
      nameEN: item.nameEN,
      symbol: item.symbol,
      purch_price: item.purch_price,
      sale_price: item.sale_price,
      min_sale_price: item.min_sale_price,
      Distributor_price: item.Distributor_price,
      jomla_price: item.jomla_price,
      tax: item.tax,
      img: item.img,
      type: item.type,
      group_id: item.group_id,
      unit: item.unit,
      prody_date: moment(item.prody_date).format("YYYY-MM-DD"),
      expiry_date: moment(item.expiry_date).format("YYYY-MM-DD"),
      is_tab3_curr: item?.is_tab3_curr,
    });

    // eds-changes
    setCurrUnitList(
      curr_unt.map((su) => {
        var unitName = AutoOptions.Units.find((x) => x.id === su.unit);
        return { ...su, unitName: unitName ? unitName.name : "" };
      })
    );
    // eds-changes
    setMultiCodeList(
      sub_barcode.map((mu) => {
        return { ...mu };
      })

    );


    setValueAutos({
      ...valueAutos,
      group_id: res.group_id,
      branch: res.branch,
      tax_group: res.tax_group,
      unit: res.unit,
      type: res.type,
    });
    if (item.type == 3)
      setDataList(
        itemComps?.map((ic, key) => {
          var unit = AutoOptions.Units.find((x) => x.id == ic.mat_unit);
          var item1 = AutoOptions.Items.find((x) => x.id == ic.mat_id);
          return {
            ...ic, id: key.toString(), quan: ic?.mat_quan, item: item1,
            unit: unit,
          }
        })
      );
    setPrevent(false);
    // setOpenPMD(false);

    //  setDefultAutos(true,res);
    setUiElement({ ...uiElement, DeleIcon: false });
    timer.current = window.setTimeout(
      () => setUiElement({ ...uiElement, DeleIcon: true }),
      300
    );
  };
  const handNewItem = () => {
    reloadGets();
    //const defaultTG = AutoOptions.tax_groups?.filter((t) => t.isDefault == true);
    setOr(insh.Or);


    setParm(inshParm);
    setUiElement({ ...uiElement, DeleIcon: false });
    setCurrUnitList([]);
    setMultiCodeList([]);
    setDataList([]);
    setValueAutos(inshValueAuto);
  };

  const ButtonLoading = (prop) => setLoading({ ...loading, [prop]: true });

  const ButtonWitting = (prop) => {
    reloadGets(prop);

  };

  const ButtonSuccess2 = (prop, state) => {
    timer.current = window.setTimeout(() => {
      setLoading({ ...loading, [prop]: false });
      setSuccess({ ...success, [prop]: true });
      if (
        prop == "delete" ||
        prop == "save"
      ) {
        if (state) handNewItem();
      }
      timer.current = window.setTimeout(() => {
        setSuccess({ ...success, [prop]: false });
      }, 500);
    }, 2000);
  };
  const ButtonSuccess = (prop, timr) => {
    timer.current = window.setTimeout(() => {
      setLoading({ ...loading, [prop]: false });
      setSuccess({ ...success, [prop]: true });
      if (prop != "save") handNewItem();
      setPrevent(true);
    }, timr);
  };

  const handLoads = async (value, stosn) => {
    const nstos = stosn;
    Object.keys(value).forEach((element) => {
      nstos[element] = value[element];
    });
    window.localStorage.setItem("sto", JSON.stringify(nstos));
  };

  const loadItem = async (stosn) => {
    const nstos = stosn;

    window.localStorage.setItem("sto", JSON.stringify(nstos));
  };

  const storgeing = async () => {
    await axios
      .post(uri + "Res", {
        wait: true,

        Items: true,
        View_CurrencieUnit: true,
        View_CurrenciesToInv: true,
        parms: {
          scurrenciestype: "I",
          scurrencieShow: "Fav",
          currType: 2, // out
          currShow: "M",
          iempid: sto.empi,
          igroupid: -1,
          takei: 50,
          sSearch: "",
        },
        autos: {},
      })
      .then((res) => {
        handLoads(res.data, wsto);
      })
      .catch((error) => {
        //  console.log('nooooo sttorgeing');
        alert("لم يتم التحديث");
      });

    //  }, 3000);
  };

  const newToI = (timr) => {
    ButtonLoading("new");
    ButtonSuccess("new", timr);
  };

  const will = () => {
    // alert(parseFloat(items.sale_price) +' <= '+ parseFloat(items.purch_price))
    let ob = { msg: "قم بإضافة ", txt: "" };
    if (items.barcode == "") ob.txt = t("forms:barcode");
    else if (items.symbol == "") ob.txt = t("forms:product-name-ar");
    else if (items.purch_price === "") ob.txt = t("forms:purch-price");
    else if (items.sale_price == "") ob.txt = t("forms:sale-price");
    else if (parseFloat(items.sale_price) < parseFloat(items.purch_price)) {
      ob.txt = "سعر البيع اكبر او يساوي سعر الشراء";
      ob.msg = "يجب أن يكون ";
    }
    ob.msg += ob.txt;
    return ob;
  };
  const [snack, setSnack] = React.useState(insh.Snack);

  const handOSnack = (newState) => {
    setSnack({ openSnak: true, ...newState });
  };
  const handCloseMulti = async (state) => {
    // if (state === "cancle") await restPM(state);
    await setOpenMulti(false);
  };
  const [transitionsmu, setTransitionsmu] = React.useState(undefined);
  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  const addToBS = () => {
    console.log("thre group is ", items);
    let action = will();
    if (action.txt == "") {
      ButtonLoading("save");
      let item_Comps = dataList?.map(x => {
        console.log("thre group is ", x);
        return {
          item_id: items?.id,
          mat_id: x?.item?.id,
          mat_unit: x?.unit?.id,
          mat_quan: x?.quan,
          price: x?.price,
          mat_quantot: x?.quan_tot,
        }
      })
      const currData = {
        itemData: items,
        subBarcodes: multiCodeList,
        subUnits: currUnitList,
        item_Comps: item_Comps,
      };
      console.log("the curre data is ", currData);

      prevent
        ? axios
          .post(uri + "Currencies", currData)
          .then((res) => {
            if (res.data) {
              alert("رقم الباردكود موجود مسبقا");
              ButtonSuccess("save", 500);
            } else {
              usto(items);
              ButtonWitting("save");
              setCurrUnitList([]);
              setMultiCodeList([]);
              setDataList([]);
              ButtonSuccess("save", 1000);
              handNewItem();

            }
          })
          .catch((res) => {
            alert("تم إضافة الصنف هذا مسبقا");
            ButtonSuccess("save", 1000);

          })
        : // axios.put('http://localhost:8184/api/Currencies', items)
        axios
          .put(uri + "Currencies", currData)
          .then((res) => {
            ButtonWitting("save");
            setCurrUnitList([]);
            ButtonSuccess("save", 1000);
            handNewItem();
          })
          .catch((res) => {
            alert("لم يتم  تعديل هذا الصنف");
            ButtonSuccess("save", 1000);
            handNewItem();
          });
    } else {
      alert(action.msg);
    }
  };

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });

  const hRowDelete = (id) => {
    let reResData = [...loadItems];
    reResData = reResData.filter((t) => t.id !== id);
    setLoadItems(reResData);
    setCurrenciesAll(reResData);
    ButtonSuccess("delete", 1000);
  };

  const deleteToI = async (e, id, screen) => {
    await e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    ButtonLoading("delete");
    axios
      .delete(uri + `Currencies?id=${id}`)
      .then((res) => {
        res.data != -1
          ? hRowDelete(id)
          : alert("لا يمكن حذف هذا الصنف لانه مرتبط بعمليات اخرى");
        // ButtonSuccess("delete", 500, true);
        setIsNew(true);
        ButtonSuccess("delete", 1000);

      })
      .catch((err) => {
        alert("لم يتم الحذف");
        // ButtonSuccess("delete", 500);
        setIsNew(false);
        ButtonSuccess("delete", 1000);
      });
  };

  // ==== b sd
  const [open, setOpen] = React.useState(false);

  const backHome = () => {
    his.push({ pathname: "/" });
  };
  const back = () => {
    if (!isDialog) his.goBack();
    else
      DialogClose(false);
  };

  const his = useHistory();

  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [sum, setSum] = useState(false);
  const styheader = {
    textAlign: "center",
    backgroundColor: "#fff",
    height: "100vh",
    // direction: "rtl",
    minWidth: "xl%",
    maxWidth: "xl"
  };
  const clasPM = useStylesDPM();

  const frontDialogForm = () => {
    return (
      <div>
        <Dialog
          fullScreen
          className={clasPM.rootPM}
          open={openPMD}
          onClose={handCloseDw}
          aria-labelledby="form-dialog-title"
          TransitionComponent={transition}
          key={transition ? transition.name : ""}
        >
          <DialogTitle id="form-dialog-title"
          //</Dialog> className={claslbl.clor}
          >

          </DialogTitle>
          <DialogContent
          // className={[clasPM.dialogContentPM, clasPM.diaCPMBasicDa]}
          >
            <ListItemsCon {...{
              loadItems,
              getItemsData,
              AutoOptions,
            }} />
          </DialogContent>
          <DialogActions
            className={clasPM.dirRPM}>
            <div className={clasCAB.wrapperCAB}>
              <Button
                variant="outlined"
                color=""
                size="large"
                style={{ ...stElemnt.all.iconBtn }}
                className={buttonClassnameNew}
                disabled={loading.new}
                onClick={handCloseDw}
              // startIcon={<CloseIcon />}
              >
                {" "}
                {t("forms:cancel")}{" "}
              </Button>
              {loading.new && (
                <CircularProgress
                  size={24}
                  className={clasCAB.buttonProgressCAB}
                />
              )}
            </div>

            <div className={clasBA.growBA} />

          </DialogActions>
        </Dialog>
      </div>
    );
  };
  return (
    <div className={classes.root}>
    <Grid container item xs={12}>
      <Typography
        size="small"
        className={classes.headerTitle}
        color="primary"
      >
        {t("translation:basic-product-data")}
      </Typography>
    </Grid>
    <Typography
      component="div"
      style={{ direction: i18next.language == "ar" ? "rtl" : "rtl" }}
    >
      <Grid container item lg={12} md={12} sm={12} xm={12} className={clasui.rootchip}>

        <Grid container item lg={6} md={6} sm={6} xm={12} className={clasui.rootchip}>

          <Container
            component={Paper}
            maxWidth={false}
            style={{
              padding: "10px",
              paddingBottom: "0px",
              borderBottomColor: colors.red[50],
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
            }}
          >

            <Grid
              container
              justifyContent="center"
              lg={12}
              md={12}
              sm={12}
              xs={12}
              spacing={1}
            >
              <ItemsForms
                {...{
                  AutoOptions,
                  items,
                  setItems,
                  changTField,
                  changAutos,
                  currUnitList,
                  setCurrUnitList,
                  valueAutos,
                  autos_curr,
                  setOpenMulti,
                  styl,
                  clasui,
                  changePurchPrice,
                  dataList,
                  setDataList
                }}
              />

            </Grid>
          </Container>
        </Grid>

        <Hidden ony={["xs"]}>

          <Grid container item lg={6} md={6} sm={6} xs={12} className={clasui.rootchip}>
            <Grid item xs={12}>
              <ListItemsCon
                {...{
                  loadItems,
                  getItemsData,
                  AutoOptions,
                }}
              />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
      <BarcodeDialog
        open={barcodeDialogOpen}
        setOpen={setBarcodeDialogOpen}
        data={items}
      />
      <MultiCode {...{
        items, multiCodeList, setMultiCodeList, openMulti, setOpenMulti, transitionsmu, handCloseMulti, multiCodeValue, AutoOptions,
      }} />


      <Hidden mdUp={["md"]}> {frontDialogForm()} </Hidden>
    </Typography>
    <ItemsAppBar  {...{
      ...props,
      items,
      setBarcodeDialogOpen,
      buttonClassnameNew,
      buttonClassnameSave,
      buttonClassnameDelete,
      clasCAB,
      newToI,
      setting,
      deleteToI,
      addToBS,
      stElemnt,
      loading,
      clasui,
      clasBA,
      isDialog,
      backHome,
      back,
      setOpenSearch,
      handNewItem,
      uiElement,
      success,
      handOpenDw,
      TransitionUp,

    }} />
  </div>

  );
}
