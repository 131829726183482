import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import LoadingPage from "../../../../../services/LoadingPage";
import EmpSales from "./EmpSales";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
const defaultAutos = {
  Employees: [],
};
export default function EmpSalesCon() {
  const [branch, setBranch] = useState({ id: -1, name: "الكل" });
  const tb = {
    wait: true,
    Customers: true,
    Employees: true,
    Branches: true,
    stor:sto,
    parms: { branch: sto.branchi},
  };
  const url = uri + "Auto" + "/custTypeM";
  const { data: CustomerOptions, fetching: fetchCust } = useFetchURL({
    ...{ url, dependencyArray: [] },
  });
  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb,
  );

  if (fetchAll || !AutoOptions?.Employees ) return <LoadingPage />;

  return (
    <EmpSales
      {...{
        AutoOptions: AutoOptions?.Employees ? AutoOptions : defaultAutos,
        CustomerOptions: CustomerOptions?.length > 0 ? CustomerOptions : [],
        isSale: true,
        isPurches: false,
      }}
    />
  );
}

export function EmpPurchCon() {
  const tb = {
    wait: true,
    Customers: true,
    Employees: true,
    Branches: true,
    stor:sto,
    parms: { branch: sto.branchi},
  };
  const t = 2;
  const url = uri + "Auto" + `/custm?t=${t}`;
  const { data: CustomerOptions, fetching: fetchCust } = useFetchURL({
    ...{ url, dependencyArray: [] },
  });
  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb,
  );

  if (
    fetchCust ||
    fetchAll ||
    !AutoOptions?.Employees ||
    CustomerOptions?.length == 0
  )
    return <LoadingPage />;
  return (
    <EmpSales
      {...{
        AutoOptions: AutoOptions?.Employees ? AutoOptions : defaultAutos,
        CustomerOptions: CustomerOptions?.length > 0 ? CustomerOptions : [],
        isSale: false,
        isPurches: true,
      }}
    />
  );
}
