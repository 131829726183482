import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { sto } from "../../../../Context/InshData";
import Print from "@material-ui/icons/Print";
import { reFormatDate } from "../../../..//utils/utils";
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        width: "97%",
    },
    root:{
        width: "100%",
    }
}));

export default function DailyJenralTable(props) {
    const { data} = props;
      const dataE=[...new Set(data.map(item=> item.resId))];
    const stylH = {
        // borderWidth: 1,
        border: "solid 0.4px",
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 15,
        color: "#fff"
    }
    const stylRo = {
        border: "solid 0.4px",
        borderColor: "#000",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 13,
        minWidth:"50px",
        maxWidth:"500px",
        color: "#000"
    }
    const stylRo2 = {
        border: "solid 0.4px",
        borderColor: "#000",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 13,
        maxWidth:"150px",
        color: "#000"
    }

    const { t } = useTranslation(["forms"]);
    const classes = useStyles();
    return (
        <div component={Paper}                 className={classes.root} 
>
            {dataE.map(resId=> (
              <div key={resId}> 
        <TableContainer component={Paper}  maxWidth={true} >
                   <div style={{marginTop:"0.4rem"}}></div>

            <Table 
                className={classes.table} 

                size="small" aria-label="a dense table" >
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor:"#808080",
                            color: "#000",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 15,
                            border: "solid 0.6px"
                        }}
                    >
                        <TableCell style={stylH} align="center">
                            {"المستند"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"رقم الحساب"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"اسم الحساب"}
                        </TableCell>
                      
                        <TableCell style={stylH} align="center">
                            {"نوع العملية"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"البيان"}
                        </TableCell>

                        <TableCell style={stylH} align="center">
                            {"مدين"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"دائن"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"مركز التكلفة"}
                        </TableCell>
                               
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.filter(item => item.resId===resId).map(item => (
                        <TableRow 
                        key={item.acc_no}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                color: "#000",
                                border: "solid 0.6px"
                            }}
                           >

                            <TableCell style={stylRo} align="center" >{item.doc_no}</TableCell>
                            <TableCell style={stylRo} align="center" >{item.acc_no}</TableCell>
                            <TableCell style={stylRo} align="center" > {item?.NameAcount}</TableCell>
                            <TableCell style={stylRo} align="center"  > {item.Doc_Type_Name}</TableCell>
                            <TableCell style={stylRo} align="center"  > {item.notes}</TableCell>
                            <TableCell style={stylRo} align="center" > {parseFloat(item.dept)?.toFixed(2) ?? "-"}</TableCell>
                            <TableCell style={stylRo} align="center" > {parseFloat(item.credit)?.toFixed(2) ?? "-"}</TableCell>
                            <TableCell style={stylRo} align="center"   > {item.Namecostcenter}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
              </div>  
            ))}

        </div>
    );
}
