import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors, IconButton, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { sto } from "../../../../Context/InshData";
import Print from "@material-ui/icons/Print";

import { useStylesLbl } from "../../../../St/comps/UseStyle";
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        minHeight:500,
    },
}));

export default function InvItemsTable(props) {
    const { isdata, optionsCurrUnitsTable,setIsData,maths,handSums ,isPurches } = props;

    const stylH = {
        // borderWidth: 1,
        border: "solid 0.1px",
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontSize: 10,
        color: "#fff"
    }
    const stylRo = {
        BorderBottom: "solid 0.3px",
        //borderStyle: "solid",
        borderColor: "gray",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 12,
        color: "#000"
    }
    const hRowUpdat = (newData, oldData, discKid) => {
        // alert(resolve)
        let resDisCountU = 0,
          restotJustSumU = 0,
          restaxvalU = 0,
          resNetU = 0,
          decPerces = 0,
          tot_before_vat_after_desc = 0;
    
        if (discKid==1) {
    
          resDisCountU = (
    
            parseFloat(newData.DiscPers / 100) *
            newData.new_exchange_price *
            newData.val1
          ).toFixed(2);
    
        } else {
          resDisCountU = parseFloat(newData.discount).toFixed(2);
        }
    
        restotJustSumU = parseFloat(
          (parseFloat(newData.new_exchange_price) * parseFloat(newData.val1)) - resDisCountU
        );
        decPerces = (
          (parseFloat(newData.totJust) - parseFloat(restotJustSumU)) / (parseFloat(newData.totJust) * 100)
    
        ).toFixed(2)
        restaxvalU = maths(
          parseFloat(restotJustSumU),
          parseFloat(newData.taxperc),
          "taxval"
        );
        resNetU = maths(
          parseFloat(restotJustSumU),
          parseFloat(newData.taxperc),
          "Net"
        );
    
        tot_before_vat_after_desc = maths(
          parseFloat(restotJustSumU),
          parseFloat(newData.taxperc),
          "before_vat"
    
        );
    
        console.log("the old data is", oldData);
        console.log("the new data is", newData);
        newData = {
          /* to show */
          // unitame: LetvalueCurrUnits.name, /*  - الـوحدة name */
          unitame: newData.unitame /*  - الـوحدة name */,
          symbol: newData.symbol /*  - إسم الصنف  */,
          /* to maaths */
          /* float - الإجمالي */
          totJust: newData.new_exchange_price * newData.val1,
          /* float - الإجمالي بعد الخصم */
          totAfterDisc: tot_before_vat_after_desc,
    
          tot_before_vat_after_d: tot_before_vat_after_desc,
    
          tab3Val:
            oldData?.tab3Val == null || oldData?.tab3Val == 0 ? 0 : restotJustSumU,
          // totAfterDisc: newData.val1 * newData.new_exchange_price - newData.discount,
          /* to go */
    
          currency_from: newData.currency_from /* float - id الصنف */,
          unit: newData.unit /* int - الـوحدة id */,
          /* float - الكمية */
          val1: newData.val1,
          // new _val1:newData.new _val1,
          /* float - العبوة */
          val: newData.val,
          // new _val:newData.val,
          /* float - الـسعر */
          new_exchange_price: newData.new_exchange_price,
          // all_new_exchange_price: newData.new_exchange_price,
          /* float - الخصم مبلغ _ قيمة الخصم  مبلغ*/
          discount: resDisCountU,
          DiscPers: newData.DiscPers,
          // new _DiscPers: newData.DiscPers,
          /* float -  ضريبة القيمة المضافة نسبة */
          taxperc: newData.taxperc,
          // new _taxperc: newData.taxperc,
          /* float - إجمالي قيمة المضافة  */
    
          taxval:
            oldData?.tab3Val == null || oldData?.tab3Val == 0
              ? restaxvalU
              : 2 * restaxvalU,
          // new _taxval: (newData.totJust-newData.discount)*newData.taxperc/100,
          /* float - الصافي */
    
          // netAmountSub: resNetU,
          netAmountSub:
            oldData?.tab3Val == null || oldData?.tab3Val == 0
              ? resNetU
              : 2 * resNetU,
          // netAmo untSub: ((restotJustSumU - resDisCountU)+((restotJustSumU-resDisCountU)*newData.taxperc/100)).toFixed(2),
          barcode: newData.barcode /*  - الباركود */,
          sub_center: 0 /* int -  */,
          sub_branch: sto.branchi /* int - الفرع */,
          notes: newData.notes /* string - ملاحضة */,
          sale_price: newData.sale_price /* string - سعر الببيع */,
          tot_before_vat_after_d: tot_before_vat_after_desc,
        };
        const dataUpdate = [...isdata];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setIsData([...dataUpdate]);
        handSums(dataUpdate);
       // resolve();
      };
      

    const MathChangIdata = (prop,row) => (event) => {
        let i = {
          res: Number(event.target.value),

        };
        if (prop === "new_exchange_price") {

            let newData={...row,new_exchange_price:i.res}
            hRowUpdat(newData,row,0);


        }
        else if (prop === "val1") {

            let newData={...row,val1:i.res}
            hRowUpdat(newData,row,0);
        }
        else if (prop === "discount") {
            let newData={...row,discount:i.res}
            hRowUpdat(newData,row,1); 
        }
        else if (prop === "DiscPers") {

            let newData={...row,DiscPers:i.res}
            hRowUpdat(newData,row,0); 
        }
 
      };

    const desalblePrice= true;
    const desalbleUnit= true;
    const desalbleVal1= true;
    const desalbleDis= true;
    const desalbleDescp= true;

    // const classes = useStyles();

    return (
        <TableContainer component={Paper}            //className={classes.table} 
        style={{ minWidth: 650,
            minHeight:500,direction:"rtl"}} >
            <Table
                size="small" aria-label="a dense table" >
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor: isPurches ? colors.blue[300] : colors.green[300],
                            color: "#000",
     
                            border: "solid 0.6px"
                        }}
                    >
                        <TableCell style={stylH} align="center">
                            {"رقم الصنف"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الباركود"}
                        </TableCell>

                        <TableCell style={stylH} align="center">
                            {"الصنف"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الوحدة"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الكمية"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"العبوة"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"السعر"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الاجمالي"}
                        </TableCell>

                        <TableCell style={stylH} align="center">
                            {"قيمة الخصم"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الخصم%"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الاجمالي قبل الضريبة"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"ضريبة التبغ"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"القيمة المضافة%"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"إجمالي الضريبة"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الصافي"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"البيان"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"Action"}
                        </TableCell>
                        {/* <TableCell style={stylH} align="center">  </TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isdata.map((row) => (
                        <TableRow key={row.currency_from}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                color: "#000",
                                fontWeight: 600,
                                fontFamily: "Amiri-Regular",
                                fontSize: 12,
                                border: "solid 0.6px"
                            }}
                        >
                            <TableCell style={stylRo} align="center" >{row.currency_from}</TableCell>
                            <TableCell style={stylRo} align="center" >{row.barcode}</TableCell>
                            <TableCell style={stylRo} align="center" >{row.symbol}</TableCell>
                            <TableCell style={stylRo} align="center" >{row.unitame}</TableCell>

                            <TableCell style={stylRo} align="center"  > {row.val}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { desalbleVal1=false; }} >
                            <TextField fullWidth variant="outlined" size="small"
                                    id="val1"
                                    label={row.val1}
                                    type="text"
                                    name="val1"
                                    // disabled={desalbleVal1}
                                    value={row.val1}
                                    onChange={MathChangIdata("val1",row)}
                                />
                            </TableCell>

                            <TableCell style={stylRo} align="center" onClick={() => { desalblePrice=false; }}>
                            <TextField fullWidth variant="outlined" size="small"
                                    id="refno"
                                   // label={row.new_exchange_price}
                                    type="text"
                                    disabled={desalblePrice}
                                    name="refno"
                                    value={row.new_exchange_price}
                                    onChange={MathChangIdata("new_exchange_price",row)}
                                />
                            </TableCell>
                            <TableCell style={stylRo} align="center"  > {parseFloat(row.totJust)?.toFixed(2)}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => {desalbleDis=false;}} >
                                 <TextField fullWidth variant="outlined" size="small"
                                    id="refno"
                                    type="text"
                                    name="discount"
                                   // disabled={desalbleDis}
                                    value={row.discount}
                                    onChange={MathChangIdata("discount",row)}
                                />
                            </TableCell>
                            <TableCell style={stylRo} align="center"  > <TextField fullWidth variant="outlined" size="small"
                                    id="refno"
                                    //label={row.discount}
                                    type="text"
                                    name="DiscPers"
                                    disabled={desalbleDescp}
                                    value={row.DiscPers}
                                    onChange={MathChangIdata("DiscPers",row)}
                                />
                            </TableCell>
                            <TableCell style={stylRo} align="center"  > {parseFloat(row.totAfterDisc)?.toFixed(2) ?? "-"}</TableCell>
                            <TableCell style={stylRo} align="center"  > {parseFloat(row.tab3Val)?.toFixed(2) ?? "-"}</TableCell>
                            <TableCell style={stylRo} align="center"  > {parseFloat(row.taxperc)?.toFixed(2) ?? "-"}</TableCell>

                            <TableCell style={stylRo} align="center" >{row.taxval}</TableCell>
                            <TableCell style={stylRo} align="center" >{row.netAmountSub}</TableCell>
                            <TableCell style={stylRo} align="center" >{""}</TableCell>


                            <TableCell align="center" >
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    // onChange= {getCell(false)}
                                    style={{ maxHeight: "0.7rem" }}
                                    onClick={() => {
                                      //  onClickPrint(row);
                                    }}>
                                    <Print size={"small"} />
                                </IconButton>
                            </TableCell>
                            {/* <TableCell  style={stylRo} align="center" ></TableCell> */}


                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
